import React from "react";
import "../GroupOverview.scss";
import loginCompleted from "../../assets/login-completed.svg";
import loginIncompleted from "../../assets/login-incompleted.svg";
import { strings } from "../../../Translations/strings";
import arrowSelect from "../../assets/arrow-select.svg";

const OverviewMain = ({
  categoriesAsArray,
  openEditCategory,
  openCalculator,
}) => {
  return (
    <>
      <p className="group-overview-title">{strings.group_overview_title}</p>
      <hr className="overview-line"></hr>
      {categoriesAsArray.map((category) => {
        return (
          <div className="group-overview-item" key={category.id}>
            <img
              src={category?.icon}
              alt="category"
              className="group-overview-category-icon"
            />
            <div className="categories-overview-col-one">
              <span className="categories-overview-title">
                {category.title}
              </span>
              <span
                className="mobile-completed-category-overview d-block d-md-none"
                onClick={() => {
                  category.completed
                    ? openEditCategory(category)
                    : openCalculator(category.slug.toLowerCase());
                }}
              >
                {category.completed ? (
                  <span className="login-completed">
                    <img
                      src={loginCompleted}
                      alt="login completed"
                      className="login-info-icon"
                    ></img>
                    {strings.group_overview_profile_completed}
                  </span>
                ) : (
                  <span className="login-incompleted">
                    <img
                      src={loginIncompleted}
                      alt="login completed"
                      className="login-info-icon"
                    ></img>
                    {strings.group_overview_profile_incomplete}
                  </span>
                )}
              </span>
              <span
                className="categories-overview-action d-none d-md-block"
                onClick={() => {
                  category.completed
                    ? openEditCategory(category)
                    : openCalculator(category.slug.toLowerCase());
                }}
              >
                {category.completed
                  ? strings.group_overview_completed_edit
                  : strings.group_overview_incomplete_start_over}
              </span>
            </div>
            <div className="categories-overview-info d-none d-md-flex">
              {category.completed ? (
                <span className="login-completed">
                  <img
                    src={loginCompleted}
                    alt="login completed"
                    className="login-info-icon"
                  ></img>
                  {strings.group_overview_profile_completed}
                </span>
              ) : (
                <span className="login-incompleted">
                  <img
                    src={loginIncompleted}
                    alt="login completed"
                    className="login-info-icon"
                  ></img>
                  {strings.group_overview_profile_incomplete}
                </span>
              )}
            </div>
            <img
              src={arrowSelect}
              alt="select"
              className="select-category-mobile d-block d-md-none"
            ></img>
          </div>
        );
      })}
    </>
  );
};

export default OverviewMain;
