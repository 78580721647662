import React, { useState } from "react";
import { strings } from "../../Translations/strings";
import Header from "../Components/header";
import "../Auth.scss";
import { useNavigate } from "react-router-dom";
import RegisterForm from "./RegisterForm";
import {ReactComponent as BackIcon} from '../../Assets/arrow-left-blue.svg';
import { Button } from "react-bootstrap";

const Register = () => {
  const navigate = useNavigate();
  const [showToc, setShowToc] = useState(false);
  const goToLogin = () => navigate("/login");

  const showTocHandler = () => setShowToc(!showToc);
  return (
    <div className="d-flex justify-content-center align-items-center login-container">
      <Header />
      <div className="contet-wrapper register-background">
        <div className="form-register-container">
         {showToc && <div className="back-container">
         <BackIcon width={30} className="back-toc" onClick={showTocHandler}/>
         <span>{strings.toc_explanation_title}</span>
         </div>
          }
          {showToc && <div className="divider" />}
          {!showToc && <p className="header-title">{strings.register_form_title}</p>}
          {!showToc && <p className="register-user-intro">Ja sam</p>}
          <RegisterForm showTocHandler={showTocHandler} showToc={showToc}/>
          <div className="divider" />
         {!showToc && <> 
         <p className="register-question auth-register-question">
            {strings.register_form_already_register_label}
          </p>
          <p className="register-link" onClick={goToLogin}>
            {strings.register_form_login_here_label}
          </p></>}
          {showToc && <div className="toc-back-container">
             <Button onClick={showTocHandler} className="back-button">{strings.toc_back_to_registration}</Button> 
             </div>}
        </div>
      </div>
    </div>
  );
};

export default Register;