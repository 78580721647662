import React from "react";
import "./ProfileSubmenu.scss";
import { strings } from "../../Translations/strings";

const ProfileSubmenu = () => {
  return (
    <div className="submenu account-submenu">
      <div className="c-container">
        <div className="submenu_nav_label align-items-center d-flex">
          <img
            src={require("../../NavMenu/assets/UserCircleBold.svg").default}
            className="dropdown-profile-icon"
            alt="user-icon"
          ></img>
          <span className="my-profile-submenu-label">
            {strings.myaccount_label}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProfileSubmenu;
