/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./Products.scss";
import { strings } from "../../Translations/strings";
import greenCheckmark from "./assets/green-checkmark.svg";
import greenPencil from "./assets/green-pencil.svg";

const CompletedCategoryBanner = ({
  currentCategory,
  currentSubcategory,
  openEditCategory,
}) => {
  return (
    <>
      <div className="d-flex completed-category-header-container">
        <div className="category-label">
          {currentSubcategory === null
            ? currentCategory.replace(/-/g, " ").replace(/dj/g, "đ")
            : strings.products_label}
        </div>
        <div className="d-flex header-category-completed">
          <img src={greenCheckmark} alt=""></img>
          <p className="completed-category-header-p">
            {strings.category_banner_category_completed_one}
            {currentSubcategory === null
              ? currentCategory
                  .replace(/-/g, " ")
                  .replace("solarni", "solarne")
                  .replace("sistemi", "sisteme")
                  .replace("prozori", "prozore")
                  .replace("izolacija", "izolaciju")
              : strings.marketplace_banner_products}
            {strings.category_banner_category_completed_two}
          </p>
          <p className="vertical-line">|</p>
          <div
            className="header-completed-category-completed-edit d-flex"
            onClick={openEditCategory}
          >
            <img src={greenPencil} className="green-pencil" alt=""></img>
            <p className="header-category-completed-p">
              {strings.category_banner_category_completed_edit}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompletedCategoryBanner;
