import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wishlistItems: [],
  wishlistTotalAmount: 0,
};

export const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    addToWishlist: (state, action) => {
      const itemIndex = state.wishlistItems.findIndex(
        (item) => item.id === action.payload.id
      );
      if (itemIndex < 0) {
        state.wishlistItems.push(action.payload);
      }
    },
    removeFromWishlist: (state, action) => {
      const newWishlistItems = state.wishlistItems.filter(
        (wishlistItem) => wishlistItem.id !== action.payload.id
      );
      state.wishlistItems = newWishlistItems;
    },
    getTotalSum: (state, action) => {
      const { total } = state.wishlistItems.reduce(
        (wishlistTotal, wishlistItem) => {
          const { price } = wishlistItem;
          const formattedPrice = parseFloat(price);
          wishlistTotal.total += formattedPrice;
          return wishlistTotal;
        },
        {
          total: 0,
        }
      );
      state.wishlistTotalAmount = total;
    },
  },
});

export const { addToWishlist, removeFromWishlist, getTotalSum } =
  wishlistSlice.actions;

export default wishlistSlice.reducer;
