/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown } from "react-bootstrap";
import "./Products.scss";
import { strings } from "../../Translations/strings";
import dropdownIcon from "./assets/dropdown-arrow-dark.svg";
import { useSelector } from "react-redux";

const SortProducts = ({ sortProducts }) => {
  const { sort } = useSelector((state) => state.marketPlace);
  const sortDefault = () => {
    sortProducts("default");
  };

  const sortLowest = () => {
    sortProducts("asc");
  };

  const sortHighest = () => {
    sortProducts("desc");
  };

  return (
    <>
      <Dropdown className="sorting-container">
        <Dropdown.Toggle variant="transparent" id="dropdown-sort">
          {sort?.title ? sort?.title : strings.sort_by_default}
          <img src={dropdownIcon} alt="sort"></img>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={(e) => sortDefault(e)} >
            Osnovno sortiranje
          </Dropdown.Item>
          <Dropdown.Item onClick={(e) => sortLowest(e)}>
            {strings.sort_dropdown_label_two}
          </Dropdown.Item>
          <Dropdown.Item onClick={(e) => sortHighest(e)}>
            {strings.sort_dropdown_option_one}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default SortProducts;
