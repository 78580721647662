import { Routes, Route } from "react-router-dom";
import "@fontsource/montserrat";
import "./App.css";
import Home from "./Home/Home";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import Marketplace from "./Marketplace/Marketplace";
import ProductDetail from "./Marketplace/Products/ProductDetail/ProductDetail";
import Wishlist from "./Wishlist/Wishlist";
import NotFound from "./NotFound/NotFound";
import CalculatorModal from "./Calculator/CalculatorModal/CalculatorModal";
import OpeningCalculatorModal from "./Calculator/OpeningCalculatorModal/OpeningCalculatorModal";
import { useDispatch, useSelector, useStore } from "react-redux";
import { setUpInterceptor } from "./Http/client";
import Profile from "./Profile/Profile";
import { useEffect } from "react";
import {
  getInitialQuestions,
  getLoggedUserQuestions,
} from "./Calculator/state/calculatorSlice";

function App() {
  const store = useStore();
  setUpInterceptor(store);
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.profile);
  const { modalCalculatorVisible } = useSelector((state) => state.calculator);


  useEffect(() => {
    if (Boolean(profile?.data?.id)) {
      dispatch(getLoggedUserQuestions());
    } else {
      dispatch(getInitialQuestions());
    }
  }, [dispatch, profile.data.id]);

  useEffect(() => {
    if (!modalCalculatorVisible && Boolean(profile?.data?.id)) {
      dispatch(getLoggedUserQuestions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalCalculatorVisible]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/proizvodi/" element={<Marketplace />} />
        <Route path="/proizvodi/:search" element={<Marketplace />} />
        <Route path="/proizvodi/:category" element={<Marketplace />} />
        <Route
          path="/proizvodi/:category/proizvod/:id"
          element={<ProductDetail />}
        />
        <Route path="/profil/" element={<Profile />} />
        <Route path="/lista-zelja" element={<Wishlist />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <CalculatorModal />
      <OpeningCalculatorModal />
    </div>
  );
}

export default App;
