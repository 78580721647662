import React from "react";
import "../../../Profile/MyProfile/MyProfile.scss";
import { useSelector } from "react-redux";
import { strings } from "../../../Translations/strings";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const AskVendor = ({ product }) => {
  const { profile } = useSelector((state) => state.profile);
  const { name } = product || {};

  const schema = yup.object().shape({
    email: yup
      .string()
      .required(strings.validation_field_required_label)
      .email(strings.validation_field_email),
    subject: yup
      .string()
      .required(strings.send_message_to_vendor_validation)
      .min(2, strings.send_message_to_vendor_validation)
      .matches(/^[A-Za-z]+$/, strings.send_message_to_vendor_validation),
    message: yup
      .string()
      .min(4, strings.send_message_to_vendor_validation)
      .required(strings.send_message_to_vendor_validation),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: profile?.data?.email,
      subject: profile?.data?.subject,
      message: profile?.data?.message,
    },
  });

  const onSubmit = () => {};

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3 mt-3 required" controlId="formEmail">
          <Form.Label aria-required className="control-label">
            {strings.send_message_to_vendor_email_label}
          </Form.Label>
          <Form.Control
            type="email"
            onKeyPress={(e) => {
              if (e.code === "Space" || e.keyCode === 32) {
                e.preventDefault();
              }
            }}
            placeholder={profile?.data?.email}
            isInvalid={errors?.email?.message}
            {...register("email", { required: false })}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.email?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <hr className="grey-line ask-vendor-line"></hr>
        <Form.Group className="mb-3 required" controlId="formEmail">
          <Form.Label aria-required className="control-label">
            {strings.send_message_to_vendor_subject}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={strings.question_vendor_subject_placeholder + name}
            isInvalid={errors?.subject?.message}
            {...register("subject", { required: false })}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.subject?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3 required" controlId="formEmail">
          <Form.Label aria-required className="control-label">
            {strings.send_message_to_vendor_message_label}
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={6}
            type="text"
            placeholder={strings.send_message_to_vendor_placeholder}
            isInvalid={errors?.message?.message}
            {...register("message", { required: false })}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.message?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Form>
    </>
  );
};

export default AskVendor;
