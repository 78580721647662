import React, { useState } from "react";
import "./ProductDetail.scss";
import { Modal, Carousel } from "react-bootstrap";
import { strings } from "../../../Translations/strings";

export const ProductImagesSlider = ({ images, ...props }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="savings-header">
        <Modal.Title id="contained-modal-title-vcenter">
          <h4 className="product-modal-title">
            {strings.product_images_title} ({index + 1}/{images?.length || 1})
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 m-0"></Modal.Body>
      <Carousel align="center" activeIndex={index} onSelect={handleSelect}>
        {images?.map((productImage) => (
          <Carousel.Item key={productImage.id}>
            <img src={productImage.url} alt="product" />
          </Carousel.Item>
        ))}
      </Carousel>
    </Modal>
  );
}
