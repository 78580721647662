import React from 'react';
import ImageTextComponent from '../CalculatorComponents/ImageTextComponent/ImageTextComponent';
import InputSelectComponent from '../CalculatorComponents/InputComponents/InputSelectComponent';
import InputText from '../CalculatorComponents/InputComponents/InputText';
import Result from '../Result';

const Components = {
    ImageTextComponent,
    InputSelectComponent,
    InputText,
    Result,
};

const mapper = ({
  title, style, answer, type, placeHolder, options, icon, setAnswer, updateIndex, currentIndex, currentCategory, category, onClose,
}) => {
  if (typeof Components[type] !== 'undefined') {
    return React.createElement(Components[type], {
        type,
        placeHolder,
        answer,
        style,
        title,
        options,
        icon,
        setAnswer,
        updateIndex,
        currentIndex,
        currentCategory,
        category,
        onClose,
    });
  }
  return null;
};

export default mapper;
