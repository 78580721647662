/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { strings } from "../../../Translations/strings";
import { ProgressBar, Table } from "react-bootstrap";
import warning from "../assets/warning.svg";

const SavingsDetails = ({
  productSaving,
  loanDetails,
  product,
  loanNeeded,
}) => {
  const { name, category, images, price } = product || {};
  return (
    <div className="savings-body-container d-flex flex-column">
      <div className="d-flex flex-column">
        <p className="savings-modal-product">{strings.savings_modal_product}</p>
        <div className="d-flex savings-modal-general-info">
          <img
            src={images?.[0]?.url}
            alt="product"
            className="savings-product-image"
          ></img>
          <div className="d-flex flex-column modal-product-info">
            <p className="savings-category">{category?.title}</p>
            <p className="savings-product-title">{name?.toUpperCase()}</p>
            <p className="vendor-label">
              {strings.product_vendor_label}
              <span className="vendor-name">{product?.manufacturer}</span>
            </p>
            <p className="savings-product-price">
              {(price * 1).toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) +
                " " +
                strings.KM_label}
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column">
        <p className="savings-exchange">{strings.savings_modal_info}</p>
        <div className="d-flex w-100 progress-bars">
          <div className="savings-progress-one">
            <ProgressBar
              now={
                productSaving?.energySavingsPercentage &&
                productSaving?.energySavingsPercentage <= 100 &&
                productSaving?.energySavingsPercentage >= 0
                  ? productSaving?.energySavingsPercentage
                  : productSaving?.energySavingsPercentage < 0
                  ? 0
                  : productSaving?.energySavingsPercentage > 100
                  ? 100
                  : 0
              }
            />
            <p className="product-savings">
              {strings.financing_energy_savings}
              <b>
                {productSaving?.energySavingsPercentage &&
                productSaving?.energySavingsPercentage <= 100 &&
                productSaving?.energySavingsPercentage >= 0
                  ? productSaving?.energySavingsPercentage
                  : productSaving?.energySavingsPercentage < 0
                  ? 0
                  : productSaving?.energySavingsPercentage > 100
                  ? 100
                  : 0}
                %
              </b>
            </p>
          </div>
          <div>
            <ProgressBar
              now={
                productSaving?.moneySavingsPercentage &&
                productSaving?.moneySavingsPercentage <= 100 &&
                productSaving?.moneySavingsPercentage >= 0
                  ? productSaving?.moneySavingsPercentage
                  : productSaving?.moneySavingsPercentage < 0
                  ? 0
                  : productSaving?.moneySavingsPercentage > 100
                  ? 100
                  : 0
              }
            />
            <p className="product-savings">
              {strings.financing_money_savings}
              <b>
                {productSaving?.moneySavingsPercentage &&
                productSaving?.moneySavingsPercentage <= 100 &&
                productSaving?.moneySavingsPercentage >= 0
                  ? productSaving?.moneySavingsPercentage
                  : productSaving?.moneySavingsPercentage < 0
                  ? 0
                  : productSaving?.moneySavingsPercentage > 100
                  ? 100
                  : 0}
                %
              </b>
            </p>
          </div>
        </div>
        <p className="product-text-smaller">
          Izračunato na osnovu Vaše trenutne potrošnje u odnosu na procijenjenu
          potrošnju sa ovim proizvodom
        </p>
        {product?.category?.title === "Izolacija" && (
          <p className="product-text-smaller">
            Kao osnova za izračun se koristi 50m<sup>2</sup> površine zidova za
            izolaciju.
          </p>
        )}
        <Table responsive="sm" className="savings-table d-flex product-savings">
          <tbody className="savings-tab">
            <tr>
              <td>{strings.savings_energy_savings}</td>
              <td>
                {productSaving?.energySavingsPercentage &&
                productSaving?.energySavingsPercentage <= 100 &&
                productSaving?.energySavingsPercentage >= 0
                  ? productSaving?.energySavingsPercentage
                  : productSaving?.energySavingsPercentage < 0
                  ? 0
                  : productSaving?.energySavingsPercentage > 100
                  ? 100
                  : 0}
                %
              </td>
            </tr>
            <tr>
              <td>{strings.savings_yearly_savings}</td>
              <td>
                {productSaving?.yearlyMoneySavings > 0
                  ? (productSaving?.yearlyMoneySavings * 1).toLocaleString(
                      "de-DE",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )
                  : 0}{" "}
                KM
              </td>
            </tr>
          </tbody>
        </Table>
        {loanNeeded && (
          <Table
            responsive="sm"
            className="savings-table d-none d-lg-flex product-savings"
          >
            <tbody className="savings-tab">
              <div className="mb-2 savings-table-info-text">
                {strings.savings_table_info}
              </div>
              <tr>
                <td className="flex flex-col">
                  <p className="product-text-small">
                    {strings.savings_table_row_one}
                  </p>
                  <p className="product-text-smaller">
                    {strings.savings_modal_description_one}
                  </p>
                </td>
                <td>
                  {(loanDetails?.productPrice * 1).toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  KM
                </td>
              </tr>
              <tr>
                <td>{strings.savings_table_row_two}</td>
                <td>
                  {loanDetails?.totalEnergySavingsThroughProductLifespan > 0
                    ? loanDetails?.totalEnergySavingsThroughProductLifespan.toLocaleString(
                        "de-DE",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )
                    : 0}{" "}
                  KM
                </td>
              </tr>
              <tr>
                <td>{strings.savings_table_row_three}</td>
                <td>
                  {loanDetails?.totalInterest.toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  KM
                </td>
              </tr>
              <tr>
                <td className="flex flex-col">
                  <p className="product-text-small">
                    {strings.savings_table_row_four}
                  </p>
                  <p className="product-text-smaller">
                    {strings.savings_modal_description_two}
                  </p>
                </td>
                <td>
                  {loanDetails?.interestCoverageByEnergySavings > 0 &&
                  loanDetails?.interestCoverageByEnergySavings <= 100
                    ? loanDetails?.interestCoverageByEnergySavings
                        ?.toFixed(2)
                        .replace(".", ",")
                    : loanDetails?.interestCoverageByEnergySavings < 0
                    ? 0
                    : 100}{" "}
                  %
                </td>
              </tr>
              <tr>
                <td className="flex flex-col">
                  <p className="product-text-small">
                    {strings.savings_table_row_five}
                  </p>
                  <p className="product-text-smaller">
                    {strings.savings_modal_description_three}
                  </p>
                </td>
                <td>
                  {(loanDetails?.grantValue * 1).toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  KM
                </td>
              </tr>
              <tr>
                <td className="flex flex-col">
                  <p className="product-text-small">
                    {strings.savings_table_row_six}
                  </p>
                  <p className="product-text-smaller">
                    {strings.savings_modal_description_four}
                  </p>
                </td>
                <td>{loanDetails?.yearsToReturnInvestment} god</td>
              </tr>
              <tr>
                <td>
                  {strings.savings_table_row_seven}
                  {strings.savings_table_row_seven_i}
                </td>
                <td>
                  {loanDetails?.nominalInterestRate
                    ?.toFixed(2)
                    .replace(".", ",")}{" "}
                  %
                </td>
              </tr>
              <tr>
                <td>
                  <span className="text-bold">
                    {strings.savings_table_row_eight}
                  </span>{" "}
                  {strings.savings_table_row_eight_i}
                </td>
                <td className="text-extrabold">
                  {loanDetails?.effectiveInterestRate
                    ?.toFixed(2)
                    .replace(".", ",")}{" "}
                  %
                </td>
              </tr>
            </tbody>
          </Table>
        )}
        {loanNeeded && (
          <Table responsive="sm" className="savings-table d-flex d-lg-none">
            <tbody>
              <div className="mb-2 savings-table-info-text">
                {strings.savings_table_info}
              </div>
              <tr>
                <td className="flex flex-col">
                  <p className="savings-table-mobile-text">
                    {strings.savings_table_row_one}
                  </p>
                  <p className="product-text-smaller">
                    {strings.savings_modal_description_one}
                  </p>
                </td>
                <td>
                  {product?.calculation?.totalInvestment.toLocaleString(
                    "de-DE",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}{" "}
                  KM
                </td>
              </tr>
              <tr>
                <td>{strings.savings_table_row_two}</td>
                <td>
                  {loanDetails?.totalEnergySavingsThroughProductLifespan > 0
                    ? loanDetails?.totalEnergySavingsThroughProductLifespan.toLocaleString(
                        "de-DE",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )
                    : 0}{" "}
                  KM
                </td>
              </tr>
              <tr>
                <td>{strings.savings_table_row_three}</td>
                <td>
                  {loanDetails?.totalInterest.toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  KM
                </td>
              </tr>
              <tr>
                <td className="flex flex-col">
                  <p className="savings-table-mobile-text">
                    {strings.savings_table_row_four}
                  </p>
                  <p className="product-text-smaller">
                    {strings.savings_modal_description_two}
                  </p>
                </td>
                <td>
                  {loanDetails?.interestCoverageByEnergySavings > 0 &&
                  loanDetails?.interestCoverageByEnergySavings <= 100
                    ? loanDetails?.interestCoverageByEnergySavings
                    : loanDetails?.interestCoverageByEnergySavings < 0
                    ? 0
                    : 100}{" "}
                  %
                </td>
              </tr>
              <tr>
                <td className="flex flex-col">
                  <p className="savings-table-mobile-text">
                    {strings.savings_table_row_five}
                  </p>
                  <p className="product-text-smaller">
                    {strings.savings_modal_description_three}
                  </p>
                </td>
                <td>
                  {(loanDetails?.grantValue * 1).toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  KM
                </td>
              </tr>
              <tr>
                <td className="flex flex-col">
                  {" "}
                  <p className="savings-table-mobile-text">
                    {strings.savings_table_row_six}
                  </p>
                  <p className="product-text-smaller">
                    {strings.savings_modal_description_four}
                  </p>
                </td>
                <td>{loanDetails.yearsToReturnInvestment} god</td>
              </tr>
              <tr>
                <td>
                  {strings.savings_table_row_seven}
                  {strings.savings_table_row_seven_i}
                </td>
                <td>{loanDetails.nominalInterestRate} %</td>
              </tr>
              <tr>
                <td>
                  <span className="text-bold">
                    {strings.savings_table_row_eight}{" "}
                  </span>

                  {strings.savings_table_row_eight_i}
                </td>
                <td className="text-extrabold">
                  {loanDetails?.effectiveInterestRate} %
                </td>
              </tr>
            </tbody>
          </Table>
        )}
        <div className="d-flex savings-disclaimer-container">
          <img src={warning} className="warning-icon" alt="disclaimer"></img>
          <p className="savings-disclaimer">{strings.savings_disclaimer}</p>
        </div>
      </div>
    </div>
  );
};

export default SavingsDetails;
