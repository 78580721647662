import { React } from "react";
import "./ImageTextComponent.scss";
import iconSelected from "../../assets/Circle_Check.svg";
import QuestionWrapperComponent from "../QuestionWrapperComponent/QuestionWraperComponent";

const ImageTextComponent = ({
  currentIndex,
  title,
  category,
  updateIndex,
  options,
  answer,
  setAnswer,
  currentCategory,
  onClose,
}) => {
  return (
    <div className="user-type-modal">
      <QuestionWrapperComponent
        title={title}
        category={category}
        buttonPrevious="Zatvori"
        buttonNext="Dalje"
        updateIndex={updateIndex}
        currentIndex={currentIndex}
        hasValue={answer}
        currentCategory={currentCategory}
        onClose={onClose}
      >
        {options.map((option) => {
          const slug = option?.slug ? option?.slug : option?.title;
          const className =
            answer === option.value
              ? "option selected image-text-component-styling"
              : "option image-text-component-styling";
          return (
            <div
              key={option.title}
              className={className}
              onClick={() => {
                setAnswer(option.value, slug)}}
            >
              <img src={option?.icon} alt="" className="option-img" />
              <div className="option-content">
                <span>{option?.title}</span>
                <span className="option-subtitle">{option?.subtitle}</span>
                <embed src={iconSelected} alt="" />
              </div>
            </div>
          );
        })}
      </QuestionWrapperComponent>
    </div>
  );
};

export default ImageTextComponent;
