import React, { useState } from "react";
import "./Footer.scss";
import { strings } from "../../Translations/strings";
import arrowUp from "./assets/arrow-up.svg";
import MobileFooter from "./MobileFooter";
import OffcanvasFooter from "./OffcanvasFooter";

const Footer = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="footer-container d-none d-lg-block">
        <hr />
        <footer>
          <div className="copyright">
            <div className="d-flex">
              <p>
                {strings.footer_copyright_label}
                {new Date().getFullYear()} {strings.footer_copyright_owner}
              </p>

              <div className="terms-conditions">
                <p>{strings.footer_all_rights_reserved_label}</p>
              </div>
            </div>
            <div
              className="footer-more-details d-flex align-items-center"
              onClick={handleShow}
            >
              <p>{strings.footer_more}</p>
              <img src={arrowUp} alt="arrow_up"></img>
            </div>
          </div>
        </footer>
        <OffcanvasFooter show={show} setShow={setShow} onHide={handleClose} />
      </div>
      <MobileFooter />
    </>
  );
};

export default Footer;
