export const products = [
  {
    name: "VAILLANT ECOTEC PLUS VUW 11/26 CS/1-5",
    cat: "grijanje",
    category: "Toplotne pumpe",
    id: 0,
    vendor: "OBI",
    image: require("./assets/product-1.png"),
    price: "340.00 KM",
    url: "0",
  },
  {
    name: "VAILLANT ECOTEC PLUS VUW 11/26 CS/1-5",
    cat: "grijanje",
    category: "Peći na pelet",
    id: 1,
    vendor: "OBI",
    image: require("./assets/product-2.png"),
    price: "500.00 KM",
    url: "1",
  },
  {
    name: "VAILLANT ECOTEC PLUS VUW 11/26 CS/1-5",
    cat: "grijanje",
    category: "Toplotne pumpe",
    id: 2,
    vendor: "OBI",
    image: require("./assets/product-3.png"),
    price: "1500.00 KM",
    url: "2",
  },
  {
    name: "VAILLANT ECOTEC PLUS VUW 11/26 CS/1-5",
    cat: "grijanje",
    category: "Toplotne pumpe",
    id: 3,
    vendor: "OBI",
    image: require("./assets/product-4.png"),
    price: "2500.00 KM",
    url: "3",
  },
  {
    name: "VAILLANT ECOTEC PLUS VUW 11/26 CS/1-5",
    cat: "grijanje",
    category: "Toplotne pumpe",
    id: 4,
    vendor: "OBI",
    image: require("./assets/product-5.png"),
    price: "1200.00 KM",
    url: "4",
  },
  {
    name: "VAILLANT ECOTEC PLUS VUW 11/26 CS/1-5",
    cat: "grijanje",
    category: "Toplotne pumpe",
    id: 5,
    vendor: "OBI",
    image: require("./assets/product-6.png"),
    price: "1500.00 KM",
    url: "5",
  },
  {
    name: "VAILLANT ECOTEC PLUS VUW 11/26 CS/1-5",
    cat: "grijanje",
    category: "Toplotne pumpe",
    id: 6,
    vendor: "OBI",
    image: require("./assets/product-7.png"),
    price: "500.00 KM",
    url: "6",
  },
  {
    name: "VAILLANT ECOTEC PLUS VUW 11/26 CS/1-5",
    cat: "grijanje",
    category: "Toplotne pumpe",
    id: 7,
    vendor: "OBI",
    image: require("./assets/product-8.png"),
    price: "900.00 KM",
    url: "7",
  },
  {
    name: "VAILLANT ECOTEC PLUS VUW 11/26",
    cat: "solarni-sistemi",
    category: "Solarni paneli",
    id: 8,
    vendor: "OBI",
    image: require("./assets/product-9.png"),
    price: "2700.00 KM",
    url: "8",
  },
  {
    name: "SOLAR PANEL PV-600",
    cat: "solarni-sistemi",
    category: "Solarni paneli",
    id: 9,
    vendor: "OBI",
    image: require("./assets/product-9.png"),
    price: "2200.00 KM",
    url: "9",
  },
  {
    name: "SOLAR PANEL PV-WH-200",
    cat: "solarni-sistemi",
    category: "Solarni paneli",
    id: 10,
    vendor: "OBI",
    image: require("./assets/product-9.png"),
    price: "6200.00 KM",
    url: "10",
  },
  {
    name: "SOLAR PANEL PV-9000:32",
    cat: "solarni-sistemi",
    category: "Solarni paneli",
    id: 11,
    vendor: "OBI",
    image: require("./assets/product-9.png"),
    price: "1300.00 KM",
    url: "11",
  },
  {
    name: "Valley Window 567-WW",
    cat: "prozori",
    category: "Aluminijski prozori",
    id: 12,
    vendor: "OBI",
    image: require("./assets/product-10.png"),
    price: "3900.00 KM",
    url: "12",
  },
  {
    name: "Diego Window 832/65",
    cat: "prozori",
    category: "Aluminijski prozori",
    id: 13,
    vendor: "OBI",
    image: require("./assets/product-10.png"),
    price: "1900.00 KM",
    url: "13",
  },
  {
    name: "FLOOR + 11/26 MB",
    cat: "izolacija",
    category: "Izolacija",
    id: 14,
    vendor: "OBI",
    image: require("./assets/product-11.png"),
    price: "200.00 KM",
    url: "14",
  },
  {
    name: "FLOOR + 1100/26",
    cat: "izolacija",
    category: "Izolacija",
    id: 15,
    vendor: "OBI",
    image: require("./assets/product-11.png"),
    price: "3200.00 KM",
    url: "15",
  },
  {
    name: "Super Cool 31-03",
    cat: "hladjenje",
    category: "Klima uredjaji",
    id: 16,
    vendor: "OBI",
    image: require("./assets/product-1.png"),
    price: "2340.00 KM",
    url: "16",
  },
  {
    name: "Valley Window 167-II",
    cat: "prozori",
    category: "Aluminijski prozori",
    id: 17,
    vendor: "OBI",
    image: require("./assets/product-10.png"),
    price: "2400.00 KM",
    url: "17",
  },
  {
    name: "Horizon Window 8-AC",
    cat: "prozori",
    category: "Aluminijski prozori",
    id: 18,
    vendor: "OBI",
    image: require("./assets/product-10.png"),
    price: "4000.00 KM",
    url: "18",
  },
  {
    name: "Y3 Window TP-3",
    cat: "prozori",
    category: "Aluminijski prozori",
    id: 19,
    vendor: "OBI",
    image: require("./assets/product-10.png"),
    price: "1500.00 KM",
    url: "19",
  },
  {
    name: "FLOOR + 1800/5",
    cat: "izolacija",
    category: "Izolacija",
    id: 20,
    vendor: "OBI",
    image: require("./assets/product-11.png"),
    price: "1250.00 KM",
    url: "20",
  },
  {
    name: "FLOOR + 2100/30",
    cat: "izolacija",
    category: "Izolacija",
    id: 21,
    vendor: "OBI",
    image: require("./assets/product-11.png"),
    price: "800.00 KM",
    url: "21",
  },
];
