import React from "react";
import "./CalculatorSidebar.scss";
import logo from "../assets/calculator-logo.svg";
import { strings } from "../../Translations/strings";
import { useSelector } from "react-redux";
import UncompletedCategories from "./UncompletedCategories/UncompletedCategories";
import CompletedCategory from "./CompletedCategory/CompletedCategory";
import fundedByEU from "../assets/funded-by-eu.png";

const CalculatorSidebar = ({ sidebarData, progress, questions }) => {
  const {
    hasCompletedCategory,
    currentCategory,
    displayResult,
    groupOverview,
  } = useSelector((state) => state.calculator);

  return (
    <div className="calculator-sidebar">
      <img src={logo} alt="sidebar_logo" className="sidebar-logo" />
      <p className="logo-text">
        <span>{strings.calculator_sidebar_title_one}</span>
        <br></br> {strings.calculator_sidebar_title_two}
      </p>
      {!hasCompletedCategory ? (
        <UncompletedCategories
          sidebarData={sidebarData}
          displayResult={displayResult}
          progress={progress}
          questions={questions}
        />
      ) : (
        <CompletedCategory
          groupOverview={groupOverview}
          sidebarData={sidebarData}
          currentCategory={currentCategory}
          progress={progress}
          displayResult={displayResult}
          questions={questions}
        />
      )}
      <img src={fundedByEU} className="funded-by-eu" alt="EU"></img>
    </div>
  );
};

export default CalculatorSidebar;
