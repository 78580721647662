/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Nav, FormGroup, FormControl, InputGroup } from "react-bootstrap";
import "./MainMenu.scss";
import { strings } from "../../Translations/strings";
import searchMobile from "./assets/search-mobile.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { searchProduct } from "../marketPlaceSlice";
import OutsideClickHandler from "react-outside-click-handler";

const MainLinksMenu = ({ mobileMenuOpen, controlMobileMenuVisibility }) => {
  const menuClass = mobileMenuOpen ? "hamburger active" : "hamburger";
  const { profile } = useSelector((state) => state.profile);
  const { wishlistItems } = useSelector((state) => state.wishlist);
  const { searchTerm } = useSelector((state) => state.marketPlace);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState(false);

  const searchProductsHandler = (term) => {
    dispatch(searchProduct({ searchTerm: term?.target?.value || "" }));
  };

  const handleSearchInput = () => {
    setSearchInput(true);
  };

  const handleOutsideSearchInput = () => {
    setSearchInput(false);
  };
  return (
    <>
      <div className="nav-links-mobile">
        <OutsideClickHandler onOutsideClick={handleOutsideSearchInput}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              navigate({
                pathname: "/proizvodi/search",
                search: `?term=${searchTerm?.searchTerm}`,
              });
              searchProductsHandler("");
            }}
          >
            <FormGroup className="d-flex">
              <Nav.Link
                className={
                  !searchInput
                    ? "d-flex align-items-center user-icon"
                    : "d-flex user-icon mobile-search-opened-icon"
                }
                href="#"
                onClick={handleSearchInput}
              >
                <img src={searchMobile} alt="search_icon" />
              </Nav.Link>
              <InputGroup
                id="mobile-marketplace-search"
                className={
                  searchInput
                    ? "marketplace-input mobile-search-input "
                    : "d-none"
                }
              >
                <FormControl
                  autofocus
                  type="search"
                  placeholder={strings.marketplace_search}
                  className={searchInput ? "me-2 marketplace-search" : "d-none"}
                  aria-label="Search"
                  id="marketplace-search"
                  value={searchTerm?.searchTerm}
                  onChange={(value) => searchProductsHandler(value)}
                />
              </InputGroup>
            </FormGroup>
          </form>
        </OutsideClickHandler>
        <Nav.Link
          className="d-flex align-items-center user-icon"
          href={profile?.data?.firstName ? "/profil" : "/login"}
        >
          <img
            src={require("./assets/UserCircle.svg").default}
            alt="usercircle_icon"
          />
        </Nav.Link>
        <Link className="d-flex align-items-center jobs-icon" to="/lista-zelja">
          <img
            src={
              wishlistItems.length === 0
                ? require("./assets/Handbag-blue.svg").default
                : require("./assets/Handbag-blue-notification.svg").default
            }
            alt="handbag_icon"
          />
        </Link>
        <div className={menuClass} onClick={controlMobileMenuVisibility}>
          <a className="main-nav-toggle-marketplace">
            <i>{strings.mobile_menu_label}</i>
          </a>
        </div>
      </div>
    </>
  );
};

export default MainLinksMenu;
