/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Nav, Dropdown } from "react-bootstrap";
import "./MainMenu.scss";
import { strings } from "../../Translations/strings";
import OutsideClickHandler from "react-outside-click-handler";
import { Link } from "react-router-dom";

const MainMenuFirst = ({
  handleMarketplaceClickedDropdown,
  clickedDropdown,
  handleMarketplaceCloseDropdown,
}) => {
  return (
    <>
      <Nav className="navbar-links d-flex align-items-center">
        <Link
          className={
            window.location.pathname !== "/profil"
              ? "nav_label nav-label-strong nav_label_marketplace"
              : "nav_label nav_label_marketplace"
          }
          to="/proizvodi"
        >
          {strings.nav_products_label}
        </Link>
        <OutsideClickHandler onOutsideClick={handleMarketplaceCloseDropdown}>
          <Dropdown
            id="dropdown-nav-marketplace"
            onClick={handleMarketplaceClickedDropdown}
          >
            <Dropdown.Toggle
              variant="transparent"
              id="dropdown-nav-toggle-marketplace"
              className={
                clickedDropdown
                  ? "nav_label nav-label-strong nav_label_marketplace"
                  : "nav_label nav_label_marketplace"
              }
            >
              {strings.nav_partner_label}
              <img
                src={require("./assets/Arrow-down-blue.svg").default}
                alt="handbag_icon"
                className="ms-1"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                href="https://www.partner.ba/o-nama"
                target="_blank"
                className="nav-dropdown-item nav_label_marketplace remove-hover-marketplace"
              >
                {strings.mainmenu_about_us}
              </Dropdown.Item>
              <Dropdown.Item
                href="https://www.partner.ba/klub-korisnika/energijska-efikasnost"
                target="_blank"
                className="nav-dropdown-item nav_label_marketplace remove-hover-marketplace"
              >
                {strings.mainmenu_ee_label}
              </Dropdown.Item>
              <Dropdown.Item
                href="https://partnerkrediti.online/"
                target="_blank"
                className="nav-dropdown-item nav_label_marketplace remove-hover-marketplace"
              >
                {strings.mainmenu_microcredit}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </OutsideClickHandler>
      </Nav>
    </>
  );
};

export default MainMenuFirst;
