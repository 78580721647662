import React from "react";
import "../GroupOverview.scss";
import arrowBack from "../../assets/back-arrow.svg";
import restart from "../../assets/restart.svg";
import restartMobile from "../../assets/restart-mobile.svg";
import { strings } from "../../../Translations/strings";
import EditCategoryTabs from "./EditCategoryTabs";
import { resetAllCalculator } from "../../lib/lib";
import { useDispatch } from "react-redux";
import { getLoggedUserQuestions, resetAll } from "../../state/calculatorSlice";

const EditCategoryView = ({
  openEditCategory,
  editCategory,
  restartCategoryHandler,
  categoriesAsArray,
  onPressEditQuestion,
}) => {
  const dispatch = useDispatch();
  const resetCompleteCalculator = async () => {
    const resetSuccess = await resetAllCalculator().catch(() => {
      dispatch(getLoggedUserQuestions());
      dispatch(resetAll())
    });
    if (resetSuccess) {
      dispatch(getLoggedUserQuestions());
      dispatch(resetAll())
    }
  };
  return (
    <>
      <div className="d-flex justify-content-between summary-container">
        <p className="d-block d-md-none group-overview-title">
          {strings.group_overview_title}
        </p>
        <hr className="overview-line-summary"></hr>
        <div className="d-flex align-items-start category-title-icon">
          <img
            src={arrowBack}
            className="category-overview-arrow-back"
            onClick={() => {
                openEditCategory();
            }}
            alt="back"
          ></img>
          <img src={editCategory.icon} alt="category"></img>
          <p className="group-overview-title">{editCategory.title}</p>
          <div
            className="d-flex d-md-none align-items-start restart-container"
            onClick={() => restartCategoryHandler(editCategory.slug)}
          >
            <img
              src={restartMobile}
              alt="restart"
              className="summary-restart-icon"
            ></img>
          </div>
        </div>
        <div
          className="d-none d-md-flex align-items-start restart-container"
          onClick={resetCompleteCalculator}
        >
          <img src={restart} alt=""></img>
          <p>{strings.group_overview_restart}</p>
        </div>
      </div>
      <EditCategoryTabs
        categoriesAsArray={categoriesAsArray}
        onPressEditQuestion={onPressEditQuestion}
        editCategory={editCategory}
      />
    </>
  );
};

export default EditCategoryView;