import React from "react";
import { strings } from "../../Translations/strings";
import Header from "../Components/header";
import "../Auth.scss";
import { useNavigate } from "react-router-dom";
import LoginForm from "./LoginForm";

const Login = () => {
  const navigation = useNavigate();

  const goToRegistration = () => navigation("/register");

  return (
    <div className="d-flex justify-content-center align-items-center login-container">
      <Header />
      <div className="contet-wrapper">
        <div className="form-container">
          <p className="header-title">{strings.login_form_title}</p>
          <LoginForm />
          <div className="divider" />
          <p className="register-question auth-register-question">
            {strings.not_register_user_label}
          </p>
          <p className="register-link" onClick={goToRegistration}>
            {strings.create_account_label}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;