/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Nav, Dropdown } from "react-bootstrap";
import "./MainMenu.scss";
import { strings } from "../../Translations/strings";
import { useSelector } from "react-redux";
import logOut from "./assets/log-out.svg";
import { Link } from "react-router-dom";

const MainMenuSecond = ({ handleLogOut }) => {
  const { profile } = useSelector((state) => state.profile);
  const { wishlistItems } = useSelector((state) => state.wishlist);

  return (
    <>
      <Nav>
        <Link className="d-flex align-items-center" to="/lista-zelja">
          <img
            src={
              wishlistItems.length === 0
                ? require("./assets/Handbag-blue.svg").default
                : require("./assets/Handbag-blue-notification.svg").default
            }
            alt="handbag_icon"
          />
        </Link>
        {window.location.pathname === "/profil" ? (
          <Nav.Link
            className="d-flex align-items-center navbar-icon"
            href={profile?.data?.firstName ? "/profil" : "/login"}
          >
            <img
              src={require("./assets/UserCircleBold.svg").default}
              alt="usercircle_icon"
            />
          </Nav.Link>
        ) : (
          <Nav.Link
            className="d-flex align-items-center navbar-icon"
            href={profile?.data?.firstName ? "/profil" : "/login"}
          >
            <img
              src={require("./assets/UserCircle.svg").default}
              alt="usercircle_icon"
            />
          </Nav.Link>
        )}
        <Dropdown
          id="dropdown-nav-marketplace profile-dropdown"
          className={profile?.data?.firstName ? "d-flex" : "dropdown-hide"}
        >
          <Dropdown.Toggle
            variant="transparent"
            id="dropdown-nav-toggle"
            className={
              window.location.pathname === "/profil"
                ? "nav-label-strong marketplace_dropdown_label ps-0"
                : "marketplace_dropdown_label ps-0"
            }
          >
            {profile?.data?.firstName}
          </Dropdown.Toggle>

          <Dropdown.Menu className="profile-dropdown-menu">
            <Dropdown.Item className="nav-dropdown-item nav_label_marketplace">
              <Link to="/profil">
                <img
                  src={require("./assets/UserCircle.svg").default}
                  className="dropdown-profile-icon"
                  alt="profile_icon"
                ></img>
                {strings.mainmenu_account_label}
              </Link>
            </Dropdown.Item>
            <Dropdown.Item
              href="#"
              className="nav-dropdown-item nav_label_marketplace"
              onClick={handleLogOut}
            >
              <img
                src={logOut}
                className="dropdown-profile-icon"
                alt="logout_icon"
              ></img>
              {strings.mainmenu_logout_label}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav>
    </>
  );
};

export default MainMenuSecond;
