/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Nav, Dropdown } from "react-bootstrap";
import { strings } from "../Translations/strings";
import "./NavMenu.scss";
import OutsideClickHandler from "react-outside-click-handler";
import { Link } from "react-router-dom";

const NavMenuFirst = () => {
  const [clickedMore, setClickedMore] = useState(false);
  const handleClickedDropdown = () => {
    setClickedMore(!clickedMore);
  };

  const handleCloseDropdown = () => {
    setClickedMore(false);
  };
  return (
    <Nav className="navbar-links d-flex align-items-center">
      <Link className="nav_label" to="/proizvodi">
        {strings.nav_products_label}
      </Link>
      <OutsideClickHandler onOutsideClick={handleCloseDropdown}>
        <Dropdown id="dropdown-nav" onClick={handleClickedDropdown}>
          <Dropdown.Toggle
            variant="transparent"
            id="dropdown-nav-toggle"
            className={clickedMore ? "nav_label nav-label-strong" : "nav_label"}
          >
            {strings.nav_partner_label}
            <img
              src={require("./assets/Arrow-down.svg").default}
              alt="handbag_icon"
              className="ms-1"
            />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              href="https://www.partner.ba/o-nama"
              target="_blank"
              className="nav-dropdown-item remove-hover"
            >
              {strings.navmenu_about_us}
            </Dropdown.Item>
            <Dropdown.Item
              href="https://www.partner.ba/klub-korisnika/energijska-efikasnost"
              target="_blank"
              className="nav-dropdown-item remove-hover"
            >
              {strings.nav_energy_efficient_label}
            </Dropdown.Item>
            <Dropdown.Item
              href="https://partnerkrediti.online/"
              target="_blank"
              className="nav-dropdown-item remove-hover"
            >
              {strings.navmenu_microcredit}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </OutsideClickHandler>
    </Nav>
  );
};

export default NavMenuFirst;
