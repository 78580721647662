/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { strings } from "../Translations/strings";
import "./NavMenu.scss";

const NavLinksMobile = ({ mobileMenuOpen, controlMobileMenuVisibility }) => {
  const menuClass = mobileMenuOpen ? "hamburger active" : "hamburger";
  const { profile } = useSelector((state) => state.profile);
  const { wishlistItems } = useSelector((state) => state.wishlist);

  return (
    <div className="nav-links-mobile">
      <Nav.Link
        className="d-flex align-items-center user-icon"
        href={profile?.data?.firstName ? "/profil" : "/login"}
      >
        <img
          src={require("./assets/UserCircle.svg").default}
          alt="usercircle_icon"
        />
      </Nav.Link>
      <Link
        className="d-flex align-items-center jobs-icon"
        to="/lista-zelja"
      >
        <img
          src={
            wishlistItems.length === 0
              ? require("./assets/Handbag.svg").default
              : require("./assets/Handbag-notification.svg").default
          }
          alt="handbag_icon"
        />
      </Link>
      <div className={menuClass} onClick={controlMobileMenuVisibility}>
        <a className="main-nav-toggle">
          <i>{strings.mobile_menu_label}</i>
        </a>
      </div>
    </div>
  );
};

export default NavLinksMobile;
