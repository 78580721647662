import React, { useEffect, useState } from "react";
import "./ProductDetail.scss";
import { Form, ProgressBar, Button } from "react-bootstrap";
import { strings } from "../../../Translations/strings";
import { useLocation } from "react-router-dom";
import logo from "../../../Assets/logo-small.svg";
//import currency from "currency.js";
import { addToWishlist } from "../../../Wishlist/wishlistSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { controlOpenModal } from "../../../Calculator/state/calculatorSlice";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../Analytics/config";
import { RegisterToChat } from "../RegisterModal/index";
import { LoanExampleModal } from "../LoanExampleModal/LoanExampleModal";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { httpClient } from "../../../Http/client";
import { BASE_URL } from "../../../Environment/config";

const ProductDetailSidebar = ({
  toggleModal,
  product,
  productSaving,
  numberOfMonths,
  setNumberOfMonths,
  loanNeeded,
  setLoanNeeded,
  id,
  getLoanDetails,
  setLoanDetails,
  loanExample,
  loanDetails,
}) => {
  const { wishlistItems } = useSelector((state) => state.wishlist);
  const { profile } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const location = useLocation();
  const { categories } = useSelector((state) => state.calculator);
  const currentCategory = location.pathname.split("/")[2];
  const [registerModal, setRegisterModal] = useState(false);
  const [loanExampleModal, setLoanExampleModal] = useState(false);
  const [chatWithVendor, setChatWithVendor] = useState(false);
  const [chatWithPartner, setChatWithPartner] = useState(false);

  const notifyUser = (product) => {
    const itemIndex = wishlistItems.findIndex((item) => item.id === product.id);
    itemIndex >= 0
      ? toast.info(`${product.name} se već nalazi na Vašoj listi želja`, {
          position: "bottom-left",
        })
      : toast.success(`${product.name} je dodat na Vašu listu želja`, {
          position: "bottom-left",
        });
  };

  const handleAddToWishlist = (product) => {
    logEvent(analytics, "product_added_to_card", { name: product?.name });
    dispatch(addToWishlist(product));
    notifyUser(product);
  };

  const matchCategories = (name) => {
    if (name.startsWith("solarni")) {
      return "paneli";
    }
    return name;
  };

  const { price } = product || {};

  const completedCategory =
    categories?.[matchCategories(currentCategory)]?.completed;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location, currentCategory]);

  const handleLoanNeeded = async () => {
    setLoanNeeded(!loanNeeded);
    const loanDetails = await getLoanDetails(id, numberOfMonths);
    setLoanDetails(loanDetails);
  };

  /*const priceToWork = currency(price) / numberOfMonths;
  const formattedPrice = (price * 1).toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });*/

  /*const finalPrice = (price / numberOfMonths).toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });*/

  const getMonthlyAmount = async (months) => {
    const { data } = await httpClient.get(
      `${BASE_URL}/calculateLoanInterestRate?loan=${price}&numberOfMonths=${months}&categoryId=${product?.category?.id}`
    );
    return data;
  };

  const formattedMainPrice = (price * 1).toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const formattedMainPrice1 = formattedMainPrice;

  const openModal = () => {
    dispatch(controlOpenModal(true));
  };
  const openInNewTab = () => {
    if (Number(product?.calculation?.totalInvestment) < 3000) {
      window.open(
        "https://partnerkrediti.online/",
        "_blank",
        "noopener,noreferrer"
      );
      return;
    } else {
      window.open(
        "https://www.partner.ba/zahtjev_za_mk/fizicka_lica/prvi_korak?mk=ee_mp",
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  const toggleRegisterModal = () => {
    setRegisterModal(false);
  };

  const toggleLoanExampleModal = () => {
    setLoanExampleModal(!loanExampleModal);
  };

  const openViberChatWithVendor = () => {
    logEvent(analytics, "contacted_vendor", {
      email: profile?.data?.email,
      firstName: profile?.data?.firstName,
      lastName: profile?.data?.lastName,
      product: product?.name,
      vendor: product?.manufacturer,
    });
    window.open(`viber://chat?number=${product?.vendor?.phone}`, "_self");
  };

  const registerToChatWithVendor = () => {
    setChatWithPartner(false);
    setChatWithVendor(true);
    setRegisterModal(true);
  };

  const openViberChatWithPartner = () => {
    logEvent(analytics, "contacted_partner", {
      email: profile?.data?.email,
      firstName: profile?.data?.firstName,
      lastName: profile?.data?.lastName,
      product: product?.name,
    });
    window.open("viber://chat?number=38762991199", "_self");
  };

  const registerToChatWithPartner = () => {
    setChatWithVendor(false);
    setChatWithPartner(true);
    setRegisterModal(true);
  };

  const [sliderValue, setSliderValue] = useState(Number(numberOfMonths));

  function changeSliderValue(value) {
    setSliderValue(value);
    setNumberOfMonths(value);
  }

  const [monthlyAmount, setMonthlyAmount] = useState();

  const handleDisplayAmount = async (value) => {
    setSliderValue(value);
    setNumberOfMonths(value);
    const { monthlyPayment } = await getMonthlyAmount(value);
    setMonthlyAmount(monthlyPayment);
  };

  const handleGetMonthlyAmount = async () => {
    const { monthlyPayment } = await getMonthlyAmount(Number(numberOfMonths));
    setMonthlyAmount(monthlyPayment);
  };

  useEffect(() => {
    handleGetMonthlyAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  return (
    <>
      <div className="partner-offer d-flex flex-column">
        <img src={logo} alt="logo"></img>
        <Form>
          <Form.Check
            type="checkbox"
            id="custom-switch"
            label={strings.partner_offer}
            onClick={handleLoanNeeded}
          />
        </Form>
        <div className={loanNeeded ? "progress-show" : "progress-hide"}>
          <div className="d-flex justify-content-between">
            <p className="payment-due-date">{strings.product_due_date}</p>
            <p className="payment-due-date">
              <b>{numberOfMonths}</b>
              {numberOfMonths <= 4 ? " mjeseca" : " mjeseci"}
            </p>
          </div>
          <Slider
            railStyle={{ backgroundColor: "#DEE2E6", padding: "4px" }}
            trackStyle={{ backgroundColor: "#DEE2E6", padding: "4px" }}
            handleStyle={{
              border: "none",
              backgroundColor: "#0C6DFD",
              opacity: 1,
              padding: "8px",
              top: "6px",
            }}
            className="financing-range"
            defaultValue={sliderValue}
            min={Number(product?.loanPeriod?.loanPeriodMin)}
            max={Number(product?.loanPeriod?.loanPeriodMax)}
            onChange={changeSliderValue}
            onAfterChange={handleDisplayAmount}
          />
          <p
            className="loan-example-label mt-4"
            onClick={toggleLoanExampleModal}
          >
            {strings.loan_example_label}
          </p>
        </div>
      </div>
      <div className="price-panel d-flex flex-column">
        <p className="price-label">{strings.financing_price_label}</p>
        <p
          className={loanNeeded ? "progress-show full-price" : "progress-hide"}
        >
          {formattedMainPrice1}
        </p>
        <p
          className={
            loanNeeded
              ? "progress-show price-value"
              : "progress-hide price-value"
          }
        >
          {monthlyAmount
            ? monthlyAmount?.toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : "-"}
          {strings.KM_label}
          <span className="monthly-price">
            {strings.product_monthly_rate_label}
          </span>
        </p>
        <p
          className={
            !loanNeeded
              ? "progress-show price-value"
              : "progress-hide price-value"
          }
        >
          {(price * 1).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) +
            " " +
            strings.KM_label}
        </p>
        {completedCategory && (
          <>
            <ProgressBar
              now={
                productSaving?.energySavingsPercentage &&
                productSaving?.energySavingsPercentage <= 100 &&
                productSaving?.energySavingsPercentage >= 0
                  ? productSaving?.energySavingsPercentage
                  : productSaving?.energySavingsPercentage < 0
                  ? 0
                  : productSaving?.energySavingsPercentage > 100
                  ? 100
                  : 0
              }
              className="product-progress-bar"
            />
            <p className="product-savings">
              {strings.financing_energy_savings}{" "}
              <b>
                {productSaving?.energySavingsPercentage &&
                productSaving?.energySavingsPercentage <= 100 &&
                productSaving?.energySavingsPercentage >= 0
                  ? productSaving?.energySavingsPercentage
                  : productSaving?.energySavingsPercentage < 0
                  ? 0
                  : productSaving?.energySavingsPercentage > 100
                  ? 100
                  : 0}
                %
              </b>
            </p>
            <ProgressBar
              now={
                productSaving?.moneySavingsPercentage &&
                productSaving?.moneySavingsPercentage <= 100 &&
                productSaving?.moneySavingsPercentage >= 0
                  ? productSaving?.moneySavingsPercentage
                  : productSaving?.moneySavingsPercentage < 0
                  ? 0
                  : productSaving?.moneySavingsPercentage > 100
                  ? 100
                  : 0
              }
              className="product-progress-bar"
            />
            <p className="product-savings">
              {strings.financing_money_savings}
              <b>
                {productSaving?.moneySavingsPercentage &&
                productSaving?.moneySavingsPercentage <= 100 &&
                productSaving?.moneySavingsPercentage >= 0
                  ? productSaving?.moneySavingsPercentage
                  : productSaving?.moneySavingsPercentage < 0
                  ? 0
                  : productSaving?.moneySavingsPercentage > 100
                  ? 100
                  : 0}
                %
              </b>
            </p>
          </>
        )}
        <Button
          variant="transparent"
          className="financing-button savings-more-details"
          onClick={completedCategory ? toggleModal : openModal}
        >
          {completedCategory
            ? strings.financing_savings_more_details
            : strings.financing_complete_category}
        </Button>
        {completedCategory && (
          <div className="product-text-small">
            {strings.financing_with_partner}
          </div>
        )}
        <hr className="grey-line-one"></hr>
        <Button
          variant="primary"
          className="request-button"
          onClick={openInNewTab}
        >
          {strings.financing_request_button_label}
        </Button>
        <hr className="grey-line"></hr>
        <Button
          variant="transparent"
          className="financing-button"
          onClick={() => handleAddToWishlist(product)}
        >
          {strings.financing_add_button_label}
        </Button>
        <Button
          variant="transparent"
          className="financing-button"
          onClick={
            profile?.data?.firstName
              ? openViberChatWithVendor
              : registerToChatWithVendor
          }
        >
          {strings.financing_question_button_label}
        </Button>
        <Button
          variant="transparent"
          className="financing-button"
          onClick={
            profile?.data?.firstName
              ? openViberChatWithPartner
              : registerToChatWithPartner
          }
        >
          {strings.partner_question_button_label}
        </Button>
      </div>
      <RegisterToChat
        show={registerModal}
        onHide={toggleRegisterModal}
        openViberChatWithVendor={openViberChatWithVendor}
        openViberChatWithPartner={openViberChatWithPartner}
        chatWithVendor={chatWithVendor}
        chatWithPartner={chatWithPartner}
      />
      <LoanExampleModal
        show={loanExampleModal}
        onHide={toggleLoanExampleModal}
        loanExample={loanExample}
      />
    </>
  );
};

export default ProductDetailSidebar;
