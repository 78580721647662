/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./MyProfile.scss";
import { strings } from "../../Translations/strings";
import MyProfileForm from "./MyProfileForm";

const MyProfile = () => {
  return (
    <>
      <section className="section-banner">
        <div className="c-container-products">
          <div className="account-calculator-wrapper">
            <p className="register-user-intro">
              {strings.account_user_identification}
            </p>
            <MyProfileForm />
          </div>
        </div>
      </section>
    </>
  );
};

export default MyProfile;
