import React from "react";
import { Modal } from "react-bootstrap";
import { strings } from "../../../Translations/strings";

export const LoanExampleModal = (props) => {
  const { loanExample } = props;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="savings-header">
        <Modal.Title id="contained-modal-title-vcenter">
          <h4 className="product-modal-title">{strings.loan_example_title}</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 m-0 pt-3">
        <p className="loan-example-info">{loanExample.loanInfo}</p>
        <div className="py-4">
          <p className="loan-example-p">
            {strings.loan_example_type}
            <strong className="ms-1">{loanExample?.loanType}</strong>
          </p>
          <p className="loan-example-p">
            {strings.loan_example_amount}
            <strong className="ms-1">
              {(loanExample.loanAmount * 1).toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              {strings.KM_label}
            </strong>
          </p>
          <p className="loan-example-p">
            {strings.loan_example_due_date}
            <strong className="ms-1">{loanExample.loanPeriod} mjeseci</strong>
          </p>
          <p className="loan-example-p">
            {strings.loan_example_nominal_rate}
            <strong className="ms-1">
              {loanExample?.nominalInterestRate?.replace(".", ",")}{" "}
              {strings.savings_table_percentage_label}
            </strong>
          </p>
          <p className="loan-example-p">
            {strings.loan_example_effective_rate}
            <strong className="ms-1">
              {loanExample?.effectiveInterestRate?.replace(".", ",")}{" "}
              {strings.savings_table_percentage_label}
            </strong>
          </p>
          <p className="loan-example-p">
            {strings.loan_example_monthly_amount}
            <strong className="ms-1">
              {(loanExample.monthlyPayment * 1).toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              {strings.KM_label}
            </strong>
          </p>
          <p className="loan-example-p">
            {strings.loan_example_interest}
            <strong className="ms-1">
              {(loanExample.interest * 1).toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              {strings.KM_label}
            </strong>
          </p>
          <p className="loan-example-p">
            {strings.loan_example_total}
            <strong className="ms-1">
              {(loanExample.total * 1).toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              {strings.KM_label}
            </strong>
          </p>
          <p className="loan-example-p">
            {strings.loan_example_processing_cost}
            <strong className="ms-1">
              {(loanExample.processingFee * 1).toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              {strings.KM_label}
            </strong>
          </p>
          <p className="loan-example-p">
            {strings.loan_example_documentation_verification_cost}
            <strong className="ms-1">
              {(loanExample.documentsFee * 1).toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              {strings.KM_label}
            </strong>
          </p>
        </div>
        <p className="loan-example-info mt-3 mb-5">
          {loanExample.currencyInfo}
        </p>
      </Modal.Body>
      <Modal.Footer> </Modal.Footer>
    </Modal>
  );
};
