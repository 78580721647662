import React from "react";
import "./MainContent.scss";
import pumpIcon from "./assets/pump-icon.svg";
import stoveIcon from "./assets/stove-icon.svg";
import finances from "./assets/finances.svg";
import expenses from "./assets/expenses.svg";
import calculator from "./assets/calculator.svg";
import { strings } from "../../Translations/strings";
import { ReactComponent as IconRightSvg } from "./assets/icon-circle-right.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCategory,
  updateSubcategory,
} from "../../Marketplace/marketPlaceSlice";
import {
  controlIntroModal,
  controlOpenModal,
} from "../../Calculator/state/calculatorSlice";
import SectionImages from "./SectionImages";
import SectionImagesMobile from "./SectionImagesMobile";

const MainContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setCurrentCategory = (category) => {
    dispatch(updateCategory(category));
  };

  const setCurrentSubcategory = (subcategory) => {
    dispatch(updateSubcategory(subcategory));
  };

  const navigateToCategory = (category) => {
    setCurrentCategory(category);
    navigate("/proizvodi/" + category, { state: "/" });
    window.scrollTo(0, 0);
  };
  const { introModalVisible, hasCompletedCategory } = useSelector(
    (state) => state.calculator
  );

  const controlOpeningModalActive = () => {
    if (hasCompletedCategory) {
      return dispatch(controlOpenModal(true));
    }
    dispatch(controlIntroModal(!introModalVisible));
  };
  return (
    <div className="main-content">
      <div className="c-container-sm">
        <div
          className="card-product first"
          onClick={() => {
            setCurrentSubcategory("Toplotne pumpe");
            navigateToCategory("grijanje?subcategory0=1190dfc9-58fe-481e-9745-d6276208c2c9");
          }}
        >
          <div className="icon-container">
            <img src={pumpIcon} alt="pump-icon"></img>
          </div>
          <div className="text-container">
            <h5>{strings.landing_main_section_heat_pump_label}</h5>
            <IconRightSvg />
          </div>
        </div>
        <div
          className="card-product"
          onClick={() => {
            setCurrentSubcategory("Peći na pelet");
            navigateToCategory("grijanje?subcategory1=768f2d6c-1956-4f94-9c78-87b86efe140b");
          }}
        >
          <div className="icon-container">
            <img src={stoveIcon} alt="stoveIcon"></img>
          </div>
          <div className="text-container">
            <h5>{strings.landing_main_section_heat_pelet_label}</h5>
            <IconRightSvg />
          </div>
        </div>
        <SectionImages />
        <SectionImagesMobile />
        <section className="section-finances">
          <div className="row">
            <div className="col-md-6">
              <img src={finances} alt="finances"></img>
            </div>
            <div className="col-md-6 text">
              <h3>{strings.landing_main_fast_finacing_label}</h3>
              <p>{strings.landing_main_fast_finacing_text}</p>
            </div>
          </div>
        </section>
        <section className="section-expenses">
          <div className="row">
            <div className="col-md-6 text" id="a">
              <h3>{strings.landing_main_reduce_monthly_costs_label}</h3>
              <p>
                {strings.landing_main_reduce_monthly_costs_text_one}
                <span className="expenses-bold">
                  {strings.landing_main_reduce_monthly_costs_text_two}
                </span>
                {strings.landing_main_reduce_monthly_costs_text_three}
                <span className="expenses-bold">
                  {strings.landing_main_reduce_monthly_costs_text_four}
                </span>
                {strings.landing_main_reduce_monthly_costs_text_five}
                <span className="expenses-bold">
                  {strings.landing_main_reduce_monthly_costs_text_six}
                </span>
              </p>
            </div>
            <div className="col-md-6" id="b">
              <img src={expenses} alt="expenses"></img>
            </div>
          </div>
        </section>
        <section className="section-calculator">
          <div className="text-container">
            <h4>{strings.landing_main_calculator_effieciency_label}</h4>
            <p>{strings.landing_main_calculator_effieciency_text}</p>
            <button
              className="btn btn-green home-button"
              onClick={controlOpeningModalActive}
            >
              {strings.landing_main_calculate_possible_saving_label}
            </button>
          </div>
          <img src={calculator} alt="calculator"></img>
          <div className="circle c-1"></div>
          <div className="circle c-2"></div>
          <div className="circle c-3"></div>
        </section>
      </div>
    </div>
  );
};

export default MainContent;
