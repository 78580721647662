/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Breadcrumb } from "react-bootstrap";
import "./Products.scss";
import { strings } from "../../Translations/strings";
import { Link } from "react-router-dom";

const ProductsBreadcrumbs = ({ currentCategory }) => {
  const categoryTitle = currentCategory === 'search' ? 'Pretraga' : currentCategory.replace(/-/g, " ").replace(/dj/g, "đ")
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">
          {strings.category_breadcrumb_home}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/proizvodi">
          {strings.category_breadcrumb_products}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active style={{ textTransform: "capitalize" }}>
          {categoryTitle}
        </Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
};

export default ProductsBreadcrumbs;
