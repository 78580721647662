import React from "react";
import "./Wishlist.scss";
import NavProducts from "../Marketplace/NavProducts/NavProducts";
import Footer from "../Common/Footer/Footer";
import WishlistProducts from "./WishlistProducts";

const Wishlist = () => {
  return (
    <>
      <NavProducts />
      <WishlistProducts />
      <Footer />
    </>
  );
};
export default Wishlist;
