import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { strings } from "../../Translations/strings";
import "../Auth.scss";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import InputError from "../../Common/Error/InputError";
import { loginUser } from "./lib";
import { setDataInStorage } from "../../Storage";
import { useDispatch } from "react-redux";
import { fetchProfile } from "../../Profile/profileSlice";
import { setAuthData } from "../authSlice";

const LoginForm = () => {
  const [loginInProgress, setLoginInProgress] = useState(false);
  const dispatch = useDispatch();

  const [loginError, setLoginError] = useState(null);
  const navigation = useNavigate();

  const schema = yup.object().shape({
    email: yup
      .string()
      .required(strings.validation_field_required_label)
      .email(strings.validation_field_email),
    password: yup
      .string()
      .min(6, strings.validation_field_password)
      .required(strings.validation_field_password_required),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const onSubmit = async (data) => {
    setLoginInProgress(true);
    const loginSuccessData = await loginUser(data).catch((err) => {
      setLoginInProgress(false);
      setLoginError(strings.login_error_label);
    });
    if (loginSuccessData) {
      dispatch(setAuthData(loginSuccessData));
      setDataInStorage({ name: "auth", data: loginSuccessData });
      dispatch(fetchProfile());
      setLoginInProgress(false);
      navigation("/");
    }
  };

  const errorStyle = {
    display: "block",
    marginTop: "0px !important",
    textAlign: "center",
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3 required" controlId="formBasicEmail">
          <Form.Label aria-required className="control-label">
            {strings.email_input_label}
          </Form.Label>
          <Form.Control
            type="email"
            isInvalid={errors?.email?.message}
            onKeyPress={(e) => {
              if (e.code === "Space" || e.keyCode === 32) {
                e.preventDefault();
              }
            }}
            placeholder={strings.email_input_placeholder}
            {...register("email", { required: true })}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.email?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label className="control-label">
            {strings.password_input_label}
          </Form.Label>
          <Form.Control
            type="password"
            onKeyPress={(e) => {
              if (e.code === "Space" || e.keyCode === 32) {
                e.preventDefault();
              }
            }}
            isInvalid={errors?.password?.message}
            placeholder={strings.password_input_label}
            {...register("password", { required: true })}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.password?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <InputError message={errors?.password?.message} />
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            label={strings.login_remember_password_label}
            className="remember-password"
          />
        </Form.Group>
        {loginError && <InputError message={loginError} style={errorStyle} />}
        <Button
          variant="primary"
          className="submit-form-register"
          type="submit"
        >
          {loginInProgress ? (
            <div className="spinner-border text-light" role="status"></div>
          ) : (
            strings.login_form_login_button_label
          )}
        </Button>
      </Form>
    </>
  );
};

export default LoginForm;
