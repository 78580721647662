import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import calculatorReducer from "../Calculator/state/calculatorSlice";
import marketPlaceReducer from "../Marketplace/marketPlaceSlice";
import persistStore from "redux-persist/es/persistStore";
import profileReducer from "../Profile/profileSlice";
import authReducer from "../Auth/authSlice";
import wishlistSlice from "../Wishlist/wishlistSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["profile", "auth", "calculator", "wishlist"],
};

const reducers = combineReducers({
  calculator: calculatorReducer,
  marketPlace: marketPlaceReducer,
  profile: profileReducer,
  auth: authReducer,
  wishlist: wishlistSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
