import { React } from "react";
import "./CalculatorModal.scss";
import Modal from "react-modal";
import mapper from "../mapper";
import closeIcon from "../assets/close-icon-dark.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  controlOpenModal,
  updateCurrentIndex,
  updateQuestions,
} from "../state/calculatorSlice";
import GroupOverview from "../GroupOverview";
import Result from "../Result";
import CalculatorSidebar from "../CalculatorSidebar/CalculatorSidebar";
import MobileCalculatorNav from "../MobileCalculatorNav/MobileCalculatorNav";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../Analytics/config";

const CalculatorModal = () => {
  const {
    questions,
    modalCalculatorVisible,
    currentIndex,
    currentCategory,
    categories,
    displayResult,
    groupOverview,
    hasCompletedCategory
  } = useSelector((state) => state.calculator);
  const dispatch = useDispatch();

  const currentQuestion = questions[currentIndex];
  const setAnswer = (answer, slug) => {
    dispatch(updateQuestions({ answer, slug }));
  };

  const updateIndex = (index) => {
    dispatch(updateCurrentIndex(index));
  };

  const onClose = () => {
    dispatch(controlOpenModal(false));
    logEvent(analytics, "calculator_exit", { category: currentCategory?.title, index: currentIndex });
  };

  const Component = mapper({
    type: currentQuestion?.type,
    answer: currentQuestion?.answer,
    title: currentQuestion?.title,
    options: currentQuestion?.options,
    icon: currentQuestion?.icon,
    placeHolder: currentQuestion?.placeHolder,
    currentCategory: currentCategory,
    category: currentCategory?.title,
    onClose,
    setAnswer,
    updateIndex,
    currentIndex,
  });


  const sidebarIndex = currentIndex + 1 > 3 ? 3 : currentIndex + 1;
  const sidebarData = questions?.slice(0, sidebarIndex);
  const sideBarProgressToShow = currentQuestion?.step;
  const numOfSteps = questions.length;

  return (
    <Modal
      isOpen={modalCalculatorVisible}
      overlayClassName="calc-overlay"
      className="calc-modal"
      appElement={document.getElementById("root") || undefined}
    >
      <div
        className="calculator-modal calculator-modal-scroll"
      >
        <div className="calculator-modal-close" onClick={onClose}>
          <img src={closeIcon} type="" alt="" />
        </div>
          <>
            <div className="sidebar-wrapper">
              <CalculatorSidebar
                currentQuestion={currentQuestion}
                sidebarData={sidebarData}
                step={sidebarIndex}
                progress={sideBarProgressToShow}
                questions={questions}
              />
            </div>
            <div
              className={
                groupOverview
                  ? "mobile-sidebar-wrapper-hide"
                  : "mobile-sidebar-wrappper"
              }
            >
              <MobileCalculatorNav
                sidebarData={sidebarData}
                progress={sideBarProgressToShow}
                currentStep={sidebarIndex}
                title={"Tip Korisnika"}
                question={currentQuestion}
                numOfSteps={numOfSteps}
                currentIndex={currentIndex}
                questions={questions}
              ></MobileCalculatorNav>
            </div>
            <>
              {displayResult && (
                <Result
                  category={currentCategory}
                  icon={currentCategory.icon}
                />
              )}
              {(!hasCompletedCategory || questions.length > 0) && !displayResult ? (
                Component
              ) : (
                <>
                  {!displayResult && <GroupOverview categories={categories} />}
                </>
              )}
            </>
          </>
      </div>
    </Modal>
  );
};

export default CalculatorModal;
