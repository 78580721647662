import React from "react";
import "./MobileCalculatorNav.scss";
import calculatorLogoMobile from "../assets/calculator-logo-mobile.png";
import closeIcon from "../assets/calculator-close-icon.svg";
import backgroundBottom from "../assets/calculator-mobile-bgd-bottom.svg";
import { controlOpenModal } from "../state/calculatorSlice";
import { useDispatch, useSelector } from "react-redux";

export default function MobileCalculatorNav({
  numOfSteps,
  sidebarData,
  progress,
  currentIndex,
}) {
  const startingProgressBarValue = (currentIndex * 100) / 10;
  const progressBarValue = (currentIndex * 100) / numOfSteps;
  const { groupOverview, displayResult } = useSelector(
    (state) => state.calculator
  );

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(controlOpenModal(false));
  };

  return (
    <div className={!groupOverview ? "mobile-calculator-nav" : ""}>
      <div className="logo-mobile-wrapper">
        <img src={calculatorLogoMobile} alt="calculator_logo" />
      </div>
      <button className="button-close" onClick={onClose}>
        <img src={closeIcon} type="" alt="close_icon" />
      </button>

      {!displayResult ? (
        <div className={currentIndex === 0 ? "step-hide" : "progress-bar"}>
          <div
            className={
              currentIndex > 0
                ? "value progress-mobile-calculator green-infinite-progress-bar"
                : "value progress-mobile-calculator green-infinite-progress-bar"
            }
            style={
              currentIndex > 1
                ? { width: progressBarValue + "%" }
                : { width: startingProgressBarValue + "%" }
            }
          ></div>
        </div>
      ) : (
        ""
      )}
      <p className={!displayResult ? "step" : "step-hide"}>{progress}</p>
      <div className="backg">
        {sidebarData
          ?.map((question, index) => {
            return (
              <div key={question?.title} className="step">
                <span className="step-name">
                  {!displayResult ? question?.sideBarTitle : ""}
                </span>
              </div>
            );
          })
          .slice(-1)}
        {displayResult && <span className="step step-name">Rezultati</span>}
      </div>
      <img src={backgroundBottom} alt="background" className="bgd-bottom" />
    </div>
  );
}
