import React, { useState } from "react";
import NavMenu from "../NavMenu/NavMenu";
import "./NotFound.scss";
import notFound from "./assets/404.svg";
import logoMobile from "../Home/HeaderHero/assets/logo-mobile.svg";
import { strings } from "../Translations/strings";
import MobileMenu from "../Common/MobileMenu/MobileMenu";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const NotFound = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const mobileMenuClass = mobileMenuOpen ? "mobile-menu active" : "mobile-menu";
  const { introModalVisible } = useSelector((state) => state.calculator);
  const controlMobileMenuVisibility = () => setMobileMenuOpen(!mobileMenuOpen);

  return (
    <div className="not-found-container">
      <div className="c-container ">
        <NavMenu
          isMobileVisible={!introModalVisible}
          mobileMenuOpen={mobileMenuOpen}
          controlMobileMenuVisibility={controlMobileMenuVisibility}
        />
        <div className="d-flex not-found-wrapper align-items-center">
          <div className="d-block d-lg-none not-found-img-container">
            <img src={notFound} alt="not_found"></img>
          </div>
          <div className="not-found-info-container">
            <h3>{strings.not_found_title}</h3>
            <p>{strings.not_found_subtitle}</p>
            <button className="btn btn-outline-primary">
              <Link to="/">{strings.not_found_btn_label}</Link>
            </button>
          </div>
          <div className="d-none d-lg-block not-found-img-container">
            <img src={notFound} alt="not_found"></img>
          </div>
        </div>
      </div>
      <MobileMenu mobileMenuClass={mobileMenuClass} logoMobile={logoMobile} />
    </div>
  );
};
export default NotFound;
