import React, { useState } from "react";
import { strings } from "../../Translations/strings";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  controlIntroModal,
  controlOpenModal,
} from "../../Calculator/state/calculatorSlice";
import { persistor } from "../../State/store";
import { Link } from "react-router-dom";

const MobileMenu = ({ logoMobile, mobileMenuClass }) => {
  const { introModalVisible, hasCompletedCategory } = useSelector(
    (state) => state.calculator
  );
  const dispatch = useDispatch();
  const [moreOptions, setMoreOptions] = useState(false);

  const handleMoreOptions = () => {
    setMoreOptions(!moreOptions);
  };
  const controlOpeningModalActive = () => {
    if (hasCompletedCategory) {
      return dispatch(controlOpenModal(true));
    }
    dispatch(controlIntroModal(!introModalVisible));
  };

  const { profile } = useSelector((state) => state.profile);

  const handleLogOut = () => {
    persistor.purge().then(() => console.log(profile));
    window.location.reload();
  };

  return (
    <div className={mobileMenuClass} id="mobile-menu">
      <div className="logo-container">
        <img src={logoMobile} alt="logo" />
      </div>
      <div className="nav-container">
        <ul>
          <li className="mt-5">
            <Link to="/proizvodi">
              {strings.nav_products_label}
              <img src={require("./assets/arrow-right.svg").default} alt="" />
            </Link>
          </li>
          <li onClick={handleMoreOptions} className="mobile-menu-more-options">
            {strings.nav_finansing_label}
            <img
              src={require("./assets/arrow-right.svg").default}
              className={moreOptions ? "d-none" : "d-block"}
              alt=""
            />
          </li>
          <div className={moreOptions ? "d-block" : "d-none"}>
            <li className="mt-0 mb-3 ps-3">
              <a
                href="https://www.partner.ba/o-nama"
                target="_blank"
                rel="noreferrer"
                className="mobile-menu-submenu-item"
              >
                {strings.navmenu_about_us}
                <img src={require("./assets/arrow-right.svg").default} alt="" />
              </a>
            </li>
            <li className="mt-0 mb-3 ps-3 mobile-menu-submenu-item">
              <a
                href="https://www.partner.ba/klub-korisnika/energijska-efikasnost"
                target="_blank"
                rel="noreferrer"
                className="mobile-menu-submenu-item"
              >
                {strings.navmenu_ee_label}
                <img src={require("./assets/arrow-right.svg").default} alt="" />
              </a>
            </li>
            <li className="mt-0 ps-3 mobile-menu-submenu-item">
              <a
                href="https://partnerkrediti.online/"
                target="_blank"
                rel="noreferrer"
                className="mobile-menu-submenu-item"
              >
                {strings.navmenu_microcredit}
                <img src={require("./assets/arrow-right.svg").default} alt="" />
              </a>
            </li>
          </div>
          <li>
            <a
              className="my-profile"
              href={profile?.data?.firstName ? "/profil" : "/login"}
            >
              <img
                src={require("./assets/user-circle.svg").default}
                alt="logo"
              />
              {strings.nav_my_profile_label}
            </a>
          </li>
          <hr
            className={profile?.data?.firstName ? "show-logout" : "hide-logout"}
          ></hr>
          <li
            className={profile?.data?.firstName ? "show-logout" : "hide-logout"}
            onClick={handleLogOut}
          >
            <Link className="my-profile" to="/">
              <img src={require("./assets/logout.svg").default} alt="logo" />
              {strings.mobile_menu_logout}
            </Link>
          </li>
        </ul>
        <button
          className={
            moreOptions && profile?.data?.firstName === undefined
              ? "btn btn-green home-button mobile-btn"
              : profile?.data?.firstName && !moreOptions
              ? "btn btn-green home-button mobile-btn-logged-in"
              : "btn btn-green home-button mobile-btn"
          }
          onClick={controlOpeningModalActive}
        >
          {strings.nav_ee_calculator_navbar}
        </button>
      </div>
    </div>
  );
};

export default MobileMenu;
