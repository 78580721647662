import React from "react";
import { strings } from "../../../Translations/strings";
import "../CalculatorSidebar.scss";

const CompletedCategory = ({
  groupOverview,
  sidebarData,
  currentCategory,
  progress,
  displayResult,
  questions,
}) => {
  return (
    <>
      <div className="sidebar-nav">
        {!groupOverview ? (
          <>
            <div className="step done">
              <button className="btn-step-number step-done">{1}</button>
              <span className="step">
                {strings.completed_category_user_type}
              </span>
            </div>
            <div className="step done">
              <button className="btn-step-number step-done">{2}</button>
              <span className="step">{strings.completed_category_options}</span>
            </div>
            <div className="step done">
              <button className="btn-step-number step-done">{3}</button>
              <span className="step">
                {currentCategory?.title}
                {progress}
              </span>
            </div>
          </>
        ) : (
          ""
        )}
        {displayResult ? (
          <>
            <div className="step done">
              <button className="btn-step-number step-done">{4}</button>
              <span className="step">{strings.completed_category_result}</span>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default CompletedCategory;
