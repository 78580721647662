import React, { useState, useEffect } from "react";
import "./GroupOverview.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  editOneAnswer,
  restartCategory,
  updateOnFieldChange,
  controlGroupOverview,
  startCategory,
} from "../../Calculator/state/calculatorSlice";
import mapper from "../mapper";
import OverviewMain from "./OverviewMain/OverviewMain";
import EditCategoryView from "./EditCategoryView/EditCategoryView";
import { updateAnswers } from "../lib/lib";

const GroupOverview = () => {
  const { currentIndex, categories, isEditingOneField } = useSelector(
    (state) => state.calculator
  );
  const [questionToEdit, setQuestionToEdit] = useState(null);
  const [editCategory, setEditCategory] = useState(null);
  const dispatch = useDispatch();

  const categoriesAsArray = Object.keys(categories).map((key) => {
    return categories[key];
  });
  useEffect(() => {
    dispatch(controlGroupOverview(true));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAnswer = (answer) => {
    setQuestionToEdit({ ...questionToEdit, answer });
  };

  const updateIndex = () => {
    updateAnswers({
      questionId: questionToEdit.id,
      answer: { value: questionToEdit.answer}
    })
    dispatch(
      updateOnFieldChange({
        answer: questionToEdit.answer,
        slug: editCategory?.slug,
        title: questionToEdit.title,
      })
    );
  };

  const openEditCategory = (category) => {
    if (editCategory) {
      setEditCategory(!editCategory);
    }
    setEditCategory(category);
  };

  const restartCategoryHandler = (cat) => {
    dispatch(restartCategory(cat));
  };

  const onClose = () => dispatch(editOneAnswer(false));

  const Component = mapper({
    type: questionToEdit?.type,
    answer: questionToEdit?.answer,
    title: questionToEdit?.title,
    options: questionToEdit?.options,
    icon: questionToEdit?.icon,
    placeHolder: questionToEdit?.placeHolder,
    currentCategory: editCategory?.title,
    category: questionToEdit?.category,
    onClose,
    setAnswer,
    updateIndex,
    currentIndex,
  });

  const onPressEditQuestion = (question) => {
    setQuestionToEdit(question);
    dispatch(editOneAnswer(true));
  };
  if (isEditingOneField) {
    return Component;
  }
  const openCalculator = (cat) => {
    dispatch(controlGroupOverview(false));
    dispatch(startCategory(cat));
  };

  return (
    <div className="group-overview-container">
      <div className="overview-modal"></div>
      <div className="group-overview-panel">
        {!editCategory ? (
          <OverviewMain
            categoriesAsArray={categoriesAsArray}
            openEditCategory={openEditCategory}
            openCalculator={openCalculator}
          />
        ) : (
          <EditCategoryView
            openEditCategory={openEditCategory}
            editCategory={editCategory}
            restartCategoryHandler={restartCategoryHandler}
            categoriesAsArray={categoriesAsArray}
            onPressEditQuestion={onPressEditQuestion}
          />
        )}
      </div>
    </div>
  );
};

export default GroupOverview;
