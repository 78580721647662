import React from "react";
import { Nav } from "react-bootstrap";
import "./Submenu.scss";
import { strings } from "../../Translations/strings";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateSubcategory, updateCategory } from "../marketPlaceSlice";

const Submenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateToProducts = (category) => {
    dispatch(updateSubcategory(null));
    navigate("/proizvodi/" + category);
    dispatch(updateCategory(category));
  };

  const location = useLocation();
  const url = location.pathname;

  return (
    <div className="submenu">
      <div className="c-container">
        <Nav className="submenu-nav">
          <Nav.Link
            className={
              url === "/proizvodi/grijanje"
                ? "submenu_nav_label submenu-active-link"
                : "submenu_nav_label"
            }
            onClick={() => {
              navigateToProducts("grijanje");
            }}
          >
            {strings.submenu_heating_label}
          </Nav.Link>
          <Nav.Link
            className={
              url === "/proizvodi/solarni-sistemi"
                ? "submenu_nav_label submenu-active-link"
                : "submenu_nav_label"
            }
            onClick={() => {
              navigateToProducts("solarni-sistemi");
            }}
          >
            {strings.submenu_solar_label}
          </Nav.Link>
          <Nav.Link
            className={
              url === "/proizvodi/prozori"
                ? "submenu_nav_label submenu-active-link"
                : "submenu_nav_label"
            }
            onClick={() => {
              navigateToProducts("prozori");
            }}
          >
            {strings.submenu_windows_label}
          </Nav.Link>
          <Nav.Link
            className={
              url === "/proizvodi/izolacija"
                ? "submenu_nav_label submenu-active-link"
                : "submenu_nav_label"
            }
            onClick={() => {
              navigateToProducts("izolacija");
            }}
          >
            {strings.submenu_isolation_label}
          </Nav.Link>
        </Nav>
      </div>
    </div>
  );
};

export default Submenu;
