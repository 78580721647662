import React from "react";
import "./ProductDetail.scss";
import { Tabs, Tab } from "react-bootstrap";
import { strings } from "../../../Translations/strings";
import pdfIcon from "../assets/pdf-icon.svg";

const ProductDetailTabs = ({ product }) => {
  return (
    <>
      <Tabs
        defaultActiveKey="about"
        id="product-detail-tabs"
        className="mb-3 product-detail-tabs"
      >
        <Tab
          eventKey="about"
          title={strings.product_tab_one}
          className="tab-label mobile-even-tabs justify-product-description"
        >
          <div className="product-description">{product.description}</div>
        </Tab>
        <Tab
          eventKey="documents"
          title={strings.product_tab_three}
          className="tab-label mobile-even-tabs"
        >
          <div className="product-description">
            {product?.files?.map((file) => {
              return (
                <div className="d-flex align-items-start">
                  <img
                    src={pdfIcon}
                    alt="download_pdf"
                    className="pdf-icon"
                  ></img>
                  <a href={file.url} download>
                    <p className="pdf-document">{file?.filename} </p>
                  </a>
                </div>
              );
            })}
          </div>
        </Tab>
      </Tabs>
    </>
  );
};

export default ProductDetailTabs;
