import React, { useState, useEffect } from "react";
import "./ProductDetail.scss";
import { strings } from "../../../Translations/strings";
import { useLocation } from "react-router-dom";
import Footer from "../../../Common/Footer/Footer";
import ProductDetailBreadcrumbs from "./ProductDetailBreadcrumbs";
import ProductDetailTabs from "./ProductDetailTabs";
import ProductDetailSidebar from "./ProductDetailSidebar";
import { SavingsModal } from "./SavingsModal";
import { SendQuestion } from "./SendQuestion";
import { ProductImagesSlider } from "./ProductImagesSlider";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import goldStar from "../assets/gold-star.svg";
import { httpClient } from "../../../Http/client";
import { BASE_URL } from "../../../Environment/config";
import MarketplaceNav from "../../MarketplaceNav.js/MarketplaceNav";
import { useSelector } from "react-redux";
import { mapCategoryIdToCategoryName } from "../../../Calculator/state/calculatorSlice";
import { RegisterToChat } from "../RegisterModal";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../Analytics/config";

const getProduct = async (id) => {
  const { data } = await httpClient.post(`${BASE_URL}/public/products/${id}`);
  return data;
};

const getLoanDetails = async (id, numberOfMonths) => {
  const { data } = await httpClient.post(
    `${BASE_URL}/public/products/details/${id}?numberOfMonths=${numberOfMonths}`
  );
  return data;
};

const getProductWithouAuth = async (id, answers, customerType) => {
  try {
    const response = await httpClient.post(
      `${BASE_URL}/public/products/${id}`,
      {
        answers,
        type: customerType,
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const getLoanDetailsWithoutAuth = async (
  id,
  numberOfMonths,
  answers,
  customerType
) => {
  try {
    const response = await httpClient.post(
      `${BASE_URL}/public/products/details/${id}?numberOfMonths=${numberOfMonths}`,
      {
        answers,
        type: customerType,
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const getLoanExampleData = async () => {
  try {
    const response = await httpClient.get(`${BASE_URL}/admin/loanExample`);
    return response.data;
  } catch (error) {
    return error;
  }
};

const ProductDetail = () => {
  const location = useLocation();
  const { state: product, pathname: pathnames } = location;
  const { profile } = useSelector((state) => state.profile);
  const { categories, customerType } = useSelector((state) => state.calculator);
  const [productInState, setProductInState] = useState(product || {});
  const [modalShow, setModalShow] = useState(false);
  const [productSaving, setProductSaving] = useState();
  const [numberOfMonths, setNumberOfMonths] = useState(36);
  const [questionModalShow, setQuestionModalShow] = useState(false);
  const [loanDetails, setLoanDetails] = useState({});
  const [loanNeeded, setLoanNeeded] = useState(false);
  const [loanExample, setLoanExample] = useState({});
  const [chatWithVendor, setChatWithVendor] = useState(false);
  const [chatWithPartner, setChatWithPartner] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);

  const isLoggedIn = Object.keys(profile?.data)?.length > 0;
  const toggleQuestionModal = () => setQuestionModalShow(!questionModalShow);

  const [sliderModalShow, setSliderModalShow] = useState(false);
  const toggleSliderModal = () => setSliderModalShow(!sliderModalShow);

  const toggleRegisterModal = () => {
    setRegisterModal(false);
  };

  const registerToChatWithVendor = () => {
    setChatWithPartner(false);
    setChatWithVendor(true);
    setRegisterModal(true);
  };

  const openViberChatWithPartner = () => {
    logEvent(analytics, "contacted_partner", {
      email: profile?.data?.email,
      firstName: profile?.data?.firstName,
      lastName: profile?.data?.lastName,
      product: product?.name,
    });
    window.open("viber://chat?number=38762991199", "_self");
  };

  const openViberChatWithVendor = () => {
    logEvent(analytics, "contacted_vendor", {
      email: profile?.data?.email,
      firstName: profile?.data?.firstName,
      lastName: profile?.data?.lastName,
      product: product?.name,
      vendor: product?.manufacturer,
    });
    window.open(`viber://chat?number=${product?.vendor?.phone}`, "_self");
  };

  const toggleModal = async () => {
    if (!modalShow) {
      if (isLoggedIn) {
        const loanDetails = await getLoanDetails(id, numberOfMonths);
        setLoanDetails(loanDetails);
      } else {
        const categoryName = mapCategoryIdToCategoryName({
          id: product?.category?.id,
        });
        const category = categories[categoryName];
        const answers = category.questions.map((question) => {
          return { questionId: question.id, value: question.answer };
        });
        const loanDetails = await getLoanDetailsWithoutAuth(
          id,
          numberOfMonths,
          answers,
          customerType
        );
        setLoanDetails(loanDetails);
      }
    }
    setModalShow(!modalShow);
  };

  const { id } = product || {};

  useEffect(() => {
    const loadExample = async () => {
      const example = await getLoanExampleData();
      if (example) {
        setLoanExample(example?.[0]);
      }
    };
    loadExample();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      const getProductDetail = async () => {
        const product = await getProduct(
          id || pathnames.split("/").slice(-1)
        ).catch((err) => console.log(err));
        if (product) {
          setProductSaving(product?.calculation);
          setProductInState(product);
          logEvent(analytics, "product_opened", product);
        }
      };
      getProductDetail();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    } else {
      const getProductDetail = async () => {
        const categoryName = mapCategoryIdToCategoryName({
          id: product?.category?.id,
        });
        const category = categories[categoryName];
        const answers = category?.questions?.map((question) => {
          return { questionId: question.id, value: question.answer };
        });
        const productDetails = await getProductWithouAuth(
          id || pathnames.split("/").slice(-1),
          answers,
          customerType || "PHYSICAL_PERSON"
        );
        if (productDetails) {
          setProductSaving(productDetails?.calculation);
          setProductInState(productDetails);
          logEvent(analytics, "product_opened", productDetails);
        }
      };
      getProductDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [product]);

  return (
    <>
      <>
        <MarketplaceNav />
        <section className="section-banner">
          <div className="c-container-products">
            <div className="category-breadcrumbs">
              <ProductDetailBreadcrumbs product={productInState} />
            </div>
            <div className="d-flex single-product-container">
              <div className="col-xl-6 d-flex flex-column product-info-container">
                <div className="d-flex flex-row product-heading">
                  <div
                    onClick={toggleSliderModal}
                    className="product-heading-img"
                  >
                    <img
                      src={productInState?.images?.[0]?.url}
                      alt="product"
                      className="product-heading-img"
                    ></img>
                  </div>
                  <div className="d-flex flex-column">
                    <p className="product-category-label d-none d-lg-flex">
                      {productInState.category?.title}
                    </p>
                    <h4>{productInState.name}</h4>
                    <p className="product-category-label d-flex d-lg-none">
                      {strings.category_breadcrumb_heating}
                    </p>
                    <p className="vendor-label d-flex d-lg-none">
                      {strings.product_vendor_label}
                      <span className="vendor-name">
                        {productInState?.manufacturer}
                      </span>
                    </p>
                    <p className="product-promo-info d-none d-lg-flex">
                      {productInState.calculation?.yearlyMoneySavings
                        ? strings.product_description_short_one +
                          " " +
                          productInState.calculation?.yearlyMoneySavings +
                          " " +
                          strings.product_description_short_two +
                          " "
                        : strings.product_description_short}
                    </p>
                    <p className="vendor-label d-none d-lg-flex">
                      {strings.product_vendor_label}
                      <span className="vendor-name">
                        {productInState?.manufacturer}
                      </span>
                    </p>
                    {["top"].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip
                            id={`tooltip-${placement}`}
                            className={
                              productInState?.discount?.discountMessage
                                ? "tooltip"
                                : "d-none"
                            }
                          >
                            {productInState?.discount?.discountMessage}
                          </Tooltip>
                        }
                      >
                        <div
                          className={
                            productInState?.discount?.discountPercentage
                              ? "d-flex discount-badge"
                              : "d-none"
                          }
                        >
                          <img
                            src={goldStar}
                            alt="badge"
                            className="discount-badge-img"
                          ></img>
                          <span>
                            <b>
                              -{productInState?.discount?.discountPercentage}%{" "}
                            </b>
                            {strings.discount_label}
                          </span>
                        </div>
                      </OverlayTrigger>
                    ))}
                  </div>
                </div>
                <div className="d-flex flex-column single-product-tabs">
                  <ProductDetailTabs product={productInState} />
                </div>
              </div>
              <div className="col-xl-4 d-flex flex-column product-financing">
                <ProductDetailSidebar
                  toggleModal={toggleModal}
                  toggleQuestionModal={toggleQuestionModal}
                  product={productInState}
                  productSaving={productSaving}
                  numberOfMonths={numberOfMonths}
                  setNumberOfMonths={setNumberOfMonths}
                  loanNeeded={loanNeeded}
                  setLoanNeeded={setLoanNeeded}
                  loanDetails={loanDetails}
                  getLoanDetails={getLoanDetails}
                  setLoanDetails={setLoanDetails}
                  id={id}
                  loanExample={loanExample}
                />
              </div>
            </div>
          </div>
        </section>
      </>
      <SavingsModal
        show={modalShow}
        onHide={toggleModal}
        toggleQuestionModal={toggleQuestionModal}
        productSaving={productSaving}
        loanDetails={loanDetails}
        product={productInState}
        loanNeeded={loanNeeded}
        openViber={
          profile?.data?.firstName
            ? openViberChatWithVendor
            : registerToChatWithVendor
        }
      />
      <RegisterToChat
        show={registerModal}
        onHide={toggleRegisterModal}
        openViberChatWithVendor={openViberChatWithVendor}
        openViberChatWithPartner={openViberChatWithPartner}
        chatWithVendor={chatWithVendor}
        chatWithPartner={chatWithPartner}
      />
      <SendQuestion
        show={questionModalShow}
        onHide={toggleQuestionModal}
        product={productInState}
      />
      <ProductImagesSlider
        show={sliderModalShow}
        onHide={toggleSliderModal}
        images={productInState?.images}
      />
      <Footer />
    </>
  );
};

export default ProductDetail;
