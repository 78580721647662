import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { strings } from "../../Translations/strings";
import "../Auth.scss";
import { useNavigate } from "react-router-dom";
import { registerUser } from "./lib";
import { setDataInStorage } from "../../Storage";
import InputError from "../../Common/Error/InputError";
import { setAuthData } from "../authSlice";
import { fetchProfile } from "../../Profile/profileSlice";
import { useDispatch } from "react-redux";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../Analytics/config";
import Toc from "./Toc";

const RegisterForm = ({ showTocHandler, showToc }) => {
  const [registrationInProgress, setRegistrationInProgress] = useState(false);
  const [registrationError, setRegistrationError] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required(strings.validation_field_first_name)
      .min(2, strings.validation_field_first_name)
      .matches(
        /^[a-zA-ZČĆŽŠĐčćžšđ,-]+(?:\s?[a-zA-Z, ČĆŽŠĐčćžšđ-])*$/,
        strings.validation_field_first_name
      ),
    lastName: yup
      .string()
      .required(strings.validation_field_last_name)
      .min(2, strings.validation_field_last_name)
      .matches(
        /^[a-zA-ZČĆŽŠĐčćžšđ,-]+(?:\s?[a-zA-Z, ČĆŽŠĐčćžšđ-])*$/,
        strings.validation_field_last_name
      ),
    type: yup.string().required(strings.validation_field_type).nullable(),
    email: yup
      .string()
      .required(strings.validation_field_required_label)
      .email(strings.validation_field_email),
    password: yup
      .string()
      .min(6, strings.validation_field_password)
      .required(strings.validation_field_password_required),
    consent: yup.bool().oneOf([true], strings.register_toc_error),
    phone: yup
      .string()
      .test(
        "check length if typing, not required field",
        strings.validation_field_telephone,
        (val) => val.toString().length <= 1 || val.toString().length >= 9
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      address: "",
      phone: "",
      email: "",
      password: "",
      type: "",
      consent: false,
    },
  });

  const onSubmit = async (data) => {
    setRegistrationInProgress(true);
    const registerData = Object.fromEntries(
      Object.entries(data).filter(([_, field]) => field !== "")
    );
    const registerSuccessData = await registerUser(registerData).catch(
      (err) => {
        setRegistrationInProgress(false);
        const error =
          err?.response?.status === 409
            ? strings.registration_error_user_exists.replace(
                "{email}",
                registerData.email
              )
            : "";
        setRegistrationError(error);
      }
    );
    if (registerSuccessData) {
      setRegistrationInProgress(false);
      dispatch(setAuthData(registerSuccessData));
      setDataInStorage({ name: "auth", data: registerSuccessData });
      logEvent(analytics, "user_register", { email: registerData.email });
      dispatch(fetchProfile());
      navigate("/");
    }
  };

  const errorStyle = {
    display: "block",
    marginTop: "0px !important",
    textAlign: "center",
  };

  return (
    <>
      {showToc ? (
        <Toc />
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex group-container-checkbox">
            <Form.Group
              className="user-type-checkboxes"
              controlId="formRadioTypePersonal"
            >
              <Form.Check
                isInvalid={errors?.type?.message}
                type="radio"
                aria-required
                label={strings.regular_user_label}
                feedback={strings.validation_field_type}
                feedbackType="invalid"
                {...register("type")}
                value="PHYSICAL_PERSON"
              />
              <Form.Control.Feedback type="invalid">
                {errors?.lastName?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="mb-3 radio-company"
              controlId="formRadioTypeCompany"
            >
              <Form.Check
                type="radio"
                aria-required
                isInvalid={errors?.type?.message}
                label={strings.company_user_label}
                {...register("type")}
                value="LEGAL_ENTITY"
              />
            </Form.Group>
          </div>
          <div className="d-flex justify-content-between group-container">
            <Form.Group
              className="mb-3 required input-half-container"
              controlId="formFirstName"
            >
              <Form.Label aria-required className="control-label">
                {strings.register_form_first_name_label}
              </Form.Label>
              <Form.Control
                type="name"
                onKeyPress={(e) => {
                  if (e.code === "Space" || e.keyCode === 32) {
                    e.preventDefault();
                  }
                }}
                isInvalid={errors?.firstName?.message}
                placeholder={strings.register_form_first_name_label}
                {...register("firstName", { required: true })}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.firstName?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="mb-3 input-half-container"
              controlId="formLastName"
            >
              <Form.Label className="control-label">
                {strings.register_form_last_name_label}
              </Form.Label>
              <Form.Control
                onKeyPress={(e) => {
                  if (e.code === "Space" || e.keyCode === 32) {
                    e.preventDefault();
                  }
                }}
                isInvalid={errors?.lastName?.message}
                type="name"
                placeholder={strings.register_form_last_name_label}
                {...register("lastName", { required: true })}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.lastName?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="d-flex justify-content-between group-container">
            <Form.Group
              className="mb-3 input-half-container"
              controlId="formAddress"
            >
              <Form.Label>{strings.city_register_form_label}</Form.Label>
              <Form.Control
                type="name"
                onKeyPress={(e) => {
                  if (e.code === "Space" || e.keyCode === 32) {
                    e.preventDefault();
                  }
                }}
                placeholder={strings.city_register_form_label}
                {...register("address", { required: false })}
              />
            </Form.Group>
            <Form.Group
              className="mb-3 input-half-container"
              controlId="formPhone"
            >
              <Form.Label>{strings.telephone_register_form_label}</Form.Label>
              <Form.Control
                type="number"
                pattern="^[0-9]*[.,]?[0-9]*$"
                onKeyPress={(e) => {
                  if (e.code === "Space" || e.keyCode === 32 || e.key === "e") {
                    e.preventDefault();
                  }
                }}
                isInvalid={errors?.telephone?.message}
                placeholder={strings.telephone_register_form_label}
                {...register("phone", { required: false })}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.telephone?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <Form.Group className="mb-3 required" controlId="formEmail">
            <Form.Label aria-required className="control-label">
              {strings.email_input_label}
            </Form.Label>
            <Form.Control
              type="email"
              onKeyPress={(e) => {
                if (e.code === "Space" || e.keyCode === 32) {
                  e.preventDefault();
                }
              }}
              isInvalid={errors?.email?.message}
              placeholder={strings.email_input_placeholder}
              {...register("email", { required: true })}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.email?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formPassword">
            <Form.Label className="control-label">
              {strings.password_input_label}
            </Form.Label>
            <Form.Control
              type="password"
              onKeyPress={(e) => {
                if (e.code === "Space" || e.keyCode === 32) {
                  e.preventDefault();
                }
              }}
              isInvalid={errors?.password?.message}
              placeholder={strings.password_input_label}
              {...register("password", { required: true })}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.password?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check>
              <Form.Check.Input
                type={"checkbox"}
                {...register("consent", { required: true })}
                isInvalid={errors?.consent?.message}
              />
              <Form.Check.Label>
                <>
                  {strings.register_toc_i_agree}{" "}
                  <span className="toc" onClick={showTocHandler}>
                    {strings.register_toc_label}
                  </span>
                </>
              </Form.Check.Label>{" "}
              <Form.Control.Feedback type="invalid">
                {errors?.consent?.message}
              </Form.Control.Feedback>
            </Form.Check>
          </Form.Group>
          {registrationError && (
            <InputError message={registrationError} style={errorStyle} />
          )}
          <Button
            variant="primary"
            className="submit-form-register"
            type="submit"
          >
            {registrationInProgress ? (
              <div className="spinner-border text-light" role="status"></div>
            ) : (
              strings.register_form_register_button_label
            )}
          </Button>
        </Form>
      )}
    </>
  );
};

export default RegisterForm;
