import React from "react";
import "./Profile.scss";
import NavProducts from "../Marketplace/NavProducts/NavProducts";
import Footer from "../Common/Footer/Footer";
import ProfileSubmenu from "./ProfileSubmenu/ProfileSubmenu";
import MyProfile from "./MyProfile/MyProfile";

const Profile = () => {
  return (
    <>
      <NavProducts />
      <ProfileSubmenu />
      <MyProfile />
      <Footer />
    </>
  );
};
export default Profile;
