import React from "react";
import "./MarketplaceNav.scss";
import NavProducts from "../NavProducts/NavProducts";
import Submenu from "../Submenu/Submenu";
import { useDispatch, useSelector } from "react-redux";
import { updateCategory } from "../marketPlaceSlice";

const MarketplaceNav = ({ searchParams, setSearchParams }) => {
  const { currentCategory } = useSelector((state) => state.marketPlace);

  const dispatch = useDispatch();
  const setCurrentCategory = (category) => {
    dispatch(updateCategory(category));
  };
  return (
    <div className="marketplace-nav-wrapper">
      <NavProducts
        currentCategory={currentCategory}
        setCurrentCategory={setCurrentCategory}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
      <Submenu setCurrentCategory={setCurrentCategory} />
    </div>
  );
};
export default MarketplaceNav;
