/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./Products.scss";
import renewableEnergy from "./assets/renewable-energy.png";
import { strings } from "../../Translations/strings";
import Product from "./Product.js";
import { useDispatch, useSelector } from "react-redux";
import {
  controlEditCategoryModal,
  controlOpenModal,
} from "../../Calculator/state/calculatorSlice";
import { useLocation } from "react-router-dom";
import ProductsFilters from "./ProductsFilters";
import SortProducts from "./SortProducts";
import ProductsBreadcrumbs from "./ProductsBreadcrumbs";
import UncompletedCategoryBanner from "./UncompletedCategoryBanner";
import CompletedCategoryBanner from "./CompletedCategoryBanner";
import filterIcon from "./assets/filter-icon.svg";
import filterActiveIcon from "./assets/filter-active-icon.svg";
import {
  fetchCategoryProducts,
  fetchProducts,
  loadMoreProducts,
  loadMoreProductsByCategory,
  filterProductsHandler,
  getCategoriesList,
  getVendors,
  searchProductsHandler,
  sortProducts,
  loadMoreProductsByFilter,
  loadMoreProductsInCategoryByFilter,
} from "../marketPlaceSlice";
import InfiniteScroll from "react-infinite-scroll-component";

const matchCategories = (name) => {
  if (name === "solarni-sistemi") {
    return "paneli";
  }
  return name;
};

export const mapPathToCategoryState = ({ currentCategory }) => {
  if (currentCategory === "proizvodi") {
    return "all";
  }
  if (currentCategory === "solarni-sistemi") {
    return "paneli";
  }
  return currentCategory;
};

const Products = ({ searchParams, setSearchParams }) => {
  const [completedCategory, setCompletedCategory] = useState(false);
  const [filterIconActive, setFilterIconActive] = useState(false);
  const location = useLocation();
  const pathNames = location?.pathname?.split("/");
  const currentCategory =
    pathNames[pathNames.length - 1] === "search"
      ? "search"
      : pathNames[pathNames.length - 1];

  const { categories, modalCalculatorVisible } = useSelector(
    (state) => state.calculator
  );
  const [searchOption, setSearchOptions] = useState({});
  const {
    currentSubcategory,
    marketPlace,
    nextToken,
    vendorList,
    categoryList,
    newProducts,
    newFilteredCategoryProducts,
    filteredProducts,
  } = useSelector((state) => state.marketPlace);
  const [moreProducts, setMoreProducts] = useState([1]);

  const products =
    marketPlace?.[mapPathToCategoryState({ currentCategory })]?.products;

  const [editCategory, setEditCategory] = useState(null);
  const dispatch = useDispatch();

  const openEditCategory = (category) => {
    dispatch(controlEditCategoryModal(true));
    if (editCategory) {
      setEditCategory(!editCategory);
    }
    setEditCategory(category);
  };

  const openModal = () => {
    dispatch(controlOpenModal(true));
  };

  const [openFiltersMob, setOpenFiltersMob] = useState(false);

  const handleOpenFilters = () => {
    setOpenFiltersMob(!openFiltersMob);
  };

  const productsComponent = products?.map((product) => (
    <Product
      product={product}
      key={product.id}
      completedCategory={completedCategory}
    />
  ));

  const filterProducts = async ({ event, id, name }) => {
    if (event.target.checked) {
      searchParams.set(name, id);
      setSearchOptions({ ...searchOption, [name]: id });
      setSearchParams(searchParams);
      setFilterIconActive(true);
      setMoreProducts([1]);
    } else {
      searchParams.delete(name);
      delete searchOption[name];
      setSearchOptions(searchOption);
      setSearchParams(searchParams);
      setMoreProducts([1]);
    }
  };

  const sortProductsHandler = (sortType) => {
    const category = mapPathToCategoryState({ currentCategory });
    dispatch(sortProducts({ id: category, order: sortType }));
    if (sortType === "default") {
      searchParams.delete("price");
      delete searchOption["price"];
      setSearchOptions(searchOption);
      setSearchParams(searchParams);
    } else {
      searchParams.set("price", sortType);
      setSearchOptions({ ...searchOption, sort: sortType });
      setSearchParams(searchParams);
    }
  };

  const refetchProducts = () => {
    const params = [];
    for (let entry of searchParams.entries()) {
      params.push(entry);
    }
    if (params.length === 0) {
      setSearchOptions({});
      const category = mapPathToCategoryState({ currentCategory });
      dispatch(sortProducts({ id: category, order: "default" }));
      const categoryToFetch = mapPathToCategoryState({ currentCategory });
      if (categoryToFetch === "all") {
        dispatch(fetchProducts({ nextToken }));
      } else {
        dispatch(
          fetchCategoryProducts({ nextToken, currentCategory: categoryToFetch })
        );
      }
    }
  };
  useEffect(() => {
    dispatch(getVendors());
    dispatch(getCategoriesList());
    refetchProducts();
    setMoreProducts([1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory]);

  useEffect(() => {
    setCompletedCategory(
      categories?.[matchCategories(currentCategory)]?.completed
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalCalculatorVisible, location]);

  const [url, setUrl] = useState("");

  useEffect(() => {
    setMoreProducts([1]);
    const params = {};
    const option = {};
    const params1 = [];
    for (let entry of searchParams.entries()) {
      params1.push(entry);
    }
    const categoryToFetch = mapPathToCategoryState({ currentCategory });
    if (Object.keys(searchOption).length === 0) {
      dispatch(
        fetchCategoryProducts({ nextToken, currentCategory: categoryToFetch })
      );
    }
    let url = "";
    if (params1.length > 0) {
      if (currentCategory !== "proizvodi" && currentCategory !== "search") {
        params["categoryId"] = categoryToFetch;
      }
      searchParams.forEach((key, value) => {
        option[value] = key;
        if (value.startsWith("vendor")) {
          url += `vendorId=${key}&`;
        }
        if (value.startsWith("subcategory")) {
          url += `subcategoryId=${key}&`;
        }
        if (value.startsWith("term")) {
          url += `term=${key}&`;
        }
        if (value.startsWith("price")) {
          sortProductsHandler(key);
          url += `price=${key}&`;
        }
        if (nextToken) {
          url += `nextToken=${nextToken}&`;
        }
        setUrl(url);
      });
      if (currentCategory === "search") {
        searchOption.hasOwnProperty("sort")
          ? setSearchOptions({ ...searchOption, ...option })
          : setSearchOptions(option);
        dispatch(searchProductsHandler({ ...params, url }));
      } else {
        setSearchOptions({ ...searchOption, ...option });
        dispatch(filterProductsHandler({ ...params, url }));
        setMoreProducts([1]);
      }
    } else {
      if (categoryToFetch === "all") {
        dispatch(fetchProducts({ nextToken }));
        setFilterIconActive(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const loadMore = () => {
    if (currentCategory === "proizvodi") {
      if (products.length > 28) {
        const params = {};
        const option = {};
        const params1 = [];
        for (let entry of searchParams.entries()) {
          params1.push(entry);
        }
        if (params1?.length > 0) {
          setSearchOptions({ ...searchOption, ...option });
          dispatch(
            loadMoreProductsByFilter({
              ...params,
              url,
              token: marketPlace.all.nextToken,
            })
          );
        } else {
          setMoreProducts(newProducts);
          dispatch(loadMoreProducts(`${marketPlace.all.nextToken} `));
          setMoreProducts([1]);
        }
      }
    } else {
      const params = {};
      const option = {};
      const params1 = [];
      for (let entry of searchParams.entries()) {
        params1.push(entry);
      }
      if (products.length > 28 && params1?.length < 1) {
        if (currentCategory !== "solarni-sistemi") {
          dispatch(
            loadMoreProductsByCategory({
              nextToken: marketPlace[currentCategory].nextToken,
              currentCategory: currentCategory,
            })
          );
        } else {
          dispatch(
            loadMoreProductsByCategory({
              nextToken: marketPlace["paneli"].nextToken,
              currentCategory: "paneli",
            })
          );
        }
      } else if (products.length > 28 && params1?.length > 0) {
        setMoreProducts(
          products.length === 30
            ? [1]
            : filteredProducts?.length > newFilteredCategoryProducts.length
            ? filteredProducts
            : newFilteredCategoryProducts
        );
        if (currentCategory !== "solarni-sistemi") {
          setSearchOptions({ ...searchOption, ...option });
          dispatch(
            loadMoreProductsInCategoryByFilter({
              ...params,
              url,
              nextToken: marketPlace[currentCategory].nextToken,
              currentCategory: currentCategory,
            })
          );
          setMoreProducts(
            filteredProducts.length === 30
              ? [1]
              : filteredProducts.length > newFilteredCategoryProducts.length
              ? filteredProducts
              : newFilteredCategoryProducts
          );
        } else {
          setSearchOptions({ ...searchOption, ...option });
          dispatch(
            loadMoreProductsInCategoryByFilter({
              ...params,
              url,
              nextToken: marketPlace["paneli"].nextToken,
              currentCategory: "paneli",
            })
          );
          setMoreProducts(newFilteredCategoryProducts);
        }
      }
    }
  };

  const categoryFilterVisible =
    currentCategory === "proizvodi" || currentCategory === "grijanje";
  return (
    <>
      {products !== undefined && (
        <>
          <section className="section-banner">
            <div className="c-container-products">
              <div className="category-breadcrumbs">
                <ProductsBreadcrumbs currentCategory={currentCategory} />
              </div>
              <div className="category-banner d-flex">
                <img
                  src={renewableEnergy}
                  alt="renewable"
                  className="d-none d-md-block"
                ></img>

                {!completedCategory ? (
                  <>
                    <UncompletedCategoryBanner
                      currentCategory={currentCategory}
                      currentSubcategory={currentSubcategory}
                      openModal={openModal}
                    />
                  </>
                ) : (
                  <CompletedCategoryBanner
                    currentCategory={currentCategory}
                    currentSubcategory={currentSubcategory}
                    openEditCategory={openEditCategory}
                  />
                )}
              </div>
            </div>
          </section>
          <section className="section-products d-flex c-container">
            <div className="filter-container d-none d-lg-flex flex-column align-items-start">
              <ProductsFilters
                filterProducts={filterProducts}
                setOpenFiltersMob={setOpenFiltersMob}
                categoryFilterVisible={categoryFilterVisible}
                setParams={setSearchParams}
                vendorList={vendorList}
                categoriesList={categoryList}
                searchOption={searchOption}
              />
            </div>

            <div className="products-container d-flex flex-column col-sm-9">
              <div className="d-flex justify-content-between align-items-start bordered-info">
                <img
                  src={filterIconActive ? filterActiveIcon : filterIcon}
                  alt="filter"
                  className="d-block d-lg-none filter-mob-icon"
                  onClick={handleOpenFilters}
                ></img>
                <div className="d-flex justify-content-between sort-filter-mobile num-of-products">
                  <div className="d-none d-sm-flex align-items-center">
                    <span>
                      <strong>{products.length}</strong>
                      {strings.products_sort_label}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center dropdown-mobile">
                  {strings.sort_dropdown_label_one}
                  <SortProducts sortProducts={sortProductsHandler} />
                </div>
              </div>
              <div
                className={
                  openFiltersMob
                    ? "display-filters-mob filter-container d-flex d-lg-none flex-column align-items-start"
                    : "hide-filters-mob"
                }
              >
                <ProductsFilters
                  filterProducts={filterProducts}
                  setOpenFiltersMob={setOpenFiltersMob}
                  categoryFilterVisible={categoryFilterVisible}
                  vendorList={vendorList}
                  setParams={setSearchParams}
                  categoriesList={categoryList}
                  searchOption={searchOption}
                />
              </div>
              <div className="d-flex d-sm-none align-items-center products-count">
                <span>
                  <strong>{products.length}</strong>
                  {strings.products_sort_label}
                </span>
              </div>
              <div>
                <InfiniteScroll
                  dataLength={products.length}
                  next={loadMore}
                  hasMore={moreProducts.length > 0 ? true : false}
                  loader={
                    moreProducts.length < 29 ? (
                      <p>Nema više proizvoda.</p>
                    ) : (
                      <p>Loading...</p>
                    )
                  }
                  endMessage={<p>Nema više proizvoda.</p>}
                >
                  <div className="d-flex flex-wrap products-content">
                    {productsComponent}
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Products;
