import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateCategory } from "../../Marketplace/marketPlaceSlice";
import { strings } from "../../Translations/strings";
import { ReactComponent as CheckMarkIcon } from "../assets/Circle_Check.svg";
import { resetAnswersOfOneCategory } from "../lib/lib";
import {
  controlOpenModal,
  controlResultModal,
  restartCategory,
} from "../state/calculatorSlice";
import "./Result.scss";

const Result = ({ category, icon }) => {
  const { title, slug, id } = category;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const categoryFormmater = () => {
    if (title === "Solarni paneli") {
      return "solarni-sistemi";
    }
    if (title === "Grijanje") {
      return "grijanje";
    }
    if (title === "Prozori") {
      return "prozori";
    }
    return "izolacija";
  };

  const restartCategoryHandler = (cat) => {
    dispatch(restartCategory(cat));
  };

  const navigateToProducts = () => {
    dispatch(controlOpenModal(false));
    dispatch(updateCategory(categoryFormmater()));
    setTimeout(() => {
      navigate("/proizvodi/" + categoryFormmater());
    }, 100);
  };

  const resultsOpened = () => {
    dispatch(controlResultModal(true));
  };

  return (
    <div className="result-container" onLoad={resultsOpened}>
      <div className="align-items-center justify-content-center result-container-top">
        <div className="top-container">
          <img src={icon} className="result-icon" alt="result_icon"></img>
          <div className="icon-text-container">
            <div className="icon-text-right">
              <CheckMarkIcon className="checkmark-icon" />
              <p className="text-right-title">
                {strings.results_p_part_one}
                {category?.title
                  .toLowerCase()
                  .replace("solarni", "solarne")
                  .replace("paneli", "panele")
                  .replace("izolacija", "izolaciju")
                  .replace("prozori", "prozore")}
                {strings.results_p_part_two}
              </p>
            </div>
            <p className="text-right-subtitle">
              {strings.results_p_part_three} {category?.title}
              {strings.results_p_part_four}
            </p>
          </div>
        </div>
      </div>
      <div className="buttons-container">
        <p className="reset-button" onClick={async() => {
            const resetSuccess = await resetAnswersOfOneCategory({categoryId: id}).catch(err =>{
              // This is is for now here because backend return 404 when reseting answers
              restartCategoryHandler(slug)
             //  dispatch(getLoggedUserQuestions());
            }); 
            if(resetSuccess) {
              restartCategoryHandler(slug)
              //dispatch(getLoggedUserQuestions());
            }
          }}>
          {strings.results_restart}
        </p>
        <button
          className="home-button btn-green results-btn"
          onClick={navigateToProducts}
        >
          {strings.results_go_to_products}
        </button>
      </div>
    </div>
  );
};

export default Result;
