import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { LoginModal } from "./LoginModal";
import { RegisterModal } from "./RegisterModal";
import { ReactComponent as BackIcon } from "../../../Assets/arrow-left-blue.svg";
import { strings } from "../../../Translations/strings";
import { Button } from "react-bootstrap";

export const RegisterToChat = (props) => {
  const [isLoginActive, setIsLoginActive] = useState(false);
  const [showToc, setShowToc] = useState(false);

  const goToRegister = () => {
    setIsLoginActive(false);
  };

  const goToLogin = () => setIsLoginActive(true);

  const showTocHandler = () => setShowToc(!showToc);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="savings-header">
        <Modal.Title id="contained-modal-title-vcenter">
          <h4 className="product-modal-title">
            {showToc ? (
              <div className="back-container">
                <BackIcon
                  width={30}
                  className="back-toc"
                  onClick={showTocHandler}
                />
                <span>{strings.toc_explanation_title}</span>
              </div>
            ) : isLoginActive ? (
              "Prijavi se"
            ) : (
              "Registracija"
            )}
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 m-0 pt-3">
        {isLoginActive ? (
          <LoginModal
            goToRegister={goToRegister}
            onHide={props.onHide}
            chatWithVendor={props.chatWithVendor}
            openViberChatWithPartner={props.openViberChatWithPartner}
            openViberChatWithVendor={props.openViberChatWithVendor}
          />
        ) : (
          <RegisterModal
            goToLogin={goToLogin}
            onHide={props.onHide}
            showTocHandler={showTocHandler}
            showToc={showToc}
            chatWithVendor={props.chatWithVendor}
            openViberChatWithPartner={props.openViberChatWithPartner}
            openViberChatWithVendor={props.openViberChatWithVendor}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        {showToc && (
          <div className="toc-back-container">
            <Button onClick={showTocHandler} className="back-button">
              {strings.toc_back_to_registration}
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};
