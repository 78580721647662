import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1, { replace: true });
  return (
    <div className="nav-logo">
      <img
        onClick={goBack}
        className="back-icon"
        src={require("../../Assets/arrow-left.svg").default}
        alt="back-icon"
      />
      <Link to="/">
        <img
          className="logo"
          src={require("../../Assets/logo.svg").default}
          alt="logo"
        />
      </Link>
      <div className="empty-container"></div>
    </div>
  );
};

export default Header;
