import React from "react";
import "./Marketplace.scss";
import Products from "./Products/Products";
import Footer from "../Common/Footer/Footer";
import MarketplaceNav from "./MarketplaceNav.js/MarketplaceNav";
import { useSearchParams } from "react-router-dom";

const Marketplace = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <MarketplaceNav searchParams={searchParams} setSearchParams={setSearchParams}/>
      <Products searchParams={searchParams} setSearchParams={setSearchParams}/>
      <Footer />
    </>
  );
};
export default Marketplace;
