import axios from "axios";

export const httpClient = axios.create({
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
});

export const setUpInterceptor = (store) => {
  const handleError = async (error) => {
    return Promise.reject(error);
  };

  httpClient.interceptors.request.use(
    async (config) => {
      config.headers.Authorization = store.getState().auth?.authData?.token ? `Bearer ${store.getState().auth?.authData?.token}` : null
      return config
    }
  );

  httpClient.interceptors.response.use((response) => response, handleError);
};
