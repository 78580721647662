import React from "react";
import QuestionWrapperComponent from "../QuestionWrapperComponent/QuestionWraperComponent";
import "./InputComponent.scss";

const InputText = ({
  currentIndex,
  title,
  category,
  updateIndex,
  answer,
  setAnswer,
  placeHolder,
  onClose,
}) => {
  return (
    <QuestionWrapperComponent
      title={title}
      category={category}
      buttonPrevious="Zatvori"
      buttonNext="Dalje"
      updateIndex={updateIndex}
      currentIndex={currentIndex}
      hasValue={answer}
      onClose={onClose}
    >
      <div className="input-text-component">
        <input
          type="text"
          placeholder={placeHolder}
          onChange={(event) =>
            setAnswer(event.target.validity.valid ? event.target.value?.replace(/\D/g, '').replace(/^0+/, '') : answer)
          }
          value={answer || ""}
          className="input_text"
          pattern="\d+((\.|,)\d+)?"
        />
      </div>
    </QuestionWrapperComponent>
  );
};

export default InputText;
