import React, { useEffect, useState } from "react";
import "./Product.scss";
import { Link } from "react-router-dom";
import handbag from "./assets/handbag-icon.svg";
import handbagMob from "./assets/Handbag.svg";
import { strings } from "../../Translations/strings";
import { useDispatch, useSelector } from "react-redux";
import { ProgressBar } from "react-bootstrap";
import { addToWishlist } from "../../Wishlist/wishlistSlice";
import { toast } from "react-toastify";
import { httpClient } from "../../Http/client";
import { BASE_URL } from "../../Environment/config";

const getProduct = async (id) => {
  const { data } = await httpClient.get(`${BASE_URL}/products/${id}`);
  return data;
};

const Product = ({ product, completedCategory }) => {
  const { name, category, images, price, id } = product;
  const { profile } = useSelector((state) => state.profile);
  const { wishlistItems } = useSelector((state) => state.wishlist);
  const [productSaving, setProductSaving] = useState();
  const dispatch = useDispatch();
  const notifyUser = (product) => {
    const itemIndex = wishlistItems.findIndex((item) => item.id === product.id);
    itemIndex >= 0
      ? toast.info(`${product.name} se već nalazi na Vašoj listi želja`, {
          position: "bottom-left",
        })
      : toast.success(`${product.name} je dodat na Vašu listu želja`, {
          position: "bottom-left",
        });
  };
  const handlePrepareToAddToWishlist = () => {
    const productToAdd = productSaving
      ? { ...product, productSaving }
      : product;
    dispatch(addToWishlist(productToAdd));
    notifyUser(product);
  };

  const path =
    category?.title === "Solarni Sistemi"
      ? "solarni-sistemi"
      : category?.title.toLowerCase();

  useEffect(() => {
    const getProductDetail = async () => {
      const product = await getProduct(id || path.split("/").slice(-1)).catch(
        (err) => console.log(err)
      );
      setProductSaving(product?.calculation);
    };
    getProductDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCategory]);

  return (
    <Link
      className="single-product d-flex"
      to={"/proizvodi/" + path + "/proizvod/" + id}
      state={product}
    >
      <img src={images?.[0]?.url} alt="product"></img>
      <div className="product-name product-title-box">{name.toUpperCase()}</div>
      <div className="product-category">{category.title}</div>
      <div className="product-vendor">
        {strings.product_vendor_label}
        {product?.manufacturer}
      </div>
      {profile?.data?.firstName && productSaving?.moneySavingsPercentage ? (
        <div>
          <ProgressBar
            now={
              productSaving?.moneySavingsPercentage
                ? productSaving?.moneySavingsPercentage
                : 0
            }
            className="product-progress-bar products-progress"
          />
          <p className="product-savings products-savings">
            {strings.products_savings_info}{" "}
            <b>
              {productSaving?.moneySavingsPercentage
                ? productSaving?.moneySavingsPercentage
                : 0}
              %
            </b>
          </p>
        </div>
      ) : (
        ""
      )}
      <div className="product-price">
        {(price * 1).toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) +
          " " +
          strings.KM_label}
      </div>
      <div className="hide-icon" onClick={handlePrepareToAddToWishlist}>
        <img
          src={handbag}
          alt="wishlist"
          className="product-handbag-icon d-none d-md-block"
        ></img>
      </div>
      <div
        className="d-flex d-md-none justify-content-end"
        onClick={handlePrepareToAddToWishlist}
      >
        <img
          src={handbagMob}
          alt="add to wishlist"
          className="handbag-icon-mob"
        ></img>
      </div>
    </Link>
  );
};

export default Product;
