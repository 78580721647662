import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../Environment/config";
import { httpClient } from "../../Http/client";
import { saveAnswers } from "../lib/lib";

const initialState = {
  customerType: "",
  categories: {},
  currentIndex: 0,
  initialQuestions: [],
  hasCompletedCategory: false,
  calculatorInProgress: false,
  currentCategory: null,
  categoryInProgress: null,
  modalCalculatorVisible: false,
  introModalVisible: false,
  questions: [],
  displayResult: false,
  isEditingOneField: false,
  groupOverview: false,
  completedOneCategory: false,
  shouldRefetchAnswers: false,
};

export const mapCategoryIdToCategoryName = ({ id }) => {
  if (id === "e2ef99db-b889-4d6c-97e6-65d3785f3597") {
    return "grijanje";
  }
  if (id === "c8203992-0dde-414b-b81a-cdd319f85e7d") {
    return "paneli";
  }
  if (id === "e3ec79d6-55f0-4860-94dc-efa902b27d53") {
    return "prozori";
  }
  if (id === "5f1da897-9c86-4736-b31e-760d6ea2608e") {
    return "izolacija";
  }
}; 
export const getInitialQuestions = createAsyncThunk(
  "calculator/getQuestions",
  () => {
    return axios
      .get(`${BASE_URL}/answers`)
      .then((response) => response.data)
      .catch((error) => error);
  }
);

export const getLoggedUserQuestions = createAsyncThunk(
  "calculator/getQuestionsLoggedUser",
  () => {
    return httpClient
      .get(`${BASE_URL}/answers`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => error);
  }
);
export const calculatorSlice = createSlice({
  name: "calculator",
  initialState,
  extraReducers: {
    [getInitialQuestions.fulfilled]: (state, action) => {
      if(Object.keys(state.categories).length > 0) {
        state.questions = action.payload.initialQuestions;
        state.initialQuestions = action.payload.initialQuestions;
        return;
      }
      const grijanjeQuestions = action.payload.categoryQuestions.filter(
        (question) =>
          question.categoryId === "e2ef99db-b889-4d6c-97e6-65d3785f3597"
      );
      const paneliQuestions = action.payload.categoryQuestions.filter(
        (question) =>
          question.categoryId === "c8203992-0dde-414b-b81a-cdd319f85e7d"
      );
      const prozoriQuestions = action.payload.categoryQuestions.filter(
        (question) =>
          question.categoryId === "e3ec79d6-55f0-4860-94dc-efa902b27d53"
      );
      const izolacijaQuestions = action.payload.categoryQuestions.filter(
        (question) =>
          question.categoryId === "5f1da897-9c86-4736-b31e-760d6ea2608e"
      );
      const grijanjeCompleted = grijanjeQuestions.every(
        (question) => question.answer
      );
      const paneliCompleted = paneliQuestions.every(
        (question) => question.answer
      );
      const prozoriCompleted = prozoriQuestions.every(
        (question) => question.answer
      );

      const izolacijaCompleted = izolacijaQuestions.every(
        (question) => question.answer
      );
      
      const grijanje = state.categories?.grijanje
      const izolacija = state.categories?.izolacija
      const paneli = state.categories?.paneli
      const prozori = state.categories?.prozori
      const categories = {
        grijanje: grijanje?.completed  ? grijanje :  {
          title: "Grijanje",
          completed: grijanjeCompleted,
          icon: "https://enova-bucket.s3.eu-west-2.amazonaws.com/icons/categories/heating.svg",
          slug: "grijanje",
          id: "e2ef99db-b889-4d6c-97e6-65d3785f3597",
          questions: grijanjeQuestions.map((question, index) => {
            return {
              ...question,
              step: `(${index + 1}/${grijanjeQuestions.length})`,
            };
          }),
        },
        paneli:  paneli?.completed ? paneli : {
          title: "Solarni paneli",
          completed: paneliCompleted,
          icon: "https://enova-bucket.s3.eu-west-2.amazonaws.com/icons/categories/solar.svg",
          slug: "paneli",
          id: "c8203992-0dde-414b-b81a-cdd319f85e7d",
          questions: paneliQuestions.map((question, index) => {
            return {
              ...question,
              step: `(${index + 1}/${paneliQuestions.length})`,
            };
          }),
        },
        prozori: prozori?.completed ? prozori :{
          title: "Prozori",
          completed: prozoriCompleted,
          icon: "https://enova-bucket.s3.eu-west-2.amazonaws.com/icons/categories/windows.svg",
          slug: "prozori",
          id: "e3ec79d6-55f0-4860-94dc-efa902b27d53",
          questions: prozoriQuestions.map((question, index) => {
            return {
              ...question,
              step: `(${index + 1}/${prozoriQuestions.length})`,
            };
          }),
        },
        izolacija: izolacija?.completed ? izolacija :{
          title: "Izolacija",
          completed: izolacijaCompleted,
          icon: "https://enova-bucket.s3.eu-west-2.amazonaws.com/icons/categories/isolation.svg",
          slug: "izolacija",
          id: "5f1da897-9c86-4736-b31e-760d6ea2608e",
          questions: izolacijaQuestions.map((question, index) => {
            return {
              ...question,
              step: `(${index + 1}/${izolacijaQuestions.length})`,
            };
          }),
        },
      };
      state.questions = action.payload.initialQuestions;
      state.initialQuestions = action.payload.initialQuestions;
      state.categories = categories;
    },
    [getLoggedUserQuestions.fulfilled]: (state, action) => {
      const grijanjeQuestions = action.payload.categoryQuestions.filter(
        (question) =>
          question.categoryId === "e2ef99db-b889-4d6c-97e6-65d3785f3597"
      );
      const paneliQuestions = action.payload.categoryQuestions.filter(
        (question) =>
          question.categoryId === "c8203992-0dde-414b-b81a-cdd319f85e7d"
      );
      const prozoriQuestions = action.payload.categoryQuestions.filter(
        (question) =>
          question.categoryId === "e3ec79d6-55f0-4860-94dc-efa902b27d53"
      );
      const izolacijaQuestions = action.payload.categoryQuestions.filter(
        (question) =>
          question.categoryId === "5f1da897-9c86-4736-b31e-760d6ea2608e"
      );
      const grijanjeCompleted = grijanjeQuestions.every(
        (question) => question.answer
      );
      const paneliCompleted = paneliQuestions.every(
        (question) => question.answer
      );
      const prozoriCompleted = prozoriQuestions.every(
        (question) => question.answer
      );
      const izolacijaCompleted = izolacijaQuestions.every(
        (question) => question.answer
      );
      const hasCompletedCategory =
        izolacijaCompleted ||
        grijanjeCompleted ||
        prozoriCompleted ||
        paneliCompleted;
      const categories = {
        grijanje: {
          title: "Grijanje",
          completed: grijanjeCompleted,
          icon: "https://enova-bucket.s3.eu-west-2.amazonaws.com/icons/categories/heating.svg",
          slug: "grijanje",
          id: "e2ef99db-b889-4d6c-97e6-65d3785f3597",
          questions: grijanjeQuestions.map((question, index) => {
            return {
              ...question,
              step: `(${index + 1}/${grijanjeQuestions.length})`,
            };
          }),
        },
        paneli: {
          title: "Solarni paneli",
          completed: paneliCompleted,
          icon: "https://enova-bucket.s3.eu-west-2.amazonaws.com/icons/categories/solar.svg",
          slug: "paneli",
          id: "c8203992-0dde-414b-b81a-cdd319f85e7d",
          questions: paneliQuestions.map((question, index) => {
            return {
              ...question,
              step: `(${index + 1}/${paneliQuestions.length})`,
            };
          }),
        },
        prozori: {
          title: "Prozori",
          completed: prozoriCompleted,
          icon: "https://enova-bucket.s3.eu-west-2.amazonaws.com/icons/categories/windows.svg",
          slug: "prozori",
          id: "e3ec79d6-55f0-4860-94dc-efa902b27d53",
          questions: prozoriQuestions.map((question, index) => {
            return {
              ...question,
              step: `(${index + 1}/${prozoriQuestions.length})`,
            };
          }),
        },
        izolacija: {
          title: "Izolacija",
          completed: izolacijaCompleted,
          icon: "https://enova-bucket.s3.eu-west-2.amazonaws.com/icons/categories/isolation.svg",
          slug: "izolacija",
          id: "5f1da897-9c86-4736-b31e-760d6ea2608e",
          questions: izolacijaQuestions.map((question, index) => {
            return {
              ...question,
              step: `(${index + 1}/${izolacijaQuestions.length})`,
            };
          }),
        },
      };
      const getCategoryInProgress = () => {
        if (grijanjeCompleted) {
          return categories.grijanje;
        }
        if (prozoriCompleted) {
          return categories.prozori;
        }
        if (paneliCompleted) {
          return categories.paneli;
        }
        if (izolacijaCompleted) {
          return categories.izolacija;
        }
        return null;
      };
        state.initialQuestions = action.payload.initialQuestions;
        state.categories = categories;
        state.hasCompletedCategory = hasCompletedCategory;
        state.categoryInProgress = getCategoryInProgress();
        state.shouldRefetchAnswers = false;
        state.questions = hasCompletedCategory ? [] : state.questions
    },
  },
  reducers: {
    updateQuestions: (state, action) => {
      const { answer, slug: originalSlug } = action.payload;
      const convertTitleToSlug = () => {
        if (originalSlug === "Grijanje") {
          return "grijanje";
        }
        if (originalSlug === "Solarni sistemi") {
          return "paneli";
        }
        if (originalSlug === "Prozori") {
          return "prozori";
        }
        if (originalSlug === "Izolacija") {
          return "izolacija";
        }
        return originalSlug;
      };
      const slug = convertTitleToSlug();
      if (
        state.currentIndex === state.questions.length - 1 &&
        !state.categoryInProgress
      ) {
        state.categoryInProgress = slug;
        const questions = state.categories[slug]?.questions;
        state.currentCategory = state.categories[slug];
        state.questions = state.questions.concat(questions);
      }
      if (
        state.currentIndex === 1 &&
        state.currentIndex !== state.questions.length - 1 &&
        state.categoryInProgress &&
        !state.hasCompletedCategory &&
        !state.completedOneCategory
      ) {
        state.categoryInProgress = slug;
        const questions1 = state.categories[slug]?.questions;
        state.currentCategory = state.categories[slug];
        state.questions.splice(2, state.questions.length - 1);
        state.questions = state.questions.concat(questions1);
      }

      const newQuestions = state?.questions.map((question, index) => {
        if (index === state.currentIndex) {
          return {
            ...question,
            answer,
          };
        }
        return question;
      });
      state.questions = newQuestions;
    },
    updateCurrentIndex: (state, action) => {
      const { index, authenticated } = action.payload;
      if (
        state.currentIndex === state.questions.length - 1 &&
        action.payload.index > state.currentIndex &&
        state.categoryInProgress &&
        state.questions.length > 2
      ) {
        if (authenticated) {
          state.displayResult = true;
          const { id } = state.currentCategory;
          const questions = [...state.questions];
          const answers = questions.map((question) => {
            return { questionId: question.id, value: question.answer };
          });
          const categoryToUpdate = state.categories[mapCategoryIdToCategoryName({ id })]
          const isFirstTimeCalculator = answers.length > categoryToUpdate?.questions?.length
          const newAnswers = isFirstTimeCalculator ? answers?.slice(2, answers.length) : answers

          saveAnswers({ answers: newAnswers, categoryId: id, refetchAnswers: true });
        } 
        else {
          state.displayResult = true;
          const questions = [...state.questions];
          const { id } = state.currentCategory;
          const answers = questions.map((question) => {
            return { ...question, answer: question.answer };
          });
          const categoryToUpdate = state.categories[mapCategoryIdToCategoryName({ id })]
          const isFirstTimeCalculator = answers.length > categoryToUpdate?.questions?.length
          const newAnswers = isFirstTimeCalculator ? answers?.slice(2, answers.length) : answers
          if(isFirstTimeCalculator) {
            state.customerType = answers[0].answer
          }
          state.categories[mapCategoryIdToCategoryName({ id })] = {
          ...categoryToUpdate,
          completed: true,
          questions: newAnswers.map((question, index) => {
            return {
              ...question,
              step: `(${index + 1}/${newAnswers.length})`,
            };
          }),
          }
        }
      } else if (state.currentIndex === 2 && action.payload.index === 1) {
        state.currentIndex = index;
      } else {
        state.currentIndex = index;
      }
    },
    controlIntroModal: (state, action) => {
      state.introModalVisible = action.payload;
    },
    controlEditCategoryModal: (state, action) => {
      state.modalCalculatorVisible = action.payload;
      state.currentCategory = state.categories[action.payload];
    },
    controlGroupOverview: (state, action) => {
      state.groupOverview = action.payload;
    },
    controlResultModal: (state, action) => {
      state.displayResult = action.payload;
    },
    controlOpenModal: (state, action) => {
      const hasCompletedCategory =
        state.questions?.every((question) => question.answer) ||
        state.hasCompletedCategory;

      if (!action.payload) {
        if (
          (state.questions.length === 0 ||
            state.questions.length - 1 > state.currentIndex) &&
          state.hasCompletedCategory
        ) {
          state.modalCalculatorVisible = action.payload;
          state.questions = [];
          state.displayResult = false;
          state.isEditingOneField = false;
          state.currentCategory = null
          return;
        }

        state.modalCalculatorVisible = action.payload;
        if (hasCompletedCategory && state.questions.length > 2) {
          state.currentIndex = 0;
          state.hasCompletedCategory = hasCompletedCategory;
          state.categoryInProgress = null;
          state.questions = [];
          state.displayResult = false;
          state.isEditingOneField = false;
        } else {
          state.questions = state.initialQuestions;
          state.currentIndex = 0;
          state.currentCategory = null;
          state.displayResult = false;
          state.categoryInProgress = null;
          state.isEditingOneField = false;
        }
      }
      state.modalCalculatorVisible = action.payload;
    },
    resetAll: (state) => {
        state.currentIndex = 0;
        state.questions = state.initialQuestions;
        state.hasCompletedCategory= false;
        state.calculatorInProgress =false;
        state.currentCategory=  null;
        state.categoryInProgress = null;
        state.introModalVisible = false;
        state.displayResult = false;
        state.isEditingOneField = false;
        state.groupOverview = false;
        state.completedOneCategory = false;
        state.shouldRefetchAnswers = false;
        state.modalCalculatorVisible= true;
    },
    startCategory: (state, action) => {
      state.categoryInProgress = action.payload;
      state.currentIndex = 0;
      state.currentCategory = state.categories[action.payload];
      state.questions = state.categories[action.payload].questions;
    },
    restartCategory: (state, action) => {
      state.groupOverview = false;
      const categoriesAsArray = Object.keys(state.categories).map((key) => {
        return state.categories[key];
      });

      const hasCompletedMoreCategories = categoriesAsArray.filter(
        (category) => category.completed === true
      );

      if (hasCompletedMoreCategories.length <= 1) {
        state.completedOneCategory = false;
        state.currentIndex = 0;
        state.questions = state.initialQuestions;
        state.categoryInProgress = null;
        state.categoryInProgress = null;
        state.hasCompletedCategory = false;
        state.displayResult = false;
        state.isEditingOneField = false;
        state.groupOverview = false;
        state.currentCategory = null;
        state.categories[action.payload].questions = state.categories[
          action.payload
        ].questions.map((question) => {
          return {...question, answer: null}
        });
        return;
      }
      state.categoryInProgress = action.payload;
      state.currentIndex = 0;
      state.currentCategory = state.categories[action.payload];
      state.displayResult = false;
      state.isEditingOneField = false;
      state.groupOverview = false;
      state.categories[action.payload].questions = state.categories[
        action.payload
      ].questions.map((question) => {
        return {...question, answer: null}
      });
      state.questions = state.categories[action.payload].questions;
      state.categories[action.payload].completed = false;
    },
    editOneAnswer: (state, action) => {
      state.isEditingOneField = action.payload;
    },
    updateOnFieldChange: (state, action) => {
      const { answer, slug, title } = action.payload;
      const category = state.categories[slug];
      const newQuestions = category.questions.map((question, index) => {
        if (question.title === title) {
          return { ...question, answer };
        }
        return question;
      });
      state.categories[slug].questions = newQuestions;
      state.isEditingOneField = false;
    },
  },
});

export const {
  updateCurrentIndex,
  updateQuestions,
  controlOpenModal,
  controlIntroModal,
  controlEditCategoryModal,
  controlOverviewModal,
  controlResultModal,
  resetAll,
  startCategory,
  restartCategory,
  editOneAnswer,
  updateOnFieldChange,
  controlGroupOverview,
} = calculatorSlice.actions;

export default calculatorSlice.reducer;
