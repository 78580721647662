/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./NavProducts.scss";
import logoMobile from "../MainMenu/assets/Logo-white.svg";
import MainMenu from "../MainMenu/MainMenu";
import MobileMenu from "../../Common/MobileMenu/MobileMenu";

const NavProducts = ({ searchParams, setSearchParams }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const mobileMenuClass = mobileMenuOpen ? "mobile-menu active" : "mobile-menu";

  const controlMobileMenuVisibility = () => setMobileMenuOpen(!mobileMenuOpen);
  return (
    <section className="section-marketplace">
      <div className="c-container">
        <MainMenu
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          mobileMenuOpen={mobileMenuOpen}
          controlMobileMenuVisibility={controlMobileMenuVisibility}
        />
        <MobileMenu mobileMenuClass={mobileMenuClass} logoMobile={logoMobile} />
      </div>
    </section>
  );
};

export default NavProducts;
