import React from "react";
import QuestionWrapperComponent from "../QuestionWrapperComponent/QuestionWraperComponent";
import "./InputComponent.scss";

const InputSelectComponent = ({
  currentIndex, title, category, updateIndex, options,
  answer, setAnswer, placeHolder, currentCategory, onClose,

})  => {
  const selectedAnswer = options.find(option => option.value === answer)?.title;
  return (
    <QuestionWrapperComponent
    title={title}
    category={category}
    buttonPrevious="Zatvori"
    buttonNext="Dalje"
    updateIndex={updateIndex}
    currentIndex={currentIndex}
    hasValue={answer}
    currentCategory={currentCategory}
    onClose={onClose}
  >
    <div>
    <select name="" id="" placeholder={placeHolder} value={selectedAnswer} className="select-form" onChange={(event) => {
      const selectedOption = options.find(option => option.title === event.target.value);
      setAnswer(selectedOption.value)}}>
    <option value="" disabled selected hidden>{placeHolder}</option>
        {options.map(opt => <option>{opt.title}</option>)}
        </select>
    </div>
    </QuestionWrapperComponent>
  );
}

export default InputSelectComponent;