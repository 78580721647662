/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import NavMenu from "../../NavMenu/NavMenu";
import "./HeaderHero.scss";
import headerImg from "./assets/header-img.svg";
import logoMobile from "./assets/logo-mobile.svg";
import { strings } from "../../Translations/strings";
import MobileMenu from "../../Common/MobileMenu/MobileMenu";
import { useDispatch, useSelector } from "react-redux";
import {
  controlIntroModal,
  controlOpenModal,
} from "../../Calculator/state/calculatorSlice";
import { Link } from "react-router-dom";

const HeaderHero = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { introModalVisible, hasCompletedCategory } = useSelector(
    (state) => state.calculator
  );
  const dispatch = useDispatch();
  const mobileMenuClass = mobileMenuOpen ? "mobile-menu active" : "mobile-menu";
  const controlMobileMenuVisibility = () => setMobileMenuOpen(!mobileMenuOpen);

  const controlOpeningModalActive = () => {
    if (hasCompletedCategory) {
      return dispatch(controlOpenModal(true));
    }
    dispatch(controlIntroModal(!introModalVisible));
  };

  return (
    <section className="section-hero">
      <div className="c-container header">
        <NavMenu
          isMobileVisible={!introModalVisible}
          mobileMenuOpen={mobileMenuOpen}
          controlMobileMenuVisibility={controlMobileMenuVisibility}
        />

        <div className="c-container-sm">
          <div className="hero-content">
            <h2 className="h2">{strings.hero_section_title}</h2>
            <p className="subtitle-text">{strings.hero_section_text}</p>
            <button
              className="btn btn-green home-button"
              onClick={controlOpeningModalActive}
            >
              {strings.nav_ee_calculator_label}
            </button>
            <button className="btn btn-outline-primary home-button btn-products">
              <Link to="/proizvodi">{strings.hero_products}</Link>
            </button>
          </div>
        </div>
        <img src={headerImg} className="header-img" alt="header"></img>
      </div>
      <MobileMenu mobileMenuClass={mobileMenuClass} logoMobile={logoMobile} />
    </section>
  );
};

export default HeaderHero;
