import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


export const firebaseConfig = {
  apiKey: "AIzaSyCkJjCNUNuULLPBOgdwlFHUHSQ84TIm4Nk",
  authDomain: "enova-ee4b5.firebaseapp.com",
  projectId: "enova-ee4b5",
  storageBucket: "enova-ee4b5.appspot.com",
  messagingSenderId: "490232909782",
  appId: "1:490232909782:web:9005b6ae890c8deba71e77",
  measurementId: "G-MPN00BT4KD"
};

  export const app = initializeApp(firebaseConfig);
  export const analytics = getAnalytics(app);