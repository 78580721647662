import React from "react";
import "../CalculatorSidebar.scss";
import { strings } from "../../../Translations/strings";

const UncompletedCategories = ({
  sidebarData,
  displayResult,
  progress,
  questions,
}) => {
  return (
    <>
      <div className="sidebar-nav">
        {sidebarData?.map((question, index) => {
          const className = question?.answer ? "step done" : "step";
          return (
            <div key={question?.title} className={className}>
              <button className="done-number">{index + 1}</button>
              <span className="step-name">
                {question?.sideBarTitle} {index === 2 ? progress : ""}
              </span>
            </div>
          );
        })}
        {displayResult ? (
          <>
            <div className="step done">
              <button className="btn-step-number step-done">{4}</button>
              <span className="step">
                {strings.uncompleted_categories_result}
              </span>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default UncompletedCategories;
