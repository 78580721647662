/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  Navbar,
  Container,
  FormControl,
  FormGroup,
  InputGroup,
} from "react-bootstrap";
import "./MainMenu.scss";
import { strings } from "../../Translations/strings";
import { useDispatch, useSelector } from "react-redux";
import {
  controlIntroModal,
  controlOpenModal,
} from "../../Calculator/state/calculatorSlice";
import { persistor } from "../../State/store";
import { Link, useNavigate } from "react-router-dom";
import MainMenuFirst from "./MainMenuFirst";
import MainMenuSecond from "./MainMenuSecond";
import MainLinksMobile from "./MainLinksMobile";
import { searchProduct } from "../marketPlaceSlice";

const MainMenu = ({ mobileMenuOpen, controlMobileMenuVisibility }) => {
  const [clickedDropdown, setClickedDropdown] = useState(false);
  const { profile } = useSelector((state) => state.profile);
  const { introModalVisible, hasCompletedCategory } = useSelector(
    (state) => state.calculator
  );
  const { searchTerm } = useSelector((state) => state.marketPlace);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const controlOpeningModalActive = () => {
    if (hasCompletedCategory) {
      return dispatch(controlOpenModal(true));
    }
    dispatch(controlIntroModal(!introModalVisible));
  };

  const handleMarketplaceClickedDropdown = () => {
    setClickedDropdown(!clickedDropdown);
  };

  const handleMarketplaceCloseDropdown = () => {
    setClickedDropdown(false);
  };

  const handleLogOut = () => {
    persistor.purge().then(() => console.log(profile));
    navigate("/");
    window.location.reload();
  };

  const searchProductsHandler = (term) => {
    dispatch(searchProduct({ searchTerm: term?.target?.value || "" }));
  };

  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="px-5">
        <Container fluid>
          <Navbar.Brand>
            <Link to="/">
              <img
                className="nav_logo"
                src={require("./assets/Logo.svg").default}
                alt="logo"
              />
              <img
                className="nav_logo_mobile"
                src={require("./assets/Logo-mobile.svg").default}
                alt="logo"
              />
              <img
                className="eu-logo"
                src={require("./assets/EU-logo.svg").default}
                alt="logo"
              />
            </Link>
          </Navbar.Brand>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              navigate({
                pathname: "/proizvodi/search",
                search: `?term=${searchTerm?.searchTerm}`,
              });
              searchProductsHandler("");
            }}
          >
            <FormGroup className="d-none d-xl-flex">
              <InputGroup className="marketplace-input">
                <InputGroup.Text>
                  <img
                    src={require("./assets/Search.svg").default}
                    alt="search_icon"
                  />
                </InputGroup.Text>
                <FormControl
                  type="search"
                  placeholder={strings.marketplace_search}
                  className="me-2 marketplace-search"
                  aria-label="Search"
                  id="marketplace-search"
                  value={searchTerm?.searchTerm}
                  onChange={(value) => searchProductsHandler(value)}
                />
              </InputGroup>
            </FormGroup>
          </form>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-between"
          >
            <MainMenuFirst
              handleMarketplaceClickedDropdown={
                handleMarketplaceClickedDropdown
              }
              handleMarketplaceCloseDropdown={handleMarketplaceCloseDropdown}
              clickedDropdown={clickedDropdown}
            />
            <MainMenuSecond
              controlOpeningModalActive={controlOpeningModalActive}
              handleLogOut={handleLogOut}
            />
          </Navbar.Collapse>
          <MainLinksMobile
            controlMobileMenuVisibility={controlMobileMenuVisibility}
            mobileMenuOpen={mobileMenuOpen}
          />
        </Container>
      </Navbar>
    </>
  );
};

export default MainMenu;
