/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./Products.scss";
import { strings } from "../../Translations/strings";

const UncompletedCategoryBanner = ({
  currentCategory,
  currentSubcategory,
  openModal,
}) => {
  if(currentCategory === 'search') { return null }
 return (
    <>
      <div className="d-flex flex-column">
        <div className="category-label">
          {currentSubcategory === null
            ? currentCategory.replace(/-/g, " ").replace(/dj/g, "đ")
            : strings.products_label}
        </div>
        <div className="empty-col"></div>
        {currentCategory !== "hladjenje" ? (
          <div className="calc-instructions">
            {strings.category_banner_instructions_one}
            {currentSubcategory === null
              ? currentCategory
                  .replace(/-/g, " ")
                  .replace("proizvodi", "proizvode")
                  .replace("solarni", "solarne")
                  .replace("sistemi", "sisteme")
                  .replace("prozori", "prozore")
                  .replace("izolacija", "izolaciju")
              : strings.marketplace_banner_products}
            {strings.category_banner_instructions_two}
          </div>
        ) : (
          ""
        )}
      </div>
      <button className="btn btn-green" onClick={openModal}>
        {strings.category_banner_button_label}
      </button>
    </>
  );
};

export default UncompletedCategoryBanner;
