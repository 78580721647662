import React, { useEffect } from "react";
import { Button, ProgressBar } from "react-bootstrap";
import "./Wishlist.scss";
import removeIcon from "./assets/remove-product.svg";
import emptyWishlist from "./assets/empty-wishlist.svg";
import { strings } from "../Translations/strings";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getTotalSum, removeFromWishlist } from "./wishlistSlice";
import { toast } from "react-toastify";

const WishlistProducts = () => {
  const { wishlistItems, wishlistTotalAmount } = useSelector(
    (state) => state.wishlist
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTotalSum());
  }, [dispatch, wishlistItems]);

  const handleRemoveFromWishlist = (wishlistItem) => {
    dispatch(removeFromWishlist(wishlistItem));
    toast.error(`${wishlistItem.name} je uklonjen sa Vaše liste želja`, {
      position: "bottom-left",
    });
  };

  const productSaving = wishlistItems?.find((item) => item.productSaving);

  const openInNewTab = () => {
    window.open(
      "https://www.partner.ba/zahtjev_za_mk/fizicka_lica/prvi_korak?mk=ee_mp",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <>
      <div className="wishlist-container">
        <div className="d-flex wishlist-cc flex-column">
          {wishlistItems.length === 0 ? (
            <div className="wishlist-empty d-flex flex-column justify-content-center align-items-center">
              <img src={emptyWishlist} alt="shopping_bag"></img>
              <div className="d-flex flex-column align-items-center mb-0">
                <p className="empty-wishlist-title">
                  {strings.empty_wishlist_title}
                </p>
                <p className="empty-wishlist-subtitle">
                  {strings.empty_wishlist_subtitle}
                </p>
                <div className="start-shopping">
                  <Link
                    to="/proizvodi"
                    className="mt-0 submit-form-register btn btn-primary go-shopping-btn"
                  >
                    {strings.wishlist_view_products}
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex wishlist-mobile-column">
              <div className="wishlist-products-list">
                <p className="wishlist-title">{strings.wishlist_title}</p>
                <p className="wishlist-subtitle">{strings.wishlist_subtitle}</p>
                {wishlistItems.map((wishlistItem) => (
                  <div
                    key={wishlistItem.id}
                    className="d-flex wishlist-product"
                  >
                    <img
                      src={wishlistItem.image}
                      alt="wishlist_product_image"
                    ></img>
                    <div className="d-flex flex-column me-auto">
                      <Link
                        className="wishlist-product-title"
                        to={
                          "/proizvodi" +
                          `${
                            wishlistItem.category.title === "Solarni sistemi"
                              ? "/solarni-sistemi"
                              : wishlistItem.category.title === "Grijanje"
                              ? "/grijanje"
                              : wishlistItem.category.title === "Prozori"
                              ? "/prozori"
                              : "/izolacija"
                          }` +
                          "/proizvod/" +
                          `${wishlistItem.id}`
                        }
                        state={wishlistItem}
                      >
                        {wishlistItem.name}
                      </Link>
                      <p className="wishlist-product-price">
                        {(wishlistItem.price * 1).toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                        {strings.KM_label}
                      </p>
                    </div>
                    <img
                      src={removeIcon}
                      alt="remove_product"
                      className="remove-product"
                      onClick={() => handleRemoveFromWishlist(wishlistItem)}
                    ></img>
                  </div>
                ))}
              </div>
              <div className="d-flex flex-column wishlist-total-price">
                <p className="price-label">{strings.wishlist_total_price}</p>
                <p className="price-value">
                  {(wishlistTotalAmount * 1).toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) +
                    " " +
                    strings.KM_label}
                </p>
                {wishlistItems?.length === 1 && productSaving && (
                  <>
                    <ProgressBar now={40} className="product-progress-bar" />
                    <p className="product-savings">
                      {strings.financing_energy_savings}{" "}
                      <b>{productSaving?.energySavingsPercentage}</b>
                    </p>
                    <ProgressBar now={80} className="product-progress-bar" />
                    <p className="product-savings">
                      {strings.financing_money_savings}{" "}
                      <b>{productSaving?.moneySavingsPercentage}</b>
                    </p>
                    <hr className="grey-line-one"></hr>
                  </>
                )}
                <Button
                  variant="primary"
                  className="request-button"
                  onClick={openInNewTab}
                >
                  {strings.financing_request_button_label}
                </Button>
              </div>
            </div>
          )}
          <div></div>
        </div>
      </div>
    </>
  );
};
export default WishlistProducts;
