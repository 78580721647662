import React from "react";
import "./MainContent.scss";
import manSolarMobile from "./assets/mobile-solar-panels 1.png";
import heatingMobile from "./assets/mobile-heating.png";
import windows from "./assets/windows.png";
import isolationMobile from "./assets/mobile-isolation.png";
import { strings } from "../../Translations/strings";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateCategory } from "../../Marketplace/marketPlaceSlice";

const SectionImagesMobile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setCurrentCategory = (category) => {
    dispatch(updateCategory(category));
  };

  const navigateToCategory = (category) => {
    setCurrentCategory(category);
    navigate("/proizvodi/" + category, { state: "/" });
    window.scrollTo(0, 0);
  };
  return (
    <>
      <section className="section-images mobile">
        <div className="row">
          <div
            className="col-md-6"
            onClick={() => {
              navigateToCategory("grijanje");
            }}
          >
            <img src={heatingMobile} alt="heating"></img>
            <div className="text">
              <h5>{strings.landing_main_section_heating_label}</h5>
            </div>
          </div>
        </div>

        <div className="row">
          <div
            className="col-6 solar-systems left"
            onClick={() => {
              navigateToCategory("solarni-sistemi");
            }}
          >
            <img src={manSolarMobile} alt={"man-solar"}></img>
            <div className="text">
              <h5>{strings.landing_main_section_solar_systems_label}</h5>
            </div>
          </div>
          <div
            className="col-6"
            onClick={() => {
              navigateToCategory("prozori");
            }}
          >
            <img src={windows} alt="windows"></img>
            <div className="text bottom">
              <h5>{strings.landing_main_section_window_label}</h5>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-6"
            onClick={() => {
              navigateToCategory("izolacija");
            }}
          >
            <img src={isolationMobile} alt="isolation"></img>
            <div className="text bottom">
              <h5>{strings.landing_main_isolation_label}</h5>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionImagesMobile;
