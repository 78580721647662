import React, { useEffect } from "react";
import "./OpeningCalculatorModal.scss";
import calculatorLogo from "../assets/calculator-logo.svg";
import calculatorLogoMobile from "../assets/calculator-logo-mobile.png";
import closeIcon from "../assets/calculator-close-icon.svg";
import calculatorImage from "./assets/calculator-img.svg";
import fundedByEU from "../assets/funded-by-eu.png";
import fundedByEUMobile from "./assets/funded-by-eu-mobile.png";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { controlIntroModal, controlOpenModal } from "../state/calculatorSlice";
import { strings } from "../../Translations/strings";

const OpeningCalculatorModal = () => {
  const { introModalVisible } = useSelector((state) => state.calculator);

  const dispatch = useDispatch();

  useEffect(() => {
    if (introModalVisible) document.body.style.overflow = "hidden";
    else document.body.removeAttribute("style");
    return () => {
      document.body.removeAttribute("style");
    };
  }, [introModalVisible]);

  const onClose = () => {
    dispatch(controlIntroModal(false));
  };

  const openCalculator = () => {
    dispatch(controlIntroModal(false));
    dispatch(controlOpenModal(true));
  };
  return (
    <Modal
      isOpen={introModalVisible}
      appElement={document.getElementById("root") || undefined}
      overlayClassName="Overlay"
      className="Modal"
    >
      <div className="opening-calculator">
        <div className="opening-calculator-top">
          <div className="opening-calculator-nav">
            <div className="logo-container">
              <div className="d-flex ">
                <img
                  src={calculatorLogo}
                  alt="calculator_logo"
                  className="opening-calc-logo"
                />
                <p>
                  <span>{strings.opening_calculator_modal_title_one}</span>
                  <br></br> {strings.opening_calculator_modal_title_two}
                </p>
              </div>
              <div className="opening-calc-eu-container">
                <img
                  src={fundedByEU}
                  alt="funded_by_EU"
                  className="opening-calc-eu"
                />
              </div>
            </div>
            <img
              src={fundedByEUMobile}
              alt="funded_by_EU"
              className="funded-by-eu-mobile"
            />
            <div className="logo-mobile-wrapper">
              <img src={calculatorLogoMobile} alt="" />
            </div>
            <button className="button-close" onClick={onClose}>
              <img src={closeIcon} alt="close_icon" />
            </button>
          </div>
          <img
            src={calculatorImage}
            alt="calculator"
            className="calculator-image"
          />
        </div>
        {/* text container */}
        <div className="text-container">
          <h5>{strings.opening_calculator_modal_title_three}</h5>
          <p>{strings.opening_calculator_modal_p}</p>
        </div>
        {/* modal bottom */}
        <div className="opening-calculator-bottom">
          <button id="a" onClick={onClose} className="close-button">
            {strings.opening_calculator_close_label}
          </button>
          <button
            id="b"
            className="home-button btn-green"
            onClick={openCalculator}
          >
            {strings.opening_calculator_button_label}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default OpeningCalculatorModal;
