import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../Environment/config";
import { httpClient } from "../Http/client";
import { strings } from "../Translations/strings";

const mapCategoryNameToCategoryId = ({ categoryName }) => {
  if (categoryName === "grijanje") {
    return "e2ef99db-b889-4d6c-97e6-65d3785f3597";
  }
  if (categoryName === "paneli") {
    return "c8203992-0dde-414b-b81a-cdd319f85e7d";
  }
  if (categoryName === "prozori") {
    return "e3ec79d6-55f0-4860-94dc-efa902b27d53";
  }
  if (categoryName === "izolacija") {
    return "5f1da897-9c86-4736-b31e-760d6ea2608e";
  }
  if (categoryName === "hladjenje") {
    return "";
  }
};
export const fetchProducts = createAsyncThunk(
  "marketPlace/products",
  (nextToken) => {
    return httpClient
      .get(`${BASE_URL}/products`, { params: nextToken })
      .then((response) => response.data)
      .catch((error) => error);
  }
);

export const loadMoreProducts = createAsyncThunk(
  "marketPlace/newProducts",
  (token) => {
    return httpClient
      .get(`${BASE_URL}/products?token=${token}`)
      .then((response) => response.data)
      .catch((error) => error);
  }
);

export const fetchCategoryProducts = createAsyncThunk(
  "marketPlace/categoryProducts",
  (bodyData) => {
    if (!bodyData.currentCategory || bodyData.currentCategory === "all")
      return {};
    return httpClient
      .get(
        `${BASE_URL}/products/category/${mapCategoryNameToCategoryId({
          categoryName: bodyData.currentCategory,
        })}`,
        { params: bodyData.nextToken }
      )
      .then((response) => {
        return { ...response.data, id: bodyData.currentCategory };
      })
      .catch((error) => error);
  }
);

export const loadMoreProductsByCategory = createAsyncThunk(
  "marketPlace/newProductsByCategory",
  (bodyData) => {
    return httpClient
      .get(
        `${BASE_URL}/products/category/${mapCategoryNameToCategoryId({
          categoryName: bodyData.currentCategory,
        })}?token=${bodyData.nextToken}`,
        { params: bodyData.nextToken }
      )
      .then((response) => {
        return { ...response.data, id: bodyData.currentCategory };
      })
      .catch((error) => error);
  }
);

export const loadMoreProductsInCategoryByFilter = createAsyncThunk(
  "marketPlace/moreProductsInCategoryByFilter",
  (bodyData) => {
    const url = bodyData.url;
    return httpClient
      .get(
        `${BASE_URL}/products?categoryId=${mapCategoryNameToCategoryId({
          categoryName: bodyData.currentCategory,
        })}&${url}token=${bodyData.nextToken}`,
        {
          params: bodyData.nextToken,
        }
      )
      .then((response) => {
        return { ...response.data, id: bodyData.currentCategory };
      })
      .catch((error) => error);
  }
);

export const filterProductsHandler = createAsyncThunk(
  "marketPlace/filterProducts",
  (params) => {
    const categoryId = mapCategoryNameToCategoryId({
      categoryName: params?.categoryId,
    });
    const url = categoryId
      ? `${params.url}categoryId=${categoryId}`
      : params.url;
    return httpClient
      .get(`${BASE_URL}/products?${url}`)
      .then((response) => {
        return { ...response.data, id: params?.categoryId };
      })
      .catch((error) => error);
  }
);

export const loadMoreProductsByFilter = createAsyncThunk(
  "marketPlace/filterMoreProducts",
  (params) => {
    const categoryId = mapCategoryNameToCategoryId({
      categoryName: params?.categoryId,
    });
    const url = categoryId
      ? `${params.url}categoryId=${categoryId}token=${params?.token}`
      : params.url;
    return httpClient
      .get(`${BASE_URL}/products?${url}token=${params?.token}`)
      .then((response) => {
        return { ...response.data, id: params?.categoryId };
      })
      .catch((error) => error);
  }
);

export const searchProductsHandler = createAsyncThunk(
  "marketPlace/searchProducts",
  (params) => {
    const categoryId = mapCategoryNameToCategoryId({
      categoryName: params?.categoryId,
    });
    const url = categoryId ? `${params.url}&categoryId` : params.url;
    return httpClient
      .get(`${BASE_URL}/products/search?${url}`)
      .then((response) => {
        return { ...response.data, id: params?.categoryId };
      })
      .catch((error) => error);
  }
);

export const getVendors = createAsyncThunk("marketPlace/vendors", () => {
  return httpClient
    .get(`${BASE_URL}/vendors`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => error);
});

export const getCategoriesList = createAsyncThunk(
  "marketPlace/categoriesList",
  () => {
    return httpClient
      .get(
        `${BASE_URL}/categories/subcategories/e2ef99db-b889-4d6c-97e6-65d3785f3597`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => error);
  }
);

const initialState = {
  currentCategory: "grijanje",
  currentSubcategory: null,
  defaultProducts: [],
  newProducts: [],
  newCategoryProducts: [],
  newFilteredCategoryProducts: [],
  newFilteredProducts: [],
  filteredProducts: [],
  selectedVendorFilters: {},
  selectedProductFilters: {},
  vendorList: [],
  categoryList: [],
  searchTerm: "",
  sort: {
    value: null,
    title: null,
  },
  marketPlace: {
    all: {
      products: [],
      nextToken: null,
    },
    grijanje: {
      products: [],
      nextToken: null,
    },
    paneli: {
      products: [],
      nextToken: null,
    },
    prozori: {
      products: [],
      nextToken: null,
    },
    izolacija: {
      products: [],
      nextToken: null,
    },
    hladjenje: {
      products: [],
      nextToken: null,
    },
    search: {
      products: [],
      nextToken: null,
    },
  },
};

export const marketPlaceSlice = createSlice({
  name: "marketPlace",
  initialState,
  reducers: {
    updateCategory: (state, action) => {
      state.currentCategory = action.payload;
    },
    updateSubcategory: (state, action) => {
      state.currentSubcategory = action.payload;
    },
    searchProduct: (state, action) => {
      state.searchTerm = action.payload;
    },
    sortProducts: (state, action) => {
      const { order } = action.payload;
      if (order === "default") {
        state.sort = null;
      }
      if (order === "asc") {
        state.sort = {
          value: "desc",
          title: strings.sort_dropdown_label_two,
        };
        return;
      }
      if (order === "desc") {
        state.sort = {
          value: "desc",
          title: strings.sort_dropdown_option_one,
        };
        return;
      }
    },
  },
  extraReducers: {
    [fetchProducts.fulfilled]: (state, action) => {
      state.marketPlace.all.products = action.payload.data;
      state.marketPlace.all.nextToken = action.payload.nextToken;
    },
    [loadMoreProducts.fulfilled]: (state, action) => {
      const newProducts = action.payload.data;
      state.newProducts = newProducts;
      state.marketPlace.all.products =
        state.marketPlace.all.products.concat(newProducts);
      state.marketPlace.all.nextToken = action.payload.nextToken;
    },
    [fetchCategoryProducts.fulfilled]: (state, action) => {
      const { data, nextToken, id } = action.payload;
      if (data && id) {
        state.marketPlace[id].products = data;
        state.marketPlace[id].nextToken = nextToken;
      }
    },
    [loadMoreProductsByCategory.fulfilled]: (state, action) => {
      const { data, nextToken, id } = action.payload;
      if (data && id) {
        const newCategoryProducts = data;
        state.newCategoryProducts = newCategoryProducts;
        state.marketPlace[id].products =
          state.marketPlace[id].products.concat(newCategoryProducts);
        state.marketPlace[id].nextToken = nextToken;
      }
    },
    [filterProductsHandler.fulfilled]: (state, action) => {
      const { data, nextToken, id } = action.payload;
      if (!id) {
        state.marketPlace.all.products = action.payload.data;
        state.marketPlace.all.nextToken = action.payload.nextToken;
        return;
      }
      state.filteredProducts = data;
      state.marketPlace[id].products = data;
      state.marketPlace[id].nextToken = nextToken;
    },
    [loadMoreProductsByFilter.fulfilled]: (state, action) => {
      const newProducts = action.payload.data;
      state.newProducts = newProducts;
      state.marketPlace.all.products = state.marketPlace.all.products.concat(
        action.payload.data
      );
      state.marketPlace.all.nextToken = action.payload.nextToken;
    },
    [loadMoreProductsInCategoryByFilter.fulfilled]: (state, action) => {
      const { data, nextToken, id } = action.payload;
      if (data && id) {
        const newFilteredCategoryProducts = data;
        state.newFilteredCategoryProducts = newFilteredCategoryProducts;
        state.marketPlace[id].products = state.marketPlace[id].products.concat(
          newFilteredCategoryProducts
        );
        state.marketPlace[id].nextToken = nextToken;
      }
    },
    [getVendors.fulfilled]: (state, action) => {
      state.vendorList = action.payload;
    },
    [getCategoriesList.fulfilled]: (state, action) => {
      state.categoryList = action.payload;
    },
    [searchProductsHandler.fulfilled]: (state, action) => {
      const { data, nextToken } = action.payload;
      state.marketPlace["search"].products = data;
      state.marketPlace["search"].nextToken = nextToken;
    },
  },
});

export const {
  updateCategory,
  updateSubcategory,
  sortProducts,
  searchProduct,
  marketplace,
} = marketPlaceSlice.actions;

export default marketPlaceSlice.reducer;
