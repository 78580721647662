import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { strings } from "../../Translations/strings";
import "./MyProfile.scss";
import { updatePasswordHandler } from "../updatePasswordHandler";
import { useState } from "react";

const UpdatePasswordModal = ({ isVisible, closeModal, profile, ...props }) => {
  const [updateInProgress, setUpdateInProgress] = useState(false)
  const schema = yup.object().shape({
    password: yup
      .string()
      .min(6, strings.validation_field_password)
      .required(strings.validation_field_password_required),
    newPassword: yup
      .string()
      .min(6, strings.validation_field_password)
      .required(strings.validation_field_password_required),
    confirmNewPassword: yup
      .string()
      .oneOf(
        [yup.ref("newPassword"), null],
        "Novi password i potvrda novog passworda moraju biti isti"
      ),
  });

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      password: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });

  const onSubmit = async (data) => {
    setUpdateInProgress(true);
    const updateSuccess = 
    await updatePasswordHandler({...profile?.data, password: data.password, newPassword: data.newPassword, confirmPassword: data.confirmNewPassword})
    .catch((err) => {
      setError('password', { type: 'custom', message: 'Pogrešan password' });
      setUpdateInProgress(false)
    });
    if(updateSuccess){ 
      closeModal()
      setUpdateInProgress(false)
    }
  };
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="opening-calculator">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="formPassword">
            <Form.Label className="control-label">
              {strings.account_current_password}
            </Form.Label>
            <Form.Control
              type="password"
              isInvalid={errors?.password?.message}
              placeholder="Upišite trenutni password"
              className="account-password-container"
              onChange={(e) => setValue("password", e.target.value)}
              {...register("password", { required: true })}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.password?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formPassword1" className="mb-3"               
          style={{marginTop: '20px'}}>
            <Form.Label className="control-label">
            {strings.account_new_password}
            </Form.Label>
            <Form.Control
              type="password"
              placeholder="Upišite novi password"
              isInvalid={errors?.newPassword?.message}
              className="account-password-container"
              {...register("newPassword", { required: true })}
              onChange={(e) => setValue("newPassword", e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.newPassword?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formPassword2" style={{marginTop: '20px'}}>
            <Form.Label className="control-label">
              {strings.account_new_password}
            </Form.Label>
            <Form.Control
              type="password"
              isInvalid={errors?.confirmNewPassword?.message}
              placeholder="Potvrdite novi password"
              className="account-password-container"
              onChange={(e) => setValue("confirmNewPassword", e.target.value)}
              {...register("confirmNewPassword", { required: true })}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.confirmNewPassword?.message}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
          </Form.Group>
          <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
           <Button
              className="discard-password-changes-btn"
              onClick={closeModal}
            >
              {strings.account_discard_changes_btn}
            </Button>
          <Button variant="primary" type="submit" disabled={!isDirty || !isValid} className="save-password-changes-btn save-password-changes-btn-active">
            {updateInProgress ?  <div className="spinner-border text-light" role="status"></div> : "Spremi"}
          </Button>
        </div>
        </Form>
      </div>
    </Modal>
  );
};

export default UpdatePasswordModal;
