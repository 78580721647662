export const strings = {
  nav_home_label: "Početna",
  nav_about_us_label: "O nama",
  nav_energy_efficient_label: "Energijska efikasnost",
  nav_products_label: "Proizvodi",
  nav_partner_label: "Saznaj više",
  nav_finansing_label: "Saznaj više",
  nav_my_profile_label: "Moj profil",
  nav_ee_calculator_label: "Kalkulator ušteda",
  nav_ee_calculator_navbar: "Kalkulator ušteda",
  hero_products: "Pogledaj proizvode",
  login_form_title: "Prijava",
  email_input_label: "Email",
  password_input_label: "Password",
  email_input_placeholder: "Email",
  login_form_login_button_label: "Prijavi se",
  login_remember_password_label: "Zapamti password",
  not_register_user_label: "Nisi registrovan korisnik? ",
  create_account_label: "Kreiraj profil ovdje",
  regular_user_label: "Fizičko lice",
  company_user_label: "Pravno lice",
  city_register_form_label: "Grad",
  telephone_register_form_label: "Telefon",
  register_form_first_name_label: "Ime",
  register_form_last_name_label: "Prezime",
  register_form_title: "Registracija",
  register_form_select_role_label: "Ja sam",
  register_form_already_register_label: "Registrovan korisnik? ",
  register_form_login_here_label: "Prijavite se ovdje",
  register_toc_i_agree: "Slažem se sa",
  register_toc_label: "Uslovima korištenja",
  register_toc_error: "Morate prihvatiti uslove korištenja",
  register_form_register_button_label: "Registruj se",
  footer_explore_label: "Istraži",
  footer_about_us_label: "O nama",
  footer_products_label: "Proizvodi",
  footer_financing_label: "Finansiranje",
  footer_blog_label: "Blog",
  footer_contact_us_label: "Kontaktirajte nas",
  footer_partner_email: "info@partner.ba",
  footer_partner_telphone: "080 020 207",
  footer_partner_address: "Merhemića trg bb 71000 Sarajevo",
  footer_copyright_label: "© ",
  footer_copyright_owner: "Partner |",
  footer_all_rights_reserved_label: "Sva prava zaštićena.",
  footer_terms_and_condition_label: "Uslovi korištenja",
  footer_policy_privacy_label: "Polica privatnosti",
  footer_text:
    "Partner MKF je lider u finansiranju mjera energijske efikasnosti, već dugi niz godina ima razvijenu strategiju kreditiranja energijske efikasnosti uz tehničku pomoć i sufinansiranje troškova ulaganja.",
  footer_funded_by_eu:
    "This website was produced with the financial assistance of the European Union. The views expressed herein can in no way be taken to reflect the official opinion of the European Union[A1] .",
  footer_more: "Pogledaj više",
  hero_section_title: "Izaberite najefikasnije rješenje za vaš dom",
  hero_section_text:
    "Svako ulaganje u energijsku efikasnost donosi povećan komfor, ali i znatne uštede.",
  landing_main_section_heat_pump_label: "Toplotne pumpe",
  landing_main_section_heat_pump_text:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  landing_main_section_heat_pelet_label: "Peći na pelet",
  landing_main_section_heat_pelet_text:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  landing_main_section_solar_systems_label: "Solarni sistemi",
  landing_main_section_solar_systems_text: "Lorem Ipsum text",
  landing_main_section_heating_label: "Grijanje",
  landing_main_section_heating_text: "Lorem Ipsum text",
  landing_main_section_window_label: "Prozori",
  landing_main_isolation_label: "Izolacija",
  landing_main_cooling_label: "Hlađenje",
  landing_main_fast_finacing_label: "Brze i efikasne opcije finansiranja",
  landing_main_fast_finacing_text:
    "Putem mikrokredita u Partner MKF povećajte komfor i ostvarite uštede. Uz ličnu kartu i CIPS prijavu prebivališta brzo i lako do mikrokredita. ",
  landing_main_reduce_monthly_costs_label: "Smanjite mjesečne troškove",
  landing_main_reduce_monthly_costs_text_one:
    "Zamjenom starih prozora i vrata energijski efikasnim ostvarite ",
  landing_main_reduce_monthly_costs_text_two: "do 40% uštede. ",
  landing_main_reduce_monthly_costs_text_three:
    "Zamjenom sistema grijanja ostvarite ",
  landing_main_reduce_monthly_costs_text_four: "do 35% uštede. ",
  landing_main_reduce_monthly_costs_text_five:
    "Postavljanjem termo-izolacije ostvarite ",
  landing_main_reduce_monthly_costs_text_six: "do 30% uštede. ",
  landing_main_calculator_effieciency_label:
    "Kalkulator energijske efikasnosti",
  landing_main_calculator_effieciency_text:
    "Izračunajte uštede koje možete ostvariti zamjenom vaših postojećih sistema grijanja, ugradnjom solarnih panela te izolacijom vanjskih zidova i zamjenom prozora.",
  landing_main_calculate_possible_saving_label: "Izračunaj moguće uštede",
  discount_label: "Popusta",
  mobile_menu_label: "Menu",
  mobile_menu_logout: "Odjavi se",
  marketplace_button_label: "Moj Kalkulator",
  marketplace_search: "Pretraži",
  submenu_heating_label: "Grijanje",
  submenu_solar_label: "Solarni sistemi",
  submenu_windows_label: "Prozori",
  submenu_isolation_label: "Izolacija",
  submenu_cooling_label: "Hlađenje",
  category_breadcrumb_home: "Početna",
  category_breadcrumb_products: "Proizvodi",
  category_breadcrumb_heating: "Grijanje",
  category_breadcrumb_solar: "Solarni sistemi",
  category_breadcrumb_isolation: "Izolacija",
  category_breadcrumb_windows: "Prozori",
  category_breadcrumb_cooling: "Hladjenje",
  category_banner_instructions_one: "Popunite EE profil za ",
  category_banner_instructions_two: " kako bi dobili precizan prikaz ušteda",
  category_banner_button_label: "Izračunaj uštede",
  category_banner_category_completed_one: "Profil za ",
  category_banner_category_completed_two: " je popunjen",
  category_banner_category_completed_edit: "Uredi",
  products_filter_brand: "Brend",
  products_filter_categories: "Kategorija",
  products_filter_price: "Cijena",
  products_filter_vendor: "Trgovac ",
  products_filter_category_one: "Toplotne pumpe",
  products_filter_category_two: "Peći na pelet",
  products_filter_brand_one: "Brend 1",
  products_filter_brand_two: "Brend 2",
  products_filter_brand_three: "Brend 3",
  products_filter_price_one: "0 - 100",
  products_filter_price_two: "100 - 200",
  products_filter_price_three: "200 - 500",
  products_filter_vendor_one: "Trgovac 1",
  products_filter_vendor_two: "Trgovac 2",
  products_sort_number: "325 ",
  products_sort_label: " Proizvoda",
  sort_dropdown_label_one: "Prikaži prvo",
  sort_dropdown_label_two: "Najjeftinije artikle",
  sort_dropdown_option_one: "Najskuplje artikle",
  sort_dropdown_option_two: "Popularne artikle",
  sort_dropdown_option_three: "Nove artikle",
  sort_by_default: "Osnovno sortiranje",
  validation_field_required_label: "Ovo polje je obavezno",
  validation_field_email: "Unesite validan email",
  validation_field_type: "Molimo selektujte tip korisnika",
  validation_field_password: "Password mora sadržavati minimalno 6 karaktera",
  validation_field_password_required: "Password je obavezno",
  validation_field_first_name: "Unesite ime",
  validation_field_last_name: "Unesite prezime",
  validation_field_city: "Unesite grad",
  validation_field_telephone: "Unesite telefon",
  registration_error_user_exists: "User sa {email} već postoji u sistemu",
  login_error_label: "Pogrešan email ili password",
  products_savings_info: "Ušteda do ",
  products_savings_amount: "80%",
  product_description_short:
    "Popunite kalkulator energijske efikasnosti i izračunajte moguće uštede.",
  product_description_short_one: "Ako kupite ovaj proizvod možete uštediti do",
  product_description_short_two:
    "KM za godinu dana, izračunato na osnovu vaše trenutne potrošnje u odnosu na procijenjenu potrošnju ovog proizvoda.",
  product_description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non suspendisse urna odio tellus non tellus convallis amet sed. Pharetra congue cursus morbi laoreet condimentum massa bibendum arcu. Libero molestie nulla elementum est senectus. Id faucibus dignissim faucibus ultrices risus diam libero. Est id arcu pharetra convallis pellentesque amet, lobortis tincidunt purus. Tincidunt ut laoreet lectus eu pellentesque eget. Lacinia mauris posuere viverra consequat, mi nec a.Pharetra nisi viverra volutpat, etiam vitae aliquam, lectus. In sit ullamcorper habitasse blandit eu quam. Lacus, vel sagittis vivamus nunc. Non neque nisl eget mauris in enim. Lorem quisque aliquam aliquam vitae, laoreet mattis aliquet. Sed volutpat, vitae fermentum lorem.",
  product_vendor_label: "Trgovac: ",
  product_due_date: "Rok otplate",
  product_monthly_rate_label: " / mjesečno",
  product_tab_one: "O proizvodu",
  product_tab_two: "Tehnički detalji",
  product_tab_three: "Dokumenti",
  product_extra_savings_info:
    "Na ovaj proizvod možete ostvariti dodatnih {discount}% popusta",
  partner_offer: "Želim finansiranje putem mikrokredita",
  loan_example_label: "Vidi reprezentativni primjer mikrokredita",
  loan_example_title: "Reprezentativni primjer mikrokredita",
  loan_example_type: "Vrsta mikrokredita",
  loan_example_amount: "Iznos",
  loan_example_due_date: "Rok otplate",
  loan_example_nominal_rate: "Nominalna kamatna stopa (NKS)",
  loan_example_effective_rate: "Efektivna kamatna stopa (EKS)",
  loan_example_monthly_amount: "Rata",
  loan_example_interest: "Kamata",
  loan_example_total: "Ukupno",
  loan_example_processing_cost: "Trošak obrade",
  loan_example_documentation_verification_cost: "Trošak ovjere dokumentacije",
  financing_price_label: "Cijena:",
  financing_energy_savings: "Ušteda energije do ",
  financing_money_savings: "Ušteda novca do ",
  financing_savings_more_details: "Više detalja o uštedama",
  financing_request_button_label: "Pošalji upit za mikrokredit",
  financing_add_button_label: "Dodaj u listu",
  financing_question_button_label: "Pošalji upit trgovcu",
  partner_question_button_label: "Pošalji upit Partner MKF",
  financing_complete_category: "Izračunaj uštede",
  savings_modal_title: "Detalji o uštedi",
  savings_modal_product: "Proizvod",
  savings_modal_info: "Zamjenom postojećeg sistema možete ostvariti:",
  savings_table_info: "Finansiranje putem mikrokredita Partner MKF",
  savings_table_row_one: "Cijena odabrane opreme KM",
  savings_table_row_two:
    "Iznos ukupne uštede na troškovima grijanja tokom životnog vijeka opreme",
  savings_table_row_three:
    "Ukupan iznos nominalne kamate na Partner mikrokreditu",
  savings_table_row_four:
    "Pokrivenost nominalne kamatne stope uštedama troškova grijanja",
  savings_table_row_five: "Vrijednost granta",
  savings_table_row_six: "Vrijeme povrata investicije",
  savings_table_row_seven: "NKS - ",
  savings_table_row_seven_i: "nominalna kamatna stopa",
  savings_table_row_eight: "EKS - ",
  savings_table_row_eight_i: "efektivna kamatna stopa",
  savings_table_KM_label: "KM",
  savings_table_percentage_label: "%",
  savings_table_year_label: "godina",
  savings_modal_loan: "KM",
  savings_modal_interest: "KM",
  savings_modal_repayment: "KM",
  savings_modal_description_one:
    "Cijena proizvoda sa uračunatom procijenjenom cijenom ugradnje",
  savings_modal_description_two:
    "Tokom životnog vijeka proizvoda u odnosu na ukupni iznos nominalne kamate",
  savings_modal_description_three:
    "Ovisi o kreditnom proizvodu kao i o odabranom proizvodu",
  savings_modal_description_four:
    "Cijena opreme u odnosu na godišnje uštede u troškovima grijanja",
  savings_indebtdness: "%",
  savings_energy: "600",
  savings_ebrd: "375",
  savings_total: "-275",
  savings_assets_cost: "10.034 KM",
  savings_total_savings_heating: "20.600 KM",
  savings_total_rate: "10.469 KM",
  savings_rate_ratio: "197%",
  savings_loan_amount: "1.000 KM",
  savings_energy_savings: "Ušteda u energiji",
  savings_energy_savings_percentage: "62%",
  savings_yearly_savings: "Godišnja ušteda u troškovima grijanja",
  savings_yearly_savings_amount: "1.030 KM",
  savings_disclaimer: "Kalkulacije su informativnog karaktera",
  group_overview_title: "Kalkulator ušteda",
  group_overview_completed_edit: "Uredi",
  group_overview_incomplete_start_over: "Pokreni kalkulator",
  group_overview_profile_completed: "Profil kompletiran",
  group_overview_profile_incomplete: "Profil nije kompletiran",
  group_overview_restart: "Restartuj",
  group_overview_edit: "Uredi",
  group_overview_results_p: "Zamjenom postojećeg sistema mozete ostvariti:",
  group_overview_energy_savings: "Ušteda energije do ",
  group_overview_money_savings: "Ušteda novca do ",
  results_p_part_one: "Energijski profil za ",
  results_p_part_two: " je uspješno popunjen",
  results_p_part_three: "Prilikom pretraživanja proizvoda iz kategorije",
  results_p_part_four:
    " imat ćete uvid u moguće uštede i prilike za finansiranje",
  results_restart: "Restartuj Kalkulator",
  results_go_to_products: "Pogledaj proizvode",
  opening_calculator_modal_title_one: "Kalkulator",
  opening_calculator_modal_title_two: "Energijske efikasnosti",
  opening_calculator_modal_title_three: "Kalkulator energijske efikasnosti",
  opening_calculator_modal_p:
    "Dobrodošli u Kalkulator energijske efikasnosti. Na sljedećem koraku izaberite kategorije koje vas interesuju, odgovorite na pitanja i dobit ćete precizne rezultate ušteda te uslove finansiranja putem mikrokredita Partner MKF.",
  opening_calculator_button_label: "Vodi me na Kalkulator",
  opening_calculator_close_label: "Zatvori",
  calculator_sidebar_title_one: "Kalkulator",
  calculator_sidebar_title_two: "Energijske efikasnosti",
  calculator_register_title:
    "Kako bi sačuvali vaše rezultate i koristili ih pri kalkulaciji ušteda za specifične proizvode, molimo Vas da se prijavite.",
  calculator_login_title:
    "Kako bi sačuvali vaše rezultate i koristili ih pri kalkulaciji ušteda za specifične proizvode, molimo Vas da se prijavite.",
  myaccount_label: "Moj Profil",
  account_user_identification: "Ja sam",
  account_change_password: "Promijeni Password",
  account_current_password: "Trenutni Password",
  account_new_password: "Novi Password",
  account_discard_changes: "Odustani od promjene",
  account_discard_changes_btn: "Odustani",
  account_save_changes: "Spasi izmjene",
  navmenu_about_us: "O nama",
  navmenu_ee_label: "Energijska efikasnost",
  navmenu_microcredit: "Online mikrokredit",
  navmenu_account_label: "Moj profil",
  navmenu_logout_label: "Odjava",
  mainmenu_about_us: "O nama",
  mainmenu_ee_label: "Energijska efikasnost",
  mainmenu_microcredit: "Online mikrokredit",
  mainmenu_account_label: "Moj profil",
  mainmenu_logout_label: "Odjava",
  uncompleted_categories_result: "Rezultati",
  completed_category_user_type: "Tip Korisnika",
  completed_category_options: "Odabir Kategorije",
  completed_category_result: "Rezultati",
  send_message_to_vendor: "Pošaljite poruku trgovcu",
  send_message_to_vendor_email_label: "Vaša Email adresa",
  send_message_to_vendor_subject: "Subject",
  send_message_to_vendor_message_label: "Poruka",
  send_message_to_vendor_placeholder: "Napišite poruku",
  send_message_to_vendor_validation: "Neispravan unos",
  send_message_to_vendor_discard: "Odustani",
  send_message_to_vendor_send: "Pošalji upit trgovcu",
  question_vendor_subject_placeholder: "Pitanje vezano za proizvod ",
  product_images_title: "Slike",
  wishlist_title: "Lista željenih Artikala",
  wishlist_subtitle:
    "Pogledajte ukupan iznos uštede odabranih produkata i pošaljite zahtjev za finansiranje",
  wishlist_total_price: "Ukupna cijena",
  wishlist_empty: "Vaša lista želja je prazna.",
  wishlist_view_products: "Idi na Proizvode",
  empty_wishlist_title: "Lista željenih Artikala je prazna",
  empty_wishlist_subtitle: "Nemate proizvoda u listi",
  KM_label: "KM",
  decimals_KM_label: ",00 KM",
  products_label: "Proizvodi",
  marketplace_banner_products: "proizvode",
  not_found_title: "Ups!",
  not_found_subtitle: "Ne možemo da nađemo stranicu koju tražite.",
  not_found_btn_label: "Idi na početnu stranicu",
  toc_text:
    "Izjavljujem da dobrovoljno pristupam EE Digitalnom marketu i dajem saglasnost Partner mikrokreditnoj fondaciji da moje lične podatke, uključujući ime, prezime i broj telefona može prikupljati, obrađivati i distribuirati u svrhu aktivnosti EE (Digitalnog) marketa, dostave obavijesti i ponuda o proizvodima i uslugama i posebnim pogodnostima koje nudi, poštujući dobre poslovne običaje, Zakon o zaštiti ličnih podataka BiH i Zakon o zaštiti potrošača u BiH. Partner mikrokreditna fondacija može moje lične podatke obrađivati i čuvati samo onoliko dugo koliko je potrebno u svrhe radi koje je saglasnost data, ili do povlačenja saglasnosti.Poznato mi je da mogu tražiti pristup mojim ličnih podacima i ispravku netačnih ličnih podataka, kao i prestanak obrade istih poštom na adresu: 15. maja bb, Tuzla, ili email-om na adresu info@partner.ba",
  toc_back_to_registration: "Nazad na registraciju",
  toc_explanation_title: "Uslovi korištenja",
  calculation_description_text:
    "Izračunato na osnovu Vaše trenutne potrošnje u odnosu na procijenjenu potrošnju sa ovim proizvodom.",
  financing_with_partner:
    "U slučaju da se radi o finansiranju putem mikrokredita u Partner MKF",
};
