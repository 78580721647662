import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { strings } from "../../../Translations/strings";
import { Form, Button } from "react-bootstrap";
import { analytics } from "../../../Analytics/config";
import InputError from "../../../Common/Error/InputError";
import { registerUser } from "../../../Auth/Register/lib";
import { setAuthData } from "../../../Auth/authSlice";
import { logEvent } from "firebase/analytics";
import { useForm } from "react-hook-form";
import { setDataInStorage } from "../../../Storage";
import { fetchProfile } from "../../../Profile/profileSlice";
import Toc from "../../../Auth/Register/Toc";

export const RegisterModal = ({
  goToLogin,
  onHide,
  chatWithVendor,
  openViberChatWithPartner,
  openViberChatWithVendor,
  showToc,
  showTocHandler,
}) => {
  const [registrationInProgress, setRegistrationInProgress] = useState(false);
  const [registrationError, setRegistrationError] = useState(null);
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required(strings.validation_field_first_name)
      .min(2, strings.validation_field_first_name)
      .matches(
        /^[a-zA-ZČĆŽŠĐčćžšđ,-]+(?:\s?[a-zA-Z, ČĆŽŠĐčćžšđ-])*$/,
        strings.validation_field_first_name
      ),
    lastName: yup
      .string()
      .required(strings.validation_field_last_name)
      .min(2, strings.validation_field_last_name)
      .matches(
        /^[a-zA-ZČĆŽŠĐčćžšđ,-]+(?:\s?[a-zA-Z, ČĆŽŠĐčćžšđ-])*$/,
        strings.validation_field_last_name
      ),
    type: yup.string().required(strings.validation_field_type).nullable(),
    email: yup
      .string()
      .required(strings.validation_field_required_label)
      .email(strings.validation_field_email),
    password: yup
      .string()
      .min(6, strings.validation_field_password)
      .required(strings.validation_field_password_required),
    phone: yup
      .string()
      .required(strings.validation_field_telephone)
      .min(9, strings.validation_field_telephone),
      consent: yup.bool().oneOf([true], strings.register_toc_error),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      address: "",
      phone: "",
      email: "",
      password: "",
      type: "",
      consent: false,
    },
  });

  const onSubmit = async (data) => {
    setRegistrationInProgress(true);
    const registerData = Object.fromEntries(
      Object.entries(data).filter(([_, field]) => field !== "")
    );
    const registerSuccessData = await registerUser(registerData).catch(
      (err) => {
        setRegistrationInProgress(false);
        const error =
          err?.response?.status === 409
            ? strings.registration_error_user_exists.replace(
                "{email}",
                registerData.email
              )
            : "";
        setRegistrationError(error);
      }
    );
    if (registerSuccessData) {
      logEvent(analytics, "user_register", { email: registerData.email });
      setRegistrationInProgress(false);
      dispatch(setAuthData(registerSuccessData));
      setDataInStorage({ name: "auth", data: registerSuccessData });
      dispatch(fetchProfile());
      onHide();
      if (chatWithVendor) {
        openViberChatWithVendor();
      } else {
        openViberChatWithPartner();
      }
    }
  };

  const errorStyle = {
    display: "block",
    marginTop: "0px !important",
    textAlign: "center",
  };

  return (
    <>
     {showToc ? (
        <Toc />
      )  :<><Form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex group-container-checkbox">
          <Form.Group
            className="user-type-checkboxes"
            controlId="formRadioTypePersonal"
          >
            <Form.Check
              isInvalid={errors?.type?.message}
              type="radio"
              aria-required
              label={strings.regular_user_label}
              feedback={strings.validation_field_type}
              feedbackType="invalid"
              {...register("type")}
              value="PHYSICAL_PERSON"
            />
            <Form.Control.Feedback type="invalid">
              {errors?.lastName?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="mb-3 radio-company"
            controlId="formRadioTypeCompany"
          >
            <Form.Check
              type="radio"
              aria-required
              isInvalid={errors?.type?.message}
              label={strings.company_user_label}
              {...register("type")}
              value="LEGAL_ENTITY"
            />
          </Form.Group>
        </div>
        <div className="d-flex justify-content-between group-container">
          <Form.Group
            className="mb-3 required input-half-container"
            controlId="formFirstName"
          >
            <Form.Label aria-required className="control-label">
              {strings.register_form_first_name_label}
            </Form.Label>
            <Form.Control
              type="name"
              onKeyPress={(e) => {
                if (e.code === "Space" || e.keyCode === 32) {
                  e.preventDefault();
                }
              }}
              isInvalid={errors?.firstName?.message}
              placeholder={strings.register_form_first_name_label}
              {...register("firstName", { required: true })}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.firstName?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="mb-3 input-half-container"
            controlId="formLastName"
          >
            <Form.Label className="control-label">
              {strings.register_form_last_name_label}
            </Form.Label>
            <Form.Control
              isInvalid={errors?.lastName?.message}
              onKeyPress={(e) => {
                if (e.code === "Space" || e.keyCode === 32) {
                  e.preventDefault();
                }
              }}
              type="name"
              placeholder={strings.register_form_last_name_label}
              {...register("lastName", { required: true })}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.lastName?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="d-flex justify-content-between group-container">
          <Form.Group
            className="mb-3 input-half-container"
            controlId="formAddress"
          >
            <Form.Label>{strings.city_register_form_label}</Form.Label>
            <Form.Control
              type="name"
              onKeyPress={(e) => {
                if (e.code === "Space" || e.keyCode === 32) {
                  e.preventDefault();
                }
              }}
              placeholder={strings.city_register_form_label}
              {...register("address", { required: false })}
            />
          </Form.Group>
          <Form.Group
            className="mb-3 input-half-container"
            controlId="formPhone"
          >
            <Form.Label className="control-label">
              {strings.telephone_register_form_label}
            </Form.Label>{" "}
            <Form.Control
              type="number"
              onKeyPress={(e) => {
                if (e.code === "Space" || e.keyCode === 32 || e.key === "e") {
                  e.preventDefault();
                }
              }}
              placeholder={strings.telephone_register_form_label}
              isInvalid={errors?.phone?.message}
              {...register("phone", { required: true })}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.phone?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <Form.Group className="mb-3 required" controlId="formEmail">
          <Form.Label aria-required className="control-label">
            {strings.email_input_label}
          </Form.Label>
          <Form.Control
            type="email"
            onKeyPress={(e) => {
              if (e.code === "Space" || e.keyCode === 32) {
                e.preventDefault();
              }
            }}
            isInvalid={errors?.email?.message}
            placeholder={strings.email_input_placeholder}
            {...register("email", { required: true })}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.email?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formPassword">
          <Form.Label className="control-label">
            {strings.password_input_label}
          </Form.Label>
          <Form.Control
            type="password"
            onKeyPress={(e) => {
              if (e.code === "Space" || e.keyCode === 32) {
                e.preventDefault();
              }
            }}
            isInvalid={errors?.password?.message}
            placeholder={strings.password_input_label}
            {...register("password", { required: true })}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.password?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Check>
              <Form.Check.Input
                type={"checkbox"}
                {...register("consent", { required: true })}
                isInvalid={errors?.consent?.message}
              />
              <Form.Check.Label>
                <>
                  {strings.register_toc_i_agree}{" "}
                  <span className="toc" onClick={showTocHandler}>
                    {strings.register_toc_label}
                  </span>
                </>
              </Form.Check.Label>{" "}
              <Form.Control.Feedback type="invalid">
                {errors?.consent?.message}
              </Form.Control.Feedback>
            </Form.Check>
          </Form.Group>
        {registrationError && (
          <InputError message={registrationError} style={errorStyle} />
        )}
        <Button variant="primary submit-form" type="submit">
          {registrationInProgress ? (
            <div className="spinner-border text-light" role="status"></div>
          ) : (
            strings.register_form_register_button_label
          )}
        </Button>
      </Form>
      <div className="login-calculator-bottom-container">
        <p className="register-question">
          {strings.register_form_already_register_label}
        </p>
        <p className="register-link-green d-flex d-md-none" onClick={goToLogin}>
          {strings.register_form_login_here_label}
        </p>
        <p className="register-link d-none d-md-flex" onClick={goToLogin}>
          {strings.register_form_login_here_label}
        </p>
      </div>
      </>}
    </>
  );
};
