/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Image, Form } from "react-bootstrap";
import "./Products.scss";
import { strings } from "../../Translations/strings";
import Collapsible from "react-collapsible";
import arrowDown from "./assets/arrow-down.svg";
import arrowUp from "./assets/arrow-up.svg";

const ProductsFilters = ({
  filterProducts,
  categoryFilterVisible,
  vendorList,
  categoriesList,
  searchOption,
}) => {
  const [filterOneOpen, setFilterOneOpen] = useState(false);
  const [filterFourOpen, setFilterFourOpen] = useState(false);

  const handleOpeningOne = () => {
    setFilterOneOpen(!filterOneOpen);
  };

  const handleOpeningFour = () => {
    setFilterFourOpen(!filterFourOpen);
  };

  const filterOne = (
    <div className="d-flex flex-row align-items-start">
      {!filterOneOpen ? (
        <Image src={arrowDown}></Image>
      ) : (
        <Image src={arrowUp}></Image>
      )}
      <p className="product-filters">{strings.products_filter_categories}</p>
    </div>
  );

  const filterFour = (
    <div className="d-flex flex-row align-items-start">
      {!filterFourOpen ? (
        <Image src={arrowDown}></Image>
      ) : (
        <Image src={arrowUp}></Image>
      )}
      <p className="product-filters">{strings.products_filter_vendor}</p>
    </div>
  );
  return (
    <>
      {/*{categoryFilterVisible ? (
        <Collapsible
          trigger={filterOne}
          onTriggerOpening={handleOpeningOne}
          onClose={handleOpeningOne}
          easing={"ease-in-out"}
        >
          {categoriesList?.map((category, index) => {
            return (
              <div className="d-flex filters-option" key={category?.id}>
                <Form.Check
                  aria-label="option 1"
                  className="products-filter-checkbox"
                  name={category.title}
                  type="checkbox"
                  checked={searchOption[`subcategory${index}`] ? true : false}
                  onChange={(event) =>
                    filterProducts({
                      event,
                      id: category.id,
                      type: "subcategory",
                      name: `subcategory${index}`,
                    })
                  }
                />
                <p>{category.title}</p>
              </div>
            );
          })}
        </Collapsible>
        ) : null}*/}
      <Collapsible
        trigger={filterFour}
        onTriggerOpening={handleOpeningFour}
        onClose={handleOpeningFour}
        easing={"ease-in-out"}
      >
        {vendorList?.map((vendor, index) => {
          return (
            <div className="d-flex filters-option" key={vendor?.id}>
              <Form.Check
                aria-label="option 1"
                type="checkbox"
                className="products-filter-checkbox"
                checked={searchOption[`vendor${index}`] ? true : false}
                onChange={(event) =>
                  filterProducts({
                    event,
                    id: vendor.id,
                    type: "vendor",
                    name: `vendor${index}`,
                  })
                }
              />
              <p>{vendor?.companyName}</p>
            </div>
          );
        })}
      </Collapsible>
    </>
  );
};

export default ProductsFilters;
