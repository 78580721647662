import React from "react";
import "../GroupOverview.scss";
import { Tab, Tabs, Table, ProgressBar } from "react-bootstrap";
import editIcon from "../../assets/edit-icon.svg";
import { strings } from "../../../Translations/strings";

const EditCategoryTabs = ({
  editCategory,
  categoriesAsArray,
  onPressEditQuestion,
}) => {
  return (
    <>
      <Tabs defaultActiveKey="data" id="edit-category-tabs" className="mb-3">
        <Tab eventKey="data" title="Podaci" className="tab-label first-tab">
          <div className="product-description">
            <div>
              <Table responsive="sm" className="categories-overview-table">
                <tbody>
                  {categoriesAsArray.map((category) =>
                    editCategory.title === category.title ? (
                      <>
                        {category.questions.map((question) => {
                          const answer =
                            question?.options?.length === 0
                              ? question?.answer
                              : question?.options?.find(
                                  (option) => option.value === question?.answer
                                )?.title;
                          return (
                            <tr key={question.title}>
                              <td className="question-titles">
                                {question.title}
                                <span className="d-block d-md-none answers-to-questions-mobile">
                                  {answer}
                                </span>
                              </td>
                              <td className="answers-to-questions">{answer}</td>
                              <td>
                                <div
                                  className="d-flex align-items-start justify-content-end edit-field"
                                  onClick={() => onPressEditQuestion(question)}
                                >
                                  <img src={editIcon} alt=""></img>
                                  <p className="d-none d-md-block">
                                    {strings.group_overview_edit}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      ""
                    )
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </Tab>
      </Tabs>
    </>
  );
};

export default EditCategoryTabs;
