import React, { useState } from "react";
import "./MyProfile.scss";
import { useDispatch, useSelector } from "react-redux";
import { strings } from "../../Translations/strings";
import { Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { updatePasswordHandler } from "../updatePasswordHandler";
import { fetchProfile } from "../profileSlice";
import UpdatePasswordModal from "./UpdatePasswordModal";
import { useNavigate } from "react-router-dom";

const MyProfileForm = () => {
  const { profile } = useSelector((state) => state.profile);
  const [updateInProgress, setUpdateInProgress] = useState(false)
  const [updatePasswordVisible, setUpdatePasswordVisible] = useState(false);
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const handleChangePasswordVisibility = () => {
    setUpdatePasswordVisible(!updatePasswordVisible);
  };

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required(strings.validation_field_first_name)
      .min(2, strings.validation_field_first_name)
      .matches(/^[A-Za-z]+$/, strings.validation_field_first_name),
    lastName: yup
      .string()
      .required(strings.validation_field_last_name)
      .min(2, strings.validation_field_last_name)
      .matches(/^[A-Za-z]+$/, strings.validation_field_last_name),
    type: yup.string().required(strings.validation_field_type).nullable(),
    address: yup.string().notRequired(),
    telephone: yup
      .string()
      .notRequired()
      .test(
        "check length if typing, not required field",
        strings.validation_field_telephone,
        (val) => val.toString().length <= 1 || val.toString().length >= 9
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      firstName: profile?.data?.firstName,
      lastName: profile?.data?.lastName,
      address: profile?.data?.address || "",
      telephone: profile?.data?.phone || "",
      email: profile?.data?.email,
      type: profile?.data?.type,
    },
  });

  const onSubmit = async(data) => {
    setUpdateInProgress(true)
    const newData = {...data, phone: data?.telephone}
    const profileUpdated = await updatePasswordHandler(newData).catch(()=>setUpdateInProgress(false))
    if(profileUpdated) {
      setUpdateInProgress(false)
      dispatch(fetchProfile())
    }
  };

  const goBack = () => navigation(-1)
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex group-container-checkbox">
          <Form.Group
            className="user-type-checkboxes"
            controlId="formRadioTypePersonal"
          >
            <Form.Check
              isInvalid={errors?.type?.message}
              type="radio"
              aria-required
              label={strings.regular_user_label}
              feedback={strings.validation_field_type}
              feedbackType="invalid"
              {...register("type")}
              value="PHYSICAL_PERSON"
            />
          </Form.Group>
          <Form.Group
            className="mb-3 radio-company"
            controlId="formRadioTypeCompany"
          >
            <Form.Check
              type="radio"
              aria-required
              isInvalid={errors?.type?.message}
              label={strings.company_user_label}
              {...register("type")}
              value="LEGAL_ENTITY"
            />
          </Form.Group>
        </div>
        <div className="d-flex justify-content-between group-container">
          <Form.Group
            className="mb-3 required input-half-container"
            controlId="formFirstName"
          >
            <Form.Label aria-required className="control-label">
              {strings.register_form_first_name_label}
            </Form.Label>
            <Form.Control
              type="name"
              onKeyPress={(e) => {
                if (e.code === "Space" || e.keyCode === 32) {
                  e.preventDefault();
                }
              }}
              placeholder={profile?.data?.firstName}
              isInvalid={errors?.firstName?.message}
              {...register("firstName")}
              onChange={(e) => setValue("firstName", e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.firstName?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="mb-3 input-half-container"
            controlId="formLastName"
          >
            <Form.Label className="control-label">
              {strings.register_form_last_name_label}
            </Form.Label>
            <Form.Control
              isInvalid={errors?.lastName?.message}
              onKeyPress={(e) => {
                if (e.code === "Space" || e.keyCode === 32) {
                  e.preventDefault();
                }
              }}
              type="name"
              placeholder={profile?.data?.lastName}
              {...register("lastName", { required: false })}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.lastName?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="d-flex justify-content-between group-container">
          <Form.Group
            className="mb-3 input-half-container"
            controlId="formAddress"
          >
            <Form.Label>
              {strings.city_register_form_label}
            </Form.Label>
            <Form.Control
              isInvalid={errors?.address?.message}
              type="name"
              onKeyPress={(e) => {
                if (e.code === "Space" || e.keyCode === 32) {
                  e.preventDefault();
                }
              }}
              placeholder={profile?.data?.address}
              {...register("address", { required: false })}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.address?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="mb-3 input-half-container"
            controlId="formTelephone"
          >
            <Form.Label>{strings.telephone_register_form_label}</Form.Label>
            <Form.Control
              type="number"
              onKeyPress={(e) => {
                if (e.code === "Space" || e.keyCode === 32 || e.key === "e") {
                  e.preventDefault();
                }
              }}
              isInvalid={errors?.telephone?.message}
              placeholder={profile?.data?.telephone}
              {...register("telephone", { required: false })}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.telephone?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <hr className="grey-line account-line"></hr>
        <p
          className="show-change-password account-change-password"
          onClick={handleChangePasswordVisibility}
        >
          {strings.account_change_password}
        </p>
        <div className="account-save-changes">
          <>
            <Button
              className="discard-changes-btn"
              onClick={goBack}
            >
              {strings.account_discard_changes_btn}
            </Button>
            <Button
              disabled={!isDirty || !isValid}
              type="submit"
              className="save-changes-btn save-changes-btn-active"
              onClick={onSubmit}
            >
              {updateInProgress ?  <div className="spinner-border text-light" role="status"></div> : strings.account_save_changes}
            </Button>
          </>
        </div>
      </Form>
      <UpdatePasswordModal show={updatePasswordVisible} closeModal={handleChangePasswordVisibility} profile={profile}/>
    </>
  );
};

export default MyProfileForm;
