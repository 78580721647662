import React from "react";
import { Nav, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { strings } from "../Translations/strings";
import "./NavMenu.scss";

const NavMenuSecond = ({ handleLogOut }) => {
  const { profile } = useSelector((state) => state.profile);
  const { wishlistItems } = useSelector((state) => state.wishlist);

  return (
    <Nav>
      <Link className="d-flex align-items-center" to="/lista-zelja">
        <img
          src={
            wishlistItems.length === 0
              ? require("./assets/Handbag.svg").default
              : require("./assets/Handbag-notification.svg").default
          }
          alt="handbag_icon"
        />
      </Link>
      <Nav.Link
        className="d-flex align-items-center navbar-icon"
        href={profile?.data?.firstName ? "/profil" : "/login"}
      >
        <img
          src={require("./assets/UserCircle.svg").default}
          alt="usercircle_icon"
        />
      </Nav.Link>

      <Dropdown
        id="profile-dropdown"
        className={profile?.data?.firstName ? "d-flex" : "dropdown-hide"}
      >
        <Dropdown.Toggle
          variant="transparent"
          id="dropdown-nav-toggle"
          className="navbar_dropdown_label ps-0"
        >
          {profile?.data?.firstName}
        </Dropdown.Toggle>

        <Dropdown.Menu className="profile-dropdown-menu dropdown-nav-profile">
          <Dropdown.Item className="nav-dropdown-item remove-hover">
            <Link to="/profil">
              <img
                src={require("./assets/UserCircle.svg").default}
                className="dropdown-profile-icon"
                alt="profile_icon"
              ></img>
              {strings.navmenu_account_label}
            </Link>
          </Dropdown.Item>
          <Dropdown.Item
            href="#"
            className="nav-dropdown-item remove-hover"
            onClick={handleLogOut}
          >
            <img
              src={require("../NavMenu/assets/LogOut-white.svg").default}
              className="dropdown-profile-icon"
              alt="profile_icon"
            ></img>
            {strings.navmenu_logout_label}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Nav>
  );
};

export default NavMenuSecond;