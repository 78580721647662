import React from "react";
import "./ProductDetail.scss";
import { Breadcrumb } from "react-bootstrap";
import { strings } from "../../../Translations/strings";
import { Link, useNavigate } from "react-router-dom";

const ProductDetailBreadcrumbs = ({ product }) => {
  const navigate = useNavigate();

  const navigateToProducts = (category) => {
    if (category !== "solarni sistemi") {
      navigate("/proizvodi/" + category);
    } else {
      navigate("/proizvodi/solarni-sistemi");
    }
  };
  const { name, category } = product || {};

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">
          {strings.category_breadcrumb_home}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
        <Link to="proizvodi">
          {strings.category_breadcrumb_products}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="products-breadcrumb-item"
          onClick={() => {
            navigateToProducts(category.title.toLowerCase());
          }}
        >
          {category?.title?.replace(/-/g, " ")?.replace(/dj/g, "đ")}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{name}</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
};

export default ProductDetailBreadcrumbs;
