import React from "react";
import "./ProductDetail.scss";
import { Button, Modal } from "react-bootstrap";
import { strings } from "../../../Translations/strings";
import AskVendor from "./AskVendor";
import { useLocation } from "react-router-dom";
import { products } from "../demoData";

export function SendQuestion(props) {
  const location = useLocation();
  const pathNames = location?.pathname.split("/");
  const product = products?.find(
    (product) => product.id === pathNames[pathNames.length - 1]
  );

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="savings-header">
        <Modal.Title id="contained-modal-title-vcenter">
          <h4 className="product-modal-title">
            {strings.send_message_to_vendor} {product?.manufacturer}
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 m-0">
        <AskVendor product={props.product} />
      </Modal.Body>
      <Modal.Footer className="modal-footer d-flex justify-content-end align-items-center">
        <Button
          onClick={props.onHide}
          variant="primary"
          className="modal-transparent-btn d-none d-lg-flex"
        >
          {strings.send_message_to_vendor_discard}
        </Button>
        <Button
          onClick={props.onHide}
          variant="primary"
          className="modal-green-btn"
          type="submit"
        >
          {strings.send_message_to_vendor_send}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
