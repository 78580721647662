import React from "react";
import "./Footer.scss";
import facebook from "./assets/Facebook.svg";
import instagram from "./assets/Instagram.svg";
import linkedIn from "./assets/LinkedIn.svg";
import youtube from "./assets/YouTube.svg";
import logo from "../../Marketplace/MainMenu/assets/Logo.svg";
import email from "./assets/Email.svg";
import phone from "./assets/Phone.svg";
import mark from "./assets/Mark.svg";
import { strings } from "../../Translations/strings";
import EUfunded from "./assets/EU-funded.png";
import Offcanvas from "react-bootstrap/Offcanvas";
import closeIcon from "./assets/close-icon.svg";

const OffcanvasFooter = ({ show, setShow, onHide }) => {
  const handleClose = () => setShow(false);
  return (
    <Offcanvas show={show} onHide={handleClose} placement="bottom">
      <Offcanvas.Body>
        <div className="footer-container mt-0">
          <footer>
            <img
              src={closeIcon}
              alt="icon"
              className="close-offcanvas"
              onClick={handleClose}
            ></img>
            <div className="content pt-5">
              <div className="logo-icons">
                <img src={logo} alt="logo" className="footer-logo" />
                <p>{strings.footer_text}</p>
                <div className="icons">
                  <a
                    href="https://www.facebook.com/PartnerMKF"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={facebook} alt="facebook_icon" />
                  </a>
                  <a
                    href="https://www.instagram.com/partnermikrokreditnafondacija/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={instagram} alt="instagram_icon" />
                  </a>
                  <a
                    href="https://ba.linkedin.com/company/partner-mkf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={linkedIn} alt="linkedIn_icon" />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCqcWPGiqh9EM-mcFWIZLvMQ"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={youtube} alt="youtube_icon" />
                  </a>
                </div>
              </div>

              <div className="links-contact-wrapper">
                <div className="links">
                  <p className="subtitle">{strings.footer_explore_label}</p>
                  <ul>
                    <li>
                      <a
                        href="https://www.partner.ba/o-nama"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {strings.footer_about_us_label}
                      </a>
                    </li>
                    <li>
                      <a href="/proizvodi">{strings.footer_products_label}</a>
                    </li>
                    <li>
                      <a
                        href="https://www.partner.ba/partner-online-krediti"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {strings.footer_financing_label}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="contact">
                  <p className="subtitle">{strings.footer_contact_us_label}</p>
                  <p className="d-flex align-items-center">
                    <embed src={email} type="" />
                    {strings.footer_partner_email}
                  </p>
                  <p className="d-flex align-items-center">
                    <embed src={phone} type="" />
                    {strings.footer_partner_telphone}
                  </p>
                  <p className="d-flex align-items-start contact-address">
                    <embed src={mark} type="" />
                    {strings.footer_partner_address}
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex funded-by-eu-container">
              <img src={EUfunded} alt="funded_by_EU"></img>
              <div className="eu-description">
                <p>{strings.footer_funded_by_eu}</p>
              </div>
            </div>
          </footer>
          <hr />
          <footer>
            <div className="copyright">
              <div className="d-flex">
                <p>
                  {strings.footer_copyright_label}
                  {new Date().getFullYear()} {strings.footer_copyright_owner}
                </p>

                <div className="terms-conditions">
                  <p>{strings.footer_all_rights_reserved_label}</p>
                </div>
              </div>
              <div className="footer-more-details d-flex align-items-center"></div>
            </div>
          </footer>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default OffcanvasFooter;
