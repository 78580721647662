import { React } from "react";
import { useSelector } from "react-redux";
import "./QuestionWrapperComponent.scss";

const QuestionWrapperComponent = ({
  children,
  category,
  title,
  updateIndex,
  currentIndex,
  hasValue,
  onClose,
}) => {
  const { isEditingOneField } = useSelector((state) => state.calculator);
  const { profile } = useSelector((state) => state.profile);

  const goToNext = () => {
    updateIndex({
      index: currentIndex + 1,
      authenticated: Boolean(profile?.data?.id),
    });
  };

  const goToPrevious = () => {
    if (currentIndex === 0) {
      return onClose();
    }
    updateIndex({
      index: currentIndex - 1,
      authenticated: Boolean(profile?.data?.d),
    });
  };
  const backButtonTitle = currentIndex === 0 ? "Zatvori" : "Nazad";

  const activeButtonClassName = hasValue
    ? "home-button btn-green"
    : "home-button btn-green disabled";
  return (
    <div className="select-modal-component">
      <div className="content-wrapper">
        {category ? <p className="category">{category.toUpperCase()}</p> : null}
        <p className="title">{title}</p>
        <div className="select-modal-options">{children}</div>
      </div>
      <div className="buttons-wrapper">
        <button onClick={goToPrevious} className="home-button">
          {isEditingOneField ? "Odustani" : backButtonTitle}
        </button>
        <button
          onClick={goToNext}
          className={activeButtonClassName}
          disabled={!hasValue}
        >
          {isEditingOneField ? "Ažuriraj" : "Dalje"}
        </button>
      </div>
    </div>
  );
};

export default QuestionWrapperComponent;
