import React from "react";
import "./ProductDetail.scss";
import { Button, Modal } from "react-bootstrap";
import { strings } from "../../../Translations/strings";
import SavingsDetails from "./SavingsDetails";

export const SavingsModal = (props) => {
  const { productSaving, loanDetails, product, onHide, loanNeeded, openViber } =
    props;

  const openInNewTab = () => {
    if (Number(product?.calculation?.totalInvestment) < 3000) {
      window.open(
        "https://partnerkrediti.online/",
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      window.open(
        "https://www.partner.ba/zahtjev_za_mk/fizicka_lica/prvi_korak?mk=ee_mp",
        "_blank",
        "noopener,noreferrer"
      );
    }
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="savings-header">
        <Modal.Title id="contained-modal-title-vcenter">
          <h4 className="product-modal-title">{strings.savings_modal_title}</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 m-0">
        <SavingsDetails
          productSaving={productSaving}
          loanDetails={loanDetails}
          product={product}
          loanNeeded={loanNeeded}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer d-flex justify-content-end align-items-center no-top-border">
        <Button
          onClick={() => {
            openViber();
            onHide();
          }}
          variant="primary"
          className="modal-transparent-btn d-none d-lg-flex"
        >
          {strings.financing_question_button_label}
        </Button>
        <Button
          onClick={openInNewTab}
          variant="primary"
          className="modal-green-btn request-a-loan-btn"
        >
          {strings.financing_request_button_label}
        </Button>
        <Button
          onClick={() => {
            openViber();
            onHide();
          }}
          variant="primary"
          className="modal-transparent-btn d-block d-lg-none"
        >
          {strings.financing_question_button_label}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
