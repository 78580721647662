import React, { useEffect } from "react";
import HeaderHero from "./HeaderHero/HeaderHero";
import MainContent from "./MainContent/MainContent";
import './Home.scss';
import Footer from "../Common/Footer/Footer";
import { logEvent } from "firebase/analytics";
import { analytics } from "../Analytics/config";

const Home = () => {
  useEffect(()=>{
    logEvent(analytics, 'homepage_visited')
  }, [])

  return (
    <>
        <HeaderHero />
        <MainContent />
        <Footer />
    </>
  );
};
export default Home;
