import { BASE_URL } from "../../Environment/config";
import { httpClient } from "../../Http/client";
import { getItemFromStorage } from "../../Storage";
import { getLoggedUserQuestions } from "../state/calculatorSlice";

export const saveAnswers = ({
  answers,
  categoryId,
  refetchAnswers = false,
}) => {
  const { store } = require("../../State/store");
  const { token } = getItemFromStorage({ name: "auth" }) ?? {};
  return httpClient
    .post(`${BASE_URL}/answers/categories/${categoryId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      answers: answers,
    })
    .then(() => {
      if (refetchAnswers) {
        store.dispatch(getLoggedUserQuestions());
      }
    });
};

export const updateAnswers = ({ answer, questionId }) => {
  return httpClient.put(`${BASE_URL}/questions/${questionId}/answer`, {
    answer,
  });
};

export const resetAnswersOfOneCategory = ({ categoryId }) => {
  return httpClient.delete(`${BASE_URL}/answers/categories/${categoryId}`);
};

export const resetAllCalculator = () => {
  return httpClient.delete(`${BASE_URL}/answers/reset`);
};
