/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Navbar, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  controlIntroModal,
  controlOpenModal,
} from "../Calculator/state/calculatorSlice";
import "./NavMenu.scss";
import { persistor } from "../State/store";
import NavMenuFirst from "./NavMenuFirst";
import NavMenuSecond from "./NavMenuSecond";
import NavLinksMobile from "./NavLinksMobile";
import { Link } from "react-router-dom";

const NavMenu = ({ mobileMenuOpen, controlMobileMenuVisibility }) => {
  const { profile } = useSelector((state) => state.profile);
  const { introModalVisible, hasCompletedCategory } = useSelector(
    (state) => state.calculator
  );
  const dispatch = useDispatch();

  const controlOpeningModalActive = () => {
    if (hasCompletedCategory) {
      return dispatch(controlOpenModal(true));
    }
    dispatch(controlIntroModal(!introModalVisible));
  };
  const handleLogOut = () => {
    persistor.purge().then(() => console.log(profile));
    window.location.reload();
  };

  return (
    <Navbar collapseOnSelect expand="lg" className="px-5">
      <Container fluid>
        <Navbar.Brand>
          <Link to={"/"}>
            <img
              className="nav_logo"
              src={require("../Assets/logo.svg").default}
              alt="logo"
            />
            <img
              className="nav_logo_mobile"
              src={require("./assets/Logo-mobile.svg").default}
              alt="logo"
            />
          </Link>
          <img
            className="eu-logo"
            src={require("./assets/EU-logo.svg").default}
            alt="EU"
          />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-between"
        >
          <NavMenuFirst />
          <NavMenuSecond
            controlOpeningModalActive={controlOpeningModalActive}
            handleLogOut={handleLogOut}
          />
        </Navbar.Collapse>
        <NavLinksMobile
          mobileMenuOpen={mobileMenuOpen}
          controlMobileMenuVisibility={controlMobileMenuVisibility}
        />
      </Container>
    </Navbar>
  );
};

export default NavMenu;
